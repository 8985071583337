#cooltone-about {
  .image-text-grid--cooltone {
    .image-text-grid {
      &__content {
        > .container:has(.image-text-grid__content-container) {
          @include mq('tablet') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }
        }
      }

      &__content-container {
        padding-top: 40px;
        padding-bottom: 36px;

        @include mq('tablet') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          margin-right: auto;
        }

        @include mq('x-large') {
          max-width: 435px; // as per live
        }
      }

      &__teaser {
        @include mq('tablet') {
          height: 720px; // as per live
        }

        .cmp-teaser__image--desktop {
          @include mq('tablet') {
            height: 100%;

            .cmp-image {
              height: 100%;

              a {
                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }

  .cooltone-about {
    &__banner {
      position: relative;
      margin-top: -27px;

      @include mq('large') {
        margin-top: -10px;
      }

      @include mq('1400px') {
        background-color: #1650e0; // used once
      }

      &-img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;

          @include mq('medium') {
            object-position: right;
          }

          @include mq('1400px') {
            object-position: center -90px;
          }
        }

        &--mobile {
          @include mq('small-plus') {
            display: none;
          }
        }

        &--desktop {
          display: none;

          @include mq('small-plus') {
            display: block;
          }
        }
      }

      &-content {
        position: relative;
        padding-top: 30px;
        padding-right: 30px;
        padding-bottom: var(--emu-common-spacing-none);
        padding-left: 30px;

        @include mq('tablet') {
          padding-top: 120px;
          padding-right: 50px;
          padding-left: 50px;
        }
      }

      &-more-strength-img {
        margin-bottom: 30px;

        @include mq('medium') {
          margin-bottom: 40px;
        }

        img {
          max-width: 220px; // as per live
          padding-top: 44px;
          padding-bottom: 44px;

          @include mq('medium') {
            max-width: 525px; // as per live
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &-disclaimer {
        p {
          max-width: 576px; // as per live
          margin-top: 119px;
          margin-bottom: var(--emu-common-spacing-medium);
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: 16.2px;
          letter-spacing: unset;
          text-align: center;

          @include mq('medium') {
            margin-top: 29px;
            margin-bottom: 21px;
          }

          @include mq('tablet') {
            margin-left: auto;
            font-size: 10px;
            line-height: 13.5px;
            text-align: left;
          }
        }
      }

      &-see-results {
        width: 100%;
        position: relative;
        bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        background-image: linear-gradient(
          270deg,
          #009cd9cc 0.43%,
          #0061b9cc 52.13%,
          #003f82cc 99.25%
        ); // used once
        padding-top: 33px;
        padding-bottom: 35px;

        @include mq('small-plus') {
          padding-top: var(--emu-common-spacing-large);
        }

        @include mq('medium') {
          padding-top: 37px;
          padding-bottom: 40px;
        }

        @include mq('tablet') {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > .button {
          text-align: center;

          @include mq('tablet') {
            width: 100%;
            max-width: 215px;
          }
        }
      }

      &-see-results-title {
        h3 {
          font-family: var(--emu-semantic-font-families-body);
          font-size: 24px;
          font-weight: var(--emu-semantic-font-weight-500);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          margin-bottom: 37px;

          @include mq('small-plus') {
            margin-bottom: 38px;
          }

          @include mq('tablet') {
            font-size: 28px;
            margin-top: var(--emu-common-spacing-none);
            margin-right: 35px;
            margin-bottom: 3px;
          }

          sup {
            top: -9px;

            @include mq('x-large') {
              top: -11px;
            }
          }
        }
      }

      &-see-results-btn {
        @include mq('tablet') {
          margin-top: 3px;
        }
      }
    }

    &__fda {
      padding-top: 40px;
      background-image: radial-gradient(
        50% 65% at 10% 100%,
        #005081 0%,
        #00335a 22.92%,
        #001733 100%
      ); // used once
      overflow: hidden;

      @include mq('tablet') {
        background-image: radial-gradient(
          65.66% 210.44% at 70.66% 47.41%,
          #005081 0%,
          #00335a 22.92%,
          #001733 100%
        ); // used once
        padding-top: 130px;
        padding-bottom: 110px;
        position: relative;
      }

      @include mq('x-large') {
        padding-top: 170px;
        padding-bottom: 120px;
      }

      @include mq('1600px') {
        // as per live
        padding-top: 220px;
        padding-bottom: 240px;
      }

      &-wrapper {
        @include mq('tablet') {
          display: flex;
          max-width: 960px; // as per live
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('x-large') {
          max-width: 1196px; // as per live
        }
      }

      &-content {
        max-width: 456px; // as per live
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
        margin-bottom: 24px;

        @include mq('tablet') {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      &-title {
        margin-bottom: 20px;

        h3 {
          color: var(--emu-semantic-colors-primary-100);
          line-height: 33px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
          }
        }
      }

      &-text {
        p {
          color: var(--emu-semantic-colors-primary-100);
          margin-bottom: 10px;
        }
      }

      &-button {
        width: 100%; // as per live
        background-color: var(--emu-common-colors-white);
        color: var(--emu-semantic-colors-primary-30);
        justify-content: center;
        margin-top: 20px;

        @include mq('small-plus') {
          width: auto; // as per live
          min-width: 215px; // as per live
        }

        &:hover {
          background-color: var(--emu-semantic-colors-secondary-300);
          color: var(--emu-common-colors-white);
        }

        &:active {
          background-color: var(--emu-semantic-colors-primary-300);
          color: var(--emu-common-colors-white);
        }
      }

      &-img {
        max-width: 450px; // as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('tablet') {
          position: absolute;
          top: 55px;
          right: 25px;
        }

        @include mq('x-large') {
          top: 0;
          right: 120px;
          max-width: 550px; // as per live
        }

        @include mq('1600px') {
          right: 250px;
          max-width: 700px; // as per live
        }
      }
    }

    &__practice {
      padding-top: 60px;
      padding-bottom: 55px;
      background-color: var(--emu-common-colors-white);

      @include mq('medium') {
        background-color: var(--emu-semantic-colors-primary-100);
        padding-top: 90px;
        padding-bottom: 70px;
      }

      &-wrapper {
        padding-left: 15px;
        padding-right: 15px;

        @include mq('x-large') {
          max-width: 1196px; // as per live
          margin-left: auto;
          margin-right: auto;
        }

        > .button {
          text-align: center;
        }
      }

      &-title {
        margin-bottom: 50px;

        h3 {
          line-height: 39.6px; // as per live
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          font-weight: var(--emu-common-font-weight-bold);

          @include mq('medium') {
            text-align: center;
          }
        }
      }

      &-note {
        p {
          margin-bottom: 40px;

          @include mq('medium') {
            text-align: center;
          }
        }
      }

      &-btn {
        background-color: var(--emu-semantic-colors-primary-400);

        &:hover {
          background: var(--emu-semantic-colors-secondary-300);
        }

        &:active {
          background-color: var(--emu-semantic-colors-primary-300);
        }
      }

      &-content-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 48px;

        @include mq('medium') {
          flex-direction: row;
          margin-bottom: 50px;
        }

        > .container {
          @include mq('medium') {
            width: 50%; // as per live
          }

          &:first-child {
            margin-bottom: 55px;

            @include mq('medium') {
              flex-direction: row;
              margin-bottom: var(--emu-common-spacing-none);
              margin-right: 33px;
            }
          }
        }
      }

      &-content-title {
        margin-bottom: 20px;

        h4 {
          font-family: var(--emu-semantic-font-families-body);
          font-weight: var(--emu-semantic-font-weight-600);
          font-size: 20px;
          line-height: 28px;

          @include mq('medium') {
            font-size: 24px;
            line-height: 33.6px;
          }
        }
      }

      &-content-text {
        margin-bottom: 28px;

        p {
          @include mq('medium') {
            margin-bottom: 60px;
          }
        }
      }
    }

    &__allergan-partnership {
      padding-top: 40px;
      padding-bottom: 40px;

      &-wrapper {
        > .button {
          text-align: center;
          margin-bottom: 30px;
        }
      }

      &-title {
        margin-top: 20px;
        margin-bottom: 20px;

        h3 {
          @include mq('medium') {
            text-align: center;
          }
        }
      }

      &-text {
        margin-bottom: 30px;

        p {
          text-align: justify; // as per live

          @include mq('medium') {
            text-align: center;
          }
        }

        @include mq('medium') {
          width: 67%; // as per live
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-btn {
        &:hover {
          background-color: var(--emu-semantic-colors-secondary-300);
        }

        &:active {
          background-color: var(--emu-semantic-colors-primary-300);
        }
      }

      &-programs-container {
        display: flex;
        flex-direction: column;

        @include mq('tablet') {
          flex-direction: row;
        }

        > .container {
          @include mq('tablet') {
            width: 50%; // as per live
          }
        }
      }

      &-programs-content {
        min-height: 300px; // as per live
        padding-top: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        margin-left: 2.5px;
        margin-right: 2.5px;
        margin-bottom: 15px;

        @include mq('small-plus') {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        @include mq('tablet') {
          margin-left: 12.5px;
          margin-right: 12.5px;
        }

        > .text {
          margin-top: 20px;
          margin-bottom: 10px;

          p {
            letter-spacing: unset;
            font-size: 24px;
            font-weight: var(--emu-semantic-font-weight-500);
            line-height: 26.4px;
          }
        }
      }

      &-programs-images {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          margin-top: 20px;
          margin-bottom: 20px;
        }

        @include mq('small') {
          flex-direction: column;
        }

        @include mq('small-plus') {
          flex-direction: row;
        }

        @include mq('tablet') {
          flex-direction: column;
        }

        @include mq('x-large') {
          flex-direction: row;
          height: 183.6px; // as per live
        }
      }

      &-programs-app-img {
        margin-right: var(--emu-common-spacing-none);

        @include mq('small-plus') {
          margin-right: var(--emu-common-spacing-xxs);
        }

        @include mq('small-plus') {
          margin-right: 22.5px;
        }

        @include mq('tablet') {
          margin-right: 20px;
        }
      }

      &-programs-alle-img {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        display: flex;
        align-items: center;

        @include mq('tablet') {
          height: 210px; // as per live
        }
      }
    }
  }

  .references {
    p {
      &:first-child {
        padding-top: 28px;
        padding-bottom: var(--emu-common-spacing-none);
        margin-bottom: 10px;

        @include mq('x-large') {
          padding-top: 42px;
        }
      }

      &:last-child {
        border-top: none;
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        margin-bottom: 82px;

        @include mq('x-large') {
          margin-bottom: 95px;
        }
      }
    }
  }
}
