.error.aaaem-container {
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 52px;
  padding-bottom: 47px;
  margin-top: -27px;

  @include mq('large') {
    margin-top: -10px;
  }

  @include mq('x-large') {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 105px;
    padding-right: 105px;
  }

  .error {
    &__title {
      margin-top: 20px;
      margin-bottom: 20px;

      @include mq('tablet') {
        margin-bottom: 35px;
      }

      h1 {
        @include mq('xx-large') {
          line-height: 117px;
          font-size: 130px;
        }
      }
    }

    &__sub-title {
      margin-bottom: 10px;

      @include mq('medium') {
        margin-bottom: 85px;
      }
    }

    &__buttons-wrapper {
      text-align: center;

      @include mq('medium') {
        text-align: left;
        column-count: 2; // to match live
        margin-left: 41px;
        margin-right: 38px;
      }

      @include mq('tablet') {
        max-width: 720px; // as per live
        margin-left: 137px;
        margin-right: 87px;
      }

      @include mq('x-large') {
        max-width: 725px; // as per live
        margin-left: auto;
        margin-right: auto;
        column-gap: 56px; // to match live
      }
    }

    &__button {
      margin-top: 30px;
      margin-right: 35px;

      @include mq('medium') {
        margin-top: 20px;
      }
    }
  }
}
