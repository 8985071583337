@font-face {
  font-family: 'GlyphiconsHalflings';
  font-display: swap;
  src: url('./assets/fonts/glyphiconsHalflings/glyphicons-halflings-regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'CoolSculptingIcons';
  font-display: swap;
  src: url('./assets/fonts/CoolSculptingIcons/coolsculpting-icons.ttf')
    format('truetype');
}
