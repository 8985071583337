.image-text-grid {
  @include mq('medium') {
    max-width: 720px; // as per live
    margin-left: auto;
    margin-right: auto;
  }

  @include mq('tablet') {
    max-width: 960px; // as per live
  }

  @include mq('x-large') {
    max-width: 1196px; // as per live
  }

  @include mq('xx-large') {
    max-width: 1440px; // as per live
  }

  &__content {
    @include mq('tablet') {
      display: flex;
    }

    > div {
      @include mq('tablet') {
        flex: 0 0 50%;
      }
    }

    > .container:has(.image-text-grid__content-container) {
      align-self: center;
    }

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  &__content-container {
    padding-top: 43px;
    padding-bottom: 46px;
    padding-left: 43px;
    padding-right: 43px;

    @include mq('tablet') {
      padding-left: 28px;
      padding-right: 28px;
    }

    @include mq('x-large') {
      padding-top: 99px;
      padding-bottom: 99px;
      padding-left: var(--emu-common-spacing-xl);
      padding-right: 94px;
    }

    &--reverse {
      @include mq('x-large') {
        padding-left: 107px;
        padding-right: 60px;
      }
    }
  }

  &__logo-img {
    margin-bottom: 23px;

    @include mq('x-large') {
      margin-bottom: var(--emu-common-spacing-large);
    }

    img {
      height: 30px; // as per live

      @include mq('x-large') {
        height: 53px; // as per live
      }
    }

    &--big {
      img {
        height: 76px; // as per live

        @include mq('x-large') {
          height: 126px; // as per live
        }
      }
    }
  }

  &__title {
    margin-bottom: 24px;

    @include mq('x-large') {
      margin-bottom: 46px;
    }

    h2 {
      @include mq('x-large') {
        width: 90%; // as per live
      }
    }
  }

  &__text {
    margin-bottom: 50px;

    @include mq('x-large') {
      margin-bottom: 43px;
    }

    p {
      sup {
        font-size: 10px;
      }
    }
  }

  &__disclaimer {
    margin-top: 45px;

    p {
      opacity: 0.8;
    }
  }

  &--cooltone {
    .image-text-grid {
      &__wrapper {
        @include mq('1600px') {
          max-width: 1366px; // as per live
          margin-left: auto;
          margin-right: auto;
        }
      }

      &__content {
        > .container:has(.image-text-grid__content-container) {
          @include mq('tablet') {
            padding-left: 25px;
            padding-right: 25px;
          }
        }
      }

      &__content-container {
        padding-top: 60px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: var(--emu-common-spacing-none);

        @include mq('medium') {
          width: 750px; // as per live
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('tablet') {
          width: 100%;
          max-width: 435px; // as per live
          padding-top: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          margin-right: unset;
        }

        @include mq('x-large') {
          max-width: 445px; // as per live
          margin-right: auto;
        }
      }

      &__teaser {
        .teaser-container {
          .content-container {
            padding-bottom: 18px;
            padding-left: 25px;
            padding-right: 25px;

            .cmp-teaser__description {
              p {
                letter-spacing: normal;
                line-height: 135%;
                text-shadow: -1px 2px 3px rgba(0, 0, 0, 0.1);
                width: 80%; // as per live
                margin-left: auto;
                margin-right: auto;
                opacity: unset;

                @include mq('medium') {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      &__title {
        margin-bottom: 20px;
      }

      &__text {
        margin-bottom: 30px;

        p {
          sup {
            font-size: 50%;
            top: -8px;
          }
        }
      }
    }
  }
}
