footer.footer {
  position: relative;
  background-color: var(--emu-common-colors-white);
  z-index: calc(var(--emu-common-other-z-index-isi) + 1);

  .footer {
    &-wrapper.aaaem-container {
      padding-left: 45px;
      padding-right: 45px;

      @include mq('large') {
        max-width: 960px; // to match live
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('x-large') {
        max-width: 1196px; // to match live
      }
    }

    &-top-container {
      border-top: 3px solid var(--emu-semantic-colors-primary-400);
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-50);
      padding-top: 45px;
      padding-bottom: 50px;

      @include mq('large') {
        display: flex;

        > .image {
          order: 1;
          margin-right: auto;
        }

        > .container {
          order: 2;
        }

        > .text {
          order: 3;
          margin-bottom: 44px;
        }
      }
    }

    &-logo {
      margin-bottom: 25px;

      img {
        max-height: 19px; // as per live
        transform: translateX(-20px); // as per live

        @include mq('medium') {
          transform: unset;
        }

        @include mq('large') {
          max-height: 30px; // as per live
        }

        @include mq('x-large') {
          max-height: 40px; // as per live
        }
      }
    }

    &-links {
      ul {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 10px;
        padding-left: var(--emu-common-spacing-none);
        list-style: none;

        li {
          font-family: var(--emu-semantic-font-families-avenir-next-medium);
          font-size: 14px;
          line-height: 15.78px; // to match live
          margin-bottom: var(--emu-common-spacing-medium);

          a {
            text-decoration: none;

            img {
              vertical-align: middle;
            }

            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }

      &--first {
        @include mq('large') {
          margin-right: 40px;
        }

        @include mq('x-large') {
          margin-right: 60px;
        }
      }
    }

    &-social-media {
      margin-top: 50px;

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
        margin-right: 40px;
      }

      @include mq('x-large') {
        margin-right: 60px;
      }

      &__text {
        p {
          margin-bottom: 20px;
          font-family: var(--emu-semantic-font-families-heading);
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-narrow-large);
          letter-spacing: 0.24;
          font-weight: var(--emu-semantic-font-weight-600);

          @include mq('x-large') {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.28px;
            margin-bottom: 14px;
          }
        }
      }

      &__icons-container {
        display: flex;

        a {
          display: flex;
          margin-right: 45px;

          svg {
            height: 20.5px; // as per live
            fill: var(--emu-semantic-colors-primary-300);

            @include mq('x-large') {
              height: 23px; // as per live
            }
          }

          &:hover,
          &:active {
            opacity: 0.7;
          }

          &:focus {
            border-bottom: var(--emu-common-border-width-medium) solid
              var(--emu-semantic-colors-primary-300);
          }
        }

        > .image:last-child {
          .cmp-image {
            a {
              margin-right: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }

    &-bottom-container {
      padding-top: 52px;
      padding-bottom: 47px;

      @include mq('large') {
        display: flex;
        padding-top: 41px;
        padding-bottom: 36px;

        > .image {
          flex: 0 0 16.67%;
        }
      }

      @include mq('x-large') {
        padding-bottom: var(--emu-common-spacing-large);
      }
    }

    &-allergan-logo {
      margin-bottom: 23px;

      @include mq('large') {
        padding-right: 25px;
      }

      img {
        max-height: 50px; // as per live

        @include mq('large') {
          max-height: 70px; // as per live
        }
      }
    }

    &-bottom-content-wrapper {
      @include mq('large') {
        display: flex;
        padding-left: 5px;

        > .text {
          flex: 0 0 50%;
        }
      }

      p {
        font-size: 10px;
        line-height: 1.3;
        letter-spacing: 0.3px;
        margin-bottom: 19px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          letter-spacing: 0.36;
        }

        sup {
          font-size: 10px;
        }
      }
    }

    &-bottom-content-text {
      @include mq('large') {
        margin-left: 5px;
      }

      p {
        &:last-child {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &-bottom-content-top-text {
      @include mq('x-large') {
        margin-right: var(--emu-common-spacing-xxs);
      }
    }
  }
}
