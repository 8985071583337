#contact-us {
  .contact-us {
    &__banner {
      background: linear-gradient(
        90deg,
        var(--emu-semantic-colors-primary-300) 0%,
        var(--emu-semantic-colors-primary-400) 100%
      );
      position: relative;
      height: 308px; // as per live
      margin-top: -27px;

      @include mq('medium') {
        height: 450px; // as per live
      }

      @include mq('tablet') {
        height: 600px; // as per live
      }

      @include mq('large') {
        margin-top: -10px;
      }

      @include mq('x-large') {
        height: 610px; // as per live
      }

      > .container {
        height: 100%;
      }
    }

    &__banner-img {
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      img {
        position: relative;
        left: -42px;
        top: 27px;
        width: 292px;
        height: 80%; // In the ref website
        object-fit: cover;

        @include mq('small-plus') {
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }

        @include mq('x-large') {
          max-width: 1196px;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('xx-large') {
          max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
        }
      }

      &--mobile {
        @include mq('small-plus') {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include mq('small-plus') {
          display: inline-block;
        }
      }
    }

    &__banner-title-container {
      position: relative;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      height: 100%;

      @include mq('small-plus') {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('xx-large') {
        max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
      }

      .title {
        /*
          Trap the `absolute` positioned heading
          so that the width of parent is x instead of (x - horizontal_padding)
        */
        @include mq('small-plus') {
          position: relative;
          width: 100%;
          height: 100%;
        }

        @include mq('medium') {
          position: unset;
        }
      }
    }

    &__banner-title {
      h1 {
        color: var(--emu-common-colors-white);
        max-width: 112px; // as per live
        box-sizing: content-box;
        padding-left: 25px;
        position: absolute;
        margin-left: 66.666667%;
        bottom: 0;

        @include mq('small-plus') {
          padding-left: 45px;
          margin-left: 58.333333%;
        }

        @include mq('medium') {
          padding-left: 30px;
          max-width: none;
          bottom: auto;
          top: 50%;
          transform: translateY(-50%);
        }

        @include mq('xx-large') {
          font-size: 130px;
          line-height: 117px;
        }
      }
    }

    &__local-centers-container {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      margin-bottom: 30px;

      @include mq('small-plus') {
        padding-left: 45px;
        padding-right: 45px;
      }

      @include mq('medium') {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &__local-centers {
      padding-left: 15px;
      padding-top: 35px;
      padding-right: 15px;
      padding-bottom: 30px;

      .title {
        @include mq('tablet') {
          padding-left: 15px;
          padding-right: 15px;
        }

        h4 {
          margin-bottom: 30px;
          padding-left: 15px;
          padding-right: 15px;

          @include mq('small-plus') {
            padding-left: 45px;
            padding-right: 45px;
          }

          @include mq('tablet') {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }

    &__local-centers-info {
      display: flex;
      flex-direction: column;

      @include mq('tablet') {
        flex-direction: row;
        justify-content: space-evenly;
      }

      .text:not(.aaaem-text) {
        @include mq('medium') {
          max-width: 331px;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('tablet') {
          max-width: none;
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
          flex-grow: 0;
          flex-basis: 280px;
        }

        @include mq('x-large') {
          flex-basis: 360px;
        }
      }
    }

    &__local-center-info {
      @include mq('x-large') {
        padding-left: 14.5px;
        padding-right: 14.5px;
      }

      p {
        color: var(--emu-semantic-colors-primary-300);
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: 25px;
        letter-spacing: normal;
        margin-bottom: 30px;

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: 35px;
        }

        b {
          font-family: var(--emu-semantic-font-families-mono);
          font-weight: var(--emu-common-font-weight-regular);
        }

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__intro {
      padding-left: 30px;
      padding-right: 30px;

      @include mq('small-plus') {
        padding-left: 60px;
        padding-right: 60px;
      }

      &-wrapper {
        padding-top: 50px;
        padding-bottom: 30px;

        @include mq('medium') {
          padding-bottom: 90px;
        }

        @include mq('x-large') {
          padding-top: 100px;
        }
      }

      &-title {
        @include mq('medium') {
          margin-bottom: 60px;
        }
      }

      &-content {
        h4 {
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 17.6px; // as per live
          line-height: 25px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            letter-spacing: 0.54px;
            margin-bottom: 19.8px; // as per live
          }

          @include mq('x-large') {
            margin-bottom: 43.2px; // as per live
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          &:last-of-type {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
}
