[data-component='carousel-v2'].emu-carousel {
  .emu-carousel {
    &__action {
      span {
        svg {
          display: none;
        }

        &::before {
          display: block;
        }
      }

      &-prev {
        span {
          &::before {
            content: url('../../assets/images/prev-arrow.png');
          }
        }
      }

      &-next {
        span {
          &::before {
            content: url('../../assets/images/next-arrow.png');
          }
        }
      }
    }
  }

  // used in partner with us page - testimonial carousel
  // used in results and testimonials page - testimonial carousel
  // used in backed by science page - how coolsculpting works and scientific evidence carousel
  &.emu-carousel--with-count {
    .tns-controls.js-show {
      position: absolute;
      width: 106px; // as per live
      margin-left: auto;
      margin-right: auto;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .tns-outer {
      margin-bottom: 24px;

      @include mq('tablet') {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    .can-show {
      bottom: -24px;
      left: 0;
      right: 0;
      width: 58px; // as per live
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;

      @include mq('tablet') {
        display: none;
      }

      span {
        &:first-child {
          font-family: var(--emu-semantic-font-families-avenir-next-medium);
          font-weight: var(--emu-semantic-font-weight-600);
        }

        &:last-child {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
    }

    .emu-carousel {
      &__action {
        top: 0;
        transform: unset;
        height: 24px; // to match live

        @include mq('tablet') {
          display: none;
        }

        span {
          svg {
            display: none;
          }

          &::before {
            font-size: 24px;
            font-family: var(--emu-semantic-font-families-glyphicons-halflings);
            color: var(--emu-semantic-colors-primary-400);
            opacity: 0.3;
            line-height: 1;
          }
        }

        &:focus,
        &:hover {
          span {
            &::before {
              opacity: 1;
            }
          }
        }

        &-prev {
          span {
            &::before {
              content: '\e079';
            }
          }
        }

        &-next {
          span {
            &::before {
              content: '\e080';
            }
          }
        }
      }
    }
  }
}
