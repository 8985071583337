#coolsculpting-elite {
  .coolsculpting-elite {
    &__feature-list {
      &-wrapper {
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 720px; // as per live
        }

        @include mq('tablet') {
          max-width: 960px; // as per live
          padding-left: 15px;
          padding-right: 15px;
          display: flex;

          > .container {
            flex: 0 0 50%;
            align-self: center;
          }

          > .image:last-child {
            flex: 0 0 50%;
          }
        }

        @include mq('x-large') {
          max-width: 1196px; // as per live
        }

        .image {
          img {
            width: 100%; // as per live

            @include mq('medium') {
              height: 1083px; // as per live
              object-fit: cover;
            }

            @include mq('tablet') {
              height: 699px; // as per live
            }

            @include mq('x-large') {
              height: 877px; // as per live
            }
          }
        }

        &--reverse {
          @include mq('tablet') {
            flex-direction: row-reverse;
          }

          h5 {
            color: var(--emu-semantic-colors-primary-400);
          }

          p {
            color: var(--emu-semantic-colors-primary-300);
          }
        }
      }

      &-content-container {
        padding-top: 50px;
        padding-bottom: 36px;
        padding-left: 45px;
        padding-right: 45px;

        @include mq('tablet') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
          padding-right: 40px;
        }

        @include mq('x-large') {
          padding-top: 129px;
          padding-bottom: 129px;
          padding-right: 35px;
        }
      }

      &-content {
        h5 {
          margin-top: 20px;
          margin-bottom: 10px;
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-semantic-font-weight-500);
          line-height: 1.4;
          letter-spacing: 0.32px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-small);
          }

          &:first-of-type {
            margin-top: 40px;
          }

          @include mq('x-large') {
            margin-top: 42px;
            font-size: 22px;
            letter-spacing: 0.44px;
          }
        }

        p {
          margin-bottom: 10px;

          sup {
            font-size: 10px;
          }

          &:last-of-type {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &-disclaimer {
        p {
          margin-top: 15px;
          opacity: 0.8;

          &:not(:last-child) {
            margin-bottom: var(--emu-common-spacing-medium);
          }

          @include mq('x-large') {
            &:first-of-type {
              margin-top: 42px;
            }
          }
        }
      }

      &-title {
        h2 {
          margin-bottom: 25px;

          @include mq('x-large') {
            margin-bottom: 50px;
          }
        }
      }
    }

    &__tab-section {
      padding-top: 50px;
      padding-bottom: 50px;

      @include mq('tablet') {
        padding-top: 80px;
        padding-bottom: var(--emu-common-spacing-none);
      }

      &-wrapper {
        padding-left: 63px;
        padding-right: 63px;

        @include mq('medium') {
          padding-left: 15px;
          padding-right: 15px;
        }

        .text {
          p {
            @include mq('tablet') {
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);
              margin-left: auto;
              margin-right: auto;
              width: 82%; //as per live
            }
          }
        }
      }

      &-title {
        h2 {
          margin-bottom: 17px;

          @include mq('tablet') {
            margin-bottom: 50px;
          }
        }
      }

      &-tab-container {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);

        ul {
          li {
            a {
              &:hover {
                background-color: var(--emu-common-colors-white);
                color: var(--emu-semantic-colors-primary-300);
              }

              @include mq('tablet') {
                padding-left: 25px;
                padding-right: 25px;
              }
            }
          }
        }
      }

      &-image {
        display: flex;
        justify-content: center;

        img {
          @include mq('small') {
            height: 204px; //as per live
            width: 375px; //as per live
          }

          @include mq('small-plus') {
            height: auto; //as per live
            width: auto; //as per live
          }
        }
      }
    }
  }
}
