// Match the authored ID instead of 'backed-by-science'
#coolsculpting-backed-by-science {
  .banner {
    &__img--mobile {
      img {
        object-fit: fill;
      }
    }

    &__disclaimer {
      @include mq('tablet') {
        left: 0;
        width: auto;
      }
    }
  }

  .backed-by-science {
    &__treatment-areas {
      padding-top: 50px;
      padding-left: var(--emu-common-spacing-none);
      padding-bottom: 50px;
      padding-right: var(--emu-common-spacing-none);

      @include mq('tablet') {
        padding-top: 80px;
        padding-bottom: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        padding-bottom: 75px;
      }

      &-title,
      &-text {
        padding-left: 63px;
        padding-right: 63px;

        @include mq('medium') {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &-title {
        margin-bottom: 17px;

        @include mq('tablet') {
          margin-bottom: 50px;
        }
      }

      &-text {
        @include mq('tablet') {
          margin-left: auto;
          margin-right: auto;
          width: 793px; // From ref webpage

          @include mq('x-large') {
            width: 956px; // From ref webpage
          }
        }
      }

      &-tab {
        &--main {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 1.5px;

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          .tabs {
            margin-top: var(--emu-common-spacing-none);
          }

          > ul {
            margin-bottom: 42px;

            @include mq('medium') {
              margin-bottom: 80px;
            }

            @include mq('large') {
              margin-bottom: 50px;
            }

            li:first-child {
              margin-right: 17.5px; // Actual: 15px

              @include mq('tablet') {
                margin-right: 25.5px; // Actual: 23px
              }
            }

            a[role='tab'] {
              border-color: var(--emu-semantic-colors-primary-400);
              background-color: var(--emu-common-colors-transparent);
              color: var(--emu-semantic-colors-primary-400);

              @include mq('tablet') {
                padding-left: 45px;
                padding-right: 45px;
              }

              &[aria-selected='true'] {
                background-color: var(--emu-semantic-colors-primary-400);
                color: var(--emu-common-colors-white);
              }

              span {
                // Visual centering to match design
                display: inline-block;
                transform: translateY(-1px);
              }
            }
          }
        }

        &--nested {
          display: flex;
          flex-direction: column-reverse;

          // Cannot use `tablet-mobile-view` utility class
          // as `display: flex` cannot be overridden by the utility
          @include mq('large') {
            display: none;
          }

          img {
            width: 100%;
            min-height: 344px; // From ref webpage
          }

          ul {
            margin-bottom: var(--emu-common-spacing-none);
            padding-top: 30px;

            li:first-child {
              margin-right: 2.5px;
            }

            a[role='tab'] {
              border-width: var(--emu-common-border-width-none);
              background-color: var(--emu-common-colors-transparent);
              border-radius: var(--emu-common-border-radius-none);
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              line-height: 21.6px;
              letter-spacing: normal;
              padding-left: 12px;
              padding-top: 6px;
              padding-right: 12px;
              padding-bottom: 6px;
              color: #393a3b;
              opacity: 0.4;

              &[aria-selected='true'] {
                color: var(--emu-semantic-colors-primary-400);
                opacity: 1;
              }
            }

            span {
              // Visual centering to match design
              display: inline-block;
              transform: translateY(-1px);
            }
          }
        }
      }

      &-desktop-images {
        // Cannot use `desktop-view` utility class
        // as `display: flex` (later) cannot be set by the utility
        display: none;

        @include mq('large') {
          display: flex;
          justify-content: space-evenly;
        }

        .image {
          flex-basis: 480px;
          flex-grow: 0;

          @include mq('x-large') {
            flex-basis: 598px;
          }

          .cmp-image {
            width: 100%;

            img {
              max-width: 100%;
              width: auto;
              max-height: 460px; // From ref webpage
              object-fit: contain;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }

    &__how-coolsculpting-works {
      padding-bottom: 50px;

      @include mq('tablet') {
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 122px;
        margin-left: auto;
        margin-right: auto;
        max-width: 960px; // as per live
      }

      @include mq('x-large') {
        max-width: 1196px; // as per live
      }

      &-carousel {
        user-select: none;

        @include mq('tablet') {
          .emu-carousel {
            &__content {
              display: flex;
              justify-content: space-evenly;
            }
          }
        }

        &-item {
          margin-bottom: 23px;

          @include mq('tablet') {
            margin-bottom: var(--emu-semantic-spacing-none);
            width: 310px; // as per live
          }

          @include mq('x-large') {
            width: 389px; // as per live
          }
        }

        &-image {
          margin-bottom: 25px;
          padding-top: 10px;
          padding-bottom: 10px;
          position: relative;

          @include mq('tablet') {
            margin-bottom: 45px;
          }

          img {
            width: 115px; // to match live
            height: 115px; // to match live
            margin-left: auto;
            margin-right: auto;
            position: relative;
            z-index: var(--emu-common-other-z-index-layer);

            @include mq('tablet') {
              width: 132px; // to match live
              height: 132px; // to match live
            }
          }

          &::before {
            position: absolute;
            content: ' ';
            border: 3px solid var(--emu-semantic-colors-primary-400);
            border-radius: 50%;
            background-color: var(--emu-common-colors-white);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 130px; // as per live
            height: 130px; // as per live

            @include mq('tablet') {
              width: 153px; // as per live
              height: 153px; // as per live
            }
          }

          &::after {
            position: absolute;
            content: '';
            background-color: var(--emu-semantic-colors-primary-400);
            width: 100%;
            height: 3px; // as per live
            z-index: var(--emu-common-other-z-index-behind);
            top: 50%;
            transform: translateY(-50%);
          }

          &--after-target {
            &::after {
              left: 50%;
            }
          }

          &--after-reduce {
            &::after {
              right: 50%;
            }
          }
        }

        &-bottom-wrapper {
          padding-left: 45px;
          padding-right: 45px;

          @include mq('tablet') {
            padding-left: var(--emu-common-spacing-large);
            padding-right: var(--emu-common-spacing-large);
          }
        }

        &-title {
          margin-bottom: 15px;
          color: var(--emu-semantic-colors-primary-400);

          @include mq('tablet') {
            margin-bottom: 26px;
          }

          h4 {
            font-size: 19px;
            font-family: var(--emu-semantic-font-families-heading);
            line-height: 1.2;
            letter-spacing: 0.38;

            @include mq('tablet') {
              font-size: 22px;
              letter-spacing: 0.44px;
            }
          }
        }

        &-text {
          margin-bottom: 52px;
          font-family: var(--emu-semantic-font-families-body);

          @include mq('x-large') {
            min-height: 135px; //as per live
          }
        }

        &-bottom-image {
          width: 100%;
          max-width: 324px; //to match live
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &__scientific-evidence {
      padding-top: 50px;
      padding-bottom: 50px;

      @include mq('tablet') {
        padding-top: 100px;
        padding-bottom: 100px;
      }

      &-title {
        padding-left: 45px;
        padding-right: 45px;

        h4 {
          margin-bottom: 25px;

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: 40.8px;
            margin-bottom: 45px;
          }
        }
      }

      &-text {
        p {
          padding-left: 40px;
          padding-right: 40px;
          margin-bottom: 25px;

          @include mq('tablet') {
            margin-bottom: 45px;
          }

          @include mq('x-large') {
            margin-left: 108px;
            margin-right: 108px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }

      &-carousel {
        padding-left: 15px;
        padding-right: 15px;

        @include mq('tablet') {
          padding-right: var(--emu-semantic-spacing-none);
          padding-left: var(--emu-semantic-spacing-none);
        }

        .tns-inner {
          @include mq('tablet') {
            max-width: 960px; //as per live
            margin-left: auto;
            margin-right: auto;
            padding-left: 15px;
            padding-right: 15px;
          }

          @include mq('x-large') {
            max-width: 1196px; //to match live
            padding-left: var(--emu-semantic-spacing-none);
            padding-right: var(--emu-semantic-spacing-none);
          }

          @include mq('xx-large') {
            max-width: 1304px; //to match live
          }

          .emu-carousel__content {
            @include mq('tablet') {
              width: fit-content;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        &-container {
          max-width: 280px; //as per live
          min-height: 350px; //as per live
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 25px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 45px;
          padding-bottom: 30px;
          user-select: none;

          @include mq('tablet') {
            max-width: 303px; //to match live
            min-height: 320px; //as per live
            padding-bottom: 20px;
            padding-top: 27px;
            margin-left: 3.5px;
            margin-right: 3.5px;
            margin-bottom: 50px;
          }

          @include mq('x-large') {
            max-width: 292px; //as per live
          }

          @include mq('xx-large') {
            max-width: 326px; // as per live
            padding-left: 43px;
            padding-right: 43px;
            margin-left: 5px;
            margin-right: 5px;
          }

          .image {
            margin-left: 50px;
            margin-right: 50px;
            margin-bottom: 40px;

            @include mq('tablet') {
              margin-left: 52px;
              margin-right: 52px;
            }

            @include mq('x-large') {
              margin-left: 46px;
              margin-right: 46px;
            }

            @include mq('xx-large') {
              margin-left: 30.5px;
              margin-right: 30.5px;
            }
          }
        }

        &-image {
          background-color: var(--emu-common-colors-white);
          width: 150px; //as per live
          height: 150px; //as per live
          border-radius: 50%;
          display: flex;
          justify-content: center;

          @include mq('tablet') {
            width: 169px; //as per live
            height: 169px; //as per live
          }

          &--width {
            img {
              width: 126px; //as per live

              @include mq('tablet') {
                width: 142px; //as per live
              }
            }
          }

          img {
            margin-top: auto;
            margin-bottom: auto;
          }
        }

        &-title {
          padding-left: var(--emu-common-spacing-medium);
          padding-right: var(--emu-common-spacing-medium);

          @include mq('tablet') {
            padding-left: 22px;
            padding-right: 22px;
            margin-top: 20px;
          }

          @include mq('x-large') {
            padding-left: var(--emu-semantic-spacing-none);
            padding-right: var(--emu-semantic-spacing-none);
          }

          h3 {
            letter-spacing: 0.32px;
            line-height: var(--emu-semantic-sizing-one-line-height-narrow);
            font-size: var(--emu-semantic-font-sizes-narrow-small);

            @include mq('tablet') {
              font-size: var(--emu-semantic-font-sizes-wide-medium);
              font-family: var(--emu-semantic-font-families-avenir-next-medium);
              font-weight: var(--emu-semantic-font-weight-600);
              line-height: 21.6px;
            }

            > sup {
              padding-left: 2.5px;
              font-size: 60%;
              top: -8px;

              @include mq('tablet') {
                padding-left: var(--emu-common-spacing-xs); // to match live
              }

              @include mq('x-large') {
                padding-left: 2.5px;
              }
            }
          }
        }
      }

      &-disclaimer {
        margin-top: 60px;
        padding-left: 45px;
        padding-right: 45px;

        @include mq('tablet') {
          margin-top: var(--emu-semantic-spacing-none);
        }

        @include mq('x-large') {
          margin-top: 10px;
        }

        p {
          opacity: 0.8;
        }
      }
    }
  }
}
