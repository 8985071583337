.tabs {
  margin-top: 35px;

  ul {
    justify-content: center;
    margin-bottom: 12px;

    @include mq('medium') {
      margin-bottom: 50px;
    }

    li {
      &:not(:last-child) {
        margin-right: 17px;

        @include mq('tablet') {
          margin-right: 25px;
        }
      }
    }

    a {
      font-size: var(--emu-common-font-sizes-narrow-medium);
      font-family: var(--emu-semantic-font-families-heading);
      border-radius: var(--emu-common-border-radius-large);
      line-height: 12px;
      letter-spacing: 0.24px;

      @include mq('tablet') {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        border-width: 3px;
        letter-spacing: 0.36px;

        &[aria-selected='true'] {
          border-width: 3px;
        }
      }
    }
  }
}
