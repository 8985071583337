header.experiencefragment {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
}

// handle header styles in custom templates
* > :has(.cmp-experiencefragment--header) {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
}

.skip-menu-container a.skip-menu {
  position: absolute;
}

.header {
  &__menu {
    box-shadow: 0px 0px 20px rgba(0, 37, 84, 0.3); // to match live

    background-image: linear-gradient(
      90deg,
      var(--emu-semantic-colors-primary-300) 0%,
      var(--emu-semantic-colors-primary-400) 100%
    ); // as per live

    .emu-image__link {
      display: flex; // to remove extra spacing around image
    }

    &-mobile.aaaem-container {
      padding-left: 25px;
      padding-right: 25px;
      position: relative;

      @include mq('large') {
        max-width: 960px; // to match live
        margin-left: auto;
        margin-right: auto;
        padding-left: 32.5px;
        padding-right: 32.5px;
        position: unset;
      }

      @include mq('x-large') {
        max-width: 1196px; // to match live
        padding-left: 47.5px;
        padding-right: 47.5px;
      }

      > .button {
        position: absolute;
        top: 25px;
        right: 25px;

        @include mq('large') {
          display: none;
        }

        .emu-button.emu-button-v2 {
          padding-top: 7px; // to match live
          padding-bottom: 7px; // to match live
          position: relative;
          top: -7.5px;
          background: var(--emu-common-colors-transparent);

          &:hover,
          &:focus {
            opacity: 0.75;
          }

          span {
            height: 2px; // to match live
            width: 20px; // to match live
            background: var(--emu-common-colors-white);
            border-radius: 10px;

            &::before,
            &::after {
              content: '';
              background: inherit;
              height: inherit;
              width: inherit;
              position: absolute;
              border-radius: inherit;
              right: 0;
            }

            &::before {
              top: 0;
            }

            &::after {
              bottom: 0;
            }
          }
        }
      }

      > div.container {
        position: absolute;

        @include mq('large') {
          position: unset;
        }

        .header__menu-mobile-dropdown {
          display: none;
          transition: all var(--emu-common-other-time-duration-instant) ease;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--emu-common-colors-white);
          margin-left: auto;
          margin-right: auto;
          overflow-y: auto;

          @include mq('large') {
            display: block;
            background-color: var(--emu-common-colors-transparent);
            position: unset;
            height: auto;
            overflow-y: unset;
          }

          &.js-toggle-on {
            display: block;
          }
        }
      }
    }

    &-disclaimer {
      padding-bottom: 10px;

      @include mq('large') {
        display: none;
      }

      p {
        letter-spacing: normal;
      }
    }

    &-mobile-top {
      padding-left: 25px;
      padding-right: 25px;

      &-content {
        padding-top: 25px;
        padding-bottom: 25px;
        display: flex;
        justify-content: space-between;
      }
    }

    &-mobile-dropdown {
      background-color: var(--emu-common-colors-white);

      &-content-wrapper.aaaem-container {
        padding-left: 25px;
        padding-right: 25px;

        @include mq('large') {
          max-width: 960px; // to match live
          margin-left: auto;
          margin-right: auto;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          max-width: 1196px; // to match live
        }
      }

      &-top-content {
        padding-top: 25px;
        padding-bottom: 25px;
        display: flex;
        justify-content: space-between;

        @include mq('large') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
        }

        > .button {
          @include mq('large') {
            display: none;
          }

          .emu-button.emu-button-v2 {
            position: absolute;
            top: 7.5px;
            right: 0;
            background: var(--emu-common-colors-transparent);

            &:hover,
            &:focus {
              opacity: 0.75;
              background: var(--emu-common-colors-transparent);
            }

            span {
              height: 2px; // to match live
              width: 20px; // to match live
              background: var(--emu-semantic-colors-primary-400);
              border-radius: 10px;
              transform: rotate(45deg);

              &::before,
              &::after {
                content: '';
                background: inherit;
                height: inherit;
                width: inherit;
                position: absolute;
                border-radius: inherit;
                right: 0;
              }

              &::before {
                top: 0;
                transform: rotate(90deg);
              }

              &::after {
                bottom: 0;
                display: none;
              }
            }
          }
        }
      }

      &-bottom-content.aaaem-container {
        @include mq('large') {
          position: absolute;
          bottom: 41px;
          right: 0;
          padding-right: calc((100% - 960px) / 2 + 32.5px); // to match live
        }

        @include mq('x-large') {
          padding-right: calc((100% - 1196px) / 2 + 47.5px); // to match live
        }

        > .button {
          text-align: center;
          top: 16.5px;

          @include mq('large') {
            text-align: right;
            top: 7.5px;
          }
        }
      }

      &-bottom-content-text {
        margin-right: 20px;
        margin-left: 20px;

        @include mq('large') {
          margin-right: var(--emu-common-spacing-none);
          margin-left: var(--emu-common-spacing-none);
        }

        ul {
          margin-bottom: 55px;
          padding-top: 25px;
          border-top: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-primary-50);
          margin-top: var(--emu-common-spacing-none);
          list-style: none;
          padding-left: var(--emu-common-spacing-none);

          @include mq('large') {
            border-top: none;
            margin-bottom: var(--emu-common-spacing-none);
            padding-top: var(--emu-common-spacing-none);
            display: flex;
            justify-content: right;
            gap: var(--emu-common-spacing-xxs);
          }

          li {
            margin-bottom: 20px;
            line-height: 22px;
            letter-spacing: 0.32px;
            font-weight: var(--emu-semantic-font-weight-600);
            color: var(--emu-semantic-colors-primary-300);

            @include mq('large') {
              color: var(--emu-common-colors-white);
              font-size: var(--emu-semantic-font-sizes-wide-xs);
              line-height: 17px;
              letter-spacing: 0.24px;
              margin-bottom: 17.5px;
              padding-left: 10px;

              &:not(:last-child) {
                border-right: 0.5px solid var(--emu-common-colors-white);
                padding-right: 10px;
              }
            }

            a {
              text-decoration: none;

              &:hover {
                font-family: var(
                  --emu-semantic-font-families-avenir-next-medium
                );

                @include mq('large') {
                  font-family: inherit;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      &-bottom-content-disclaimer {
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          line-height: 1.43;
          margin-bottom: 15px;
          letter-spacing: normal;
        }
      }

      &-cta.emu-button.emu-button-v2 {
        border: 2.5px solid var(--emu-semantic-colors-primary-400);
        padding-top: 18px;
        padding-bottom: 18px;
        padding-right: 30px;
        padding-left: 30px;
        text-decoration: none;
        font-family: var(--emu-semantic-font-families-body);
        font-weight: var(--emu-semantic-font-weight-600);
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 22px;
        letter-spacing: 0.32px;
        color: var(--emu-semantic-colors-primary-400);

        @include mq('large') {
          border-color: var(--emu-common-colors-white);
          color: var(--emu-common-colors-white);
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.28px;
          padding-top: 10.5px;
          padding-bottom: 10.5px;
          padding-right: 24px;
          padding-left: 24px;
        }

        &:hover,
        &:active {
          background-color: var(--emu-semantic-colors-primary-400);
          color: var(--emu-common-colors-white);

          @include mq('large') {
            background-color: var(--emu-common-colors-white);
            color: var(--emu-semantic-colors-primary-300);
          }
        }
      }
    }

    &-logo-white {
      padding-top: 25px;
      padding-bottom: 25px;

      @include mq('large') {
        padding-top: 40px;
        padding-bottom: 17px;
      }

      img {
        max-width: 109px; // as per live

        @include mq('large') {
          max-width: 223px; // as per live
        }
      }
    }

    &-logo-blue {
      @include mq('large') {
        display: none;
      }

      img {
        max-width: 109px; // as per live
      }
    }
  }
}

.u-hide-overflow-in-mobile {
  @include mq('1023px', 'max-width') {
    overflow: hidden;
    height: 100vh;
  }
}
