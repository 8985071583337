#home {
  .home {
    &__banner {
      margin-top: -27px;
      background: linear-gradient(
        90deg,
        var(--emu-semantic-colors-primary-300) 0%,
        var(--emu-semantic-colors-primary-400) 100%
      );
      height: 308px; //as per live
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      @include mq('medium') {
        height: auto; //as per live
      }

      @include mq('large') {
        margin-top: -10px;
      }

      &-image {
        @include mq('x-large') {
          padding-top: 23px;
        }
      }
    }
  }
}
