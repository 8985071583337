.intro {
  background-color: var(--emu-common-colors-white);

  @include mq('medium') {
    position: relative;
    margin-top: -43px;
  }

  > .container:not(:first-child) {
    .intro__wrapper {
      padding-top: var(--emu-common-spacing-none);

      @include mq('medium') {
        position: relative;
        margin-top: -43px;
      }
    }
  }

  &__wrapper {
    padding-top: 52px;
    padding-bottom: 47px;
    padding-left: 30px;
    padding-right: 30px;

    @include mq('x-large') {
      padding-top: 100px;
      padding-bottom: 100px;
      padding-left: 105px;
      padding-right: 105px;
    }

    > .button {
      text-align: center;
    }
  }

  &__title {
    width: 80%;
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;

    @include mq('x-large') {
      margin-bottom: 34px;
    }
  }

  &__text {
    p {
      &:not(:last-child) {
        margin-bottom: 17.6px; // as per live

        @include mq('x-large') {
          margin-bottom: 38.4px; // as per live
        }
      }

      sup {
        font-size: 10px;
      }

      span.emphasis {
        color: var(--emu-semantic-colors-primary-400);
        font-size: 20px;
      }
    }
  }

  &__cta {
    margin-top: 35px;

    @include mq('x-large') {
      margin-top: 50px;
    }
  }

  &__disclaimer {
    margin-top: 25px;
    opacity: 0.8;

    @include mq('x-large') {
      width: 72%; // as per live
      margin-left: auto;
      margin-right: auto;
      margin-top: 47px;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    @include mq('x-large') {
      margin-bottom: 50px;
    }

    img {
      max-height: 45px; // as per live

      @include mq('x-large') {
        max-height: 54px; // as per live
      }
    }
  }
}
