.navigationlist {
  .emu-navigation {
    &__content-wrapper {
      margin-top: 72px;
      position: relative;

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
        position: unset;
      }

      nav {
        background-color: var(--emu-common-colors-white);
        padding-left: 20px;

        @include mq('large') {
          background-color: var(--emu-common-colors-transparent);
          padding-left: var(--emu-common-spacing-none);
          margin-left: 42.5px;
          position: unset;
        }

        ul {
          flex-direction: column;
          display: block;

          @include mq('large') {
            flex-direction: row;
            position: unset;
            display: flex;
          }

          li {
            @include mq('large') {
              margin-right: 40px;
            }

            a {
              display: inline-block;

              @include mq('large') {
                display: block;
              }
            }

            > a {
              font-family: var(--emu-semantic-font-families-avenir-next-medium);
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              font-weight: var(--emu-semantic-font-weight-500);
              line-height: 25px;
              letter-spacing: 0.36;
              margin-bottom: 25px;

              @include mq('large') {
                color: var(--emu-common-colors-white);
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.28;
                margin-bottom: var(--emu-common-spacing-none);
                padding-bottom: 30px;
                position: relative;
              }

              &:hover {
                opacity: 0.75;

                @include mq('large') {
                  background-color: var(--emu-common-colors-transparent);
                  color: var(--emu-common-colors-white);
                }
              }
            }

            &.emu-navigation__item-parent {
              > a {
                position: relative;

                &::after {
                  content: '\e259';
                  color: var(--emu-semantic-colors-primary-400);
                  font-family: var(
                    --emu-semantic-font-families-glyphicons-halflings
                  );
                  font-size: var(--emu-common-font-sizes-wide-medium);
                  line-height: 1;
                  display: inline-block;
                  letter-spacing: unset;
                  position: absolute;
                  right: -24px;
                  top: 4px;

                  @include mq('large') {
                    display: none;
                  }
                }

                &:hover {
                  @include mq('large') {
                    &::before {
                      content: '';
                      height: 3px;
                      width: 100%;
                      background-color: var(--emu-common-colors-white);
                      display: block;
                      position: absolute;
                      bottom: 0;
                    }
                  }
                }
              }
            }

            ul {
              display: none;
            }

            &.js-toggle-dropdown {
              ul {
                display: block;

                @include mq('large') {
                  display: flex;
                }
              }

              > a {
                &::after {
                  transform: rotateZ(180deg);
                }
              }
            }

            ul {
              margin-top: var(--emu-common-spacing-none);
              padding-left: 30px;

              @include mq('medium') {
                position: relative;
              }

              @include mq('large') {
                padding-left: var(--emu-common-spacing-none);
                position: absolute;
                width: 100%;
                right: 0;
                left: 0;
                background-color: rgba(0, 94, 184, 0.75);
                padding-left: calc(
                  (100vw - 960px) / 2 + 75.5px
                ); // to match live
              }

              @include mq('x-large') {
                padding-left: calc(
                  (100vw - 1196px) / 2 + 87.5px
                ); // to match live
              }

              li {
                a {
                  font-family: var(--emu-semantic-font-families-body);
                  line-height: 22.4px;
                  font-weight: var(--emu-common-font-weight-regular);
                  color: var(--emu-semantic-colors-primary-400);

                  @include mq('large') {
                    color: var(--emu-common-colors-white);
                    line-height: 20px;
                    font-weight: var(--emu-semantic-font-weight-500);
                    padding-top: 20px;
                    padding-bottom: 20px;
                  }

                  &:hover {
                    @include mq('large') {
                      background-color: var(--emu-common-colors-transparent);
                      color: var(--emu-common-colors-white);
                      opacity: 1;

                      &::before {
                        content: '';
                        height: 3px;
                        width: 100%;
                        background-color: var(--emu-common-colors-white);
                        display: block;
                        position: absolute;
                        bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__item {
      &--active {
        > a {
          color: var(--emu-semantic-colors-primary-400);

          @include mq('large') {
            color: var(--emu-common-colors-white);

            &::before {
              content: '';
              height: 3px;
              width: 100%;
              background-color: var(--emu-common-colors-white);
              display: block;
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
