.video-testimonial {
  background-image: url('../../assets/images/video-testimonial-bg.jpg'); // as per live
  background-color: var(--emu-semantic-colors-primary-800);
  background-repeat: no-repeat;
  background-size: 100% 100%; // as per live
  padding-top: 50px;
  padding-bottom: 50px;

  @include mq('medium') {
    padding-top: 90px;
    padding-bottom: 60px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @include mq('large') {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  &__wrapper {
    @include mq('large') {
      max-width: 960px; // as per live
      margin-left: auto;
      margin-right: auto;
      padding-left: 15px;
      padding-right: 15px;
    }

    @include mq('x-large') {
      max-width: 1196px; // as per live
    }
  }

  &__title {
    margin-bottom: 5px;

    h3 {
      font-family: var(--emu-semantic-font-families-heading);
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: 35px;
      font-weight: var(--emu-common-font-weight-black);

      @include mq('small-plus') {
        font-size: 46px;
        line-height: 50px;
      }

      @include mq('medium') {
        font-size: 64px;
        line-height: 69.5px;
      }

      @include mq('large') {
        font-size: 54px;
        line-height: 58px;
      }

      br {
        @include mq('large') {
          display: none;
        }
      }
    }
  }

  &__text {
    margin-bottom: 20px;

    @include mq('small-plus') {
      padding-left: 20px;
      margin-bottom: 65px;
    }

    @include mq('medium') {
      margin-bottom: 50px;
    }

    @include mq('large') {
      margin-bottom: 65px;
    }

    p {
      font-size: 14px;
      line-height: 26px;
      letter-spacing: unset;

      @include mq('small-plus') {
        font-size: 22px;
        line-height: 40px;
      }

      @include mq('medium') {
        font-size: 28px;
        line-height: 42px;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('large') {
        font-size: 24px;
        line-height: 31px;
      }

      sup {
        top: -10px;
      }

      br {
        @include mq('medium') {
          display: none;
        }
      }
    }
  }

  &__content {
    @include mq('large') {
      display: flex;
      justify-content: space-evenly;
      width: 930px; // as per live
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('x-large') {
      width: 1166px; // as per live
    }

    > .embed {
      @include mq('large') {
        width: 55%; // as per live
      }
    }

    > .text {
      @include mq('large') {
        width: 35%; // as per live
      }
    }
  }

  &__carousel.emu-carousel {
    .emu-carousel__action {
      top: 23%; // to match live

      @include mq('small-plus') {
        top: 58%; // to match live
      }

      @include mq('medium') {
        top: 26%; // to match live
      }

      @include mq('large') {
        top: 54%; // to match live
      }

      &-prev {
        left: var(--emu-common-spacing-small);

        @include mq('small-plus') {
          left: var(--emu-common-spacing-medium);
        }

        @include mq('medium') {
          left: -11px;
        }
      }

      &-next {
        right: var(--emu-common-spacing-small);

        @include mq('small-plus') {
          right: var(--emu-common-spacing-medium);
        }

        @include mq('medium') {
          right: -11px;
        }
      }
    }
  }

  &__embed {
    width: 80%; // as per live
    margin-left: auto;
    margin-right: auto;

    @include mq('small-plus') {
      width: 85%; // as per live
    }

    @include mq('medium') {
      width: calc((100vw - 100px) * 0.9); // as per live
    }

    @include mq('large') {
      width: 100%; // as per live
    }
  }

  &__description {
    width: 85%; // as per live
    margin-left: auto;
    margin-right: auto;
    user-select: none; // as per live

    @include mq('small-plus') {
      width: 100%;
      padding-left: 8%; // as per live
      padding-right: 8%; // as per live
    }

    @include mq('medium') {
      width: 90%; // as per live
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    @include mq('large') {
      width: 100%;
      padding-left: 20px;
    }

    h5 {
      margin-top: 54px;
      margin-bottom: 25px;
      font-family: var(--emu-semantic-font-families-body);
      font-size: var(--emu-common-font-sizes-wide-medium);
      line-height: 16.5px;
      font-weight: var(--emu-common-font-weight-black);

      @include mq('small-plus') {
        margin-top: 24px;
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 30.5px;
      }

      @include mq('medium') {
        margin-top: 60px;
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: 41px;
        font-weight: var(--emu-common-font-weight-bold);
      }

      @include mq('large') {
        margin-top: 24px;
        font-size: 22px;
        line-height: 30px;
        font-weight: var(--emu-common-font-weight-black);
      }
    }

    h4 {
      margin-bottom: 25px;
      font-size: 28px;
      line-height: 44px;
      font-family: var(--emu-semantic-font-families-body);
      font-weight: var(--emu-common-font-weight-regular);

      @include mq('small-plus') {
        margin-bottom: 20px;
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: 34px;
      }

      @include mq('medium') {
        font-size: 64px;
        line-height: 69.5px;
      }

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: 42px;
      }
    }

    p {
      line-height: 32px;
      letter-spacing: normal;

      @include mq('small-plus') {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 40px;
      }

      @include mq('medium') {
        font-size: 28px;
        line-height: 42px;
      }

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 30px;
        font-weight: var(--emu-semantic-font-weight-500);
      }
    }
  }
}
