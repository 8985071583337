.teaser {
  .content-container {
    position: absolute;
    bottom: var(--emu-common-spacing-none);
    width: 100%;

    @include mq('x-large') {
      padding-right: 148px;
      padding-left: 72px;
    }

    .cmp-teaser__description {
      p {
        display: block !important;
        font-size: 10px;
        line-height: 1.4;
        letter-spacing: 0.2px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.85);
        opacity: 0.8;
      }
    }
  }

  .aaaem-teaser {
    height: 100%;

    .teaser-container {
      height: 100%;

      .teaser-image-container {
        height: 100%;

        .cmp-teaser__image--desktop {
          height: 100%;

          .cmp-image {
            height: 100%;

            a {
              img {
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
