[data-component='container-isi-v2'] {
  .aaaem-isi {
    &-banner {
      position: relative;

      @include mq('large') {
        max-width: 960px; // to match live for desktop styles
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('x-large') {
        max-width: 1196px; // to match live
      }
    }

    &-container {
      &__button-wrapper {
        button {
          text-align: left;

          span {
            font-family: var(--emu-semantic-font-families-mono);
            line-height: 1.4;
            font-size: 13px;
            letter-spacing: 0.26;
            color: var(--emu-common-colors-white);
            display: flex;

            b {
              margin-left: 12px;
              margin-right: 9px;
              font-weight: var(--emu-common-font-weight-regular);
            }
          }
        }
      }

      &__isi-content {
        @include mq('large') {
          max-width: 960px; // to match live for desktop styles
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('x-large') {
          max-width: 1196px; // to match live
        }

        > div:first-child {
          @include mq('large') {
            padding-left: 15px;
            padding-right: 15px;
          }
        }

        h5 {
          font-family: var(--emu-semantic-font-families-avenir-next-medium);
          font-size: 13px;
          line-height: 1.1;
          letter-spacing: 0.26;
          font-weight: var(--emu-semantic-font-weight-600);
          color: var(--emu-semantic-colors-primary-400);
          margin-bottom: 5px;
        }

        p {
          font-size: 13px;
          line-height: 1.3;
          letter-spacing: 0.26;
          margin-bottom: 10px;

          b {
            font-family: var(--emu-semantic-font-families-avenir-next-medium);
            font-weight: var(--emu-common-font-weight-regular);
          }

          &:last-child {
            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
            }
          }
        }

        .isi__inner-container {
          @include mq('large') {
            display: flex;
          }

          > .text {
            margin-bottom: 33px;

            @include mq('large') {
              flex: 0 0 50%;

              &:first-child {
                padding-right: 43px;
              }
            }
          }

          &--coolsculpting,
          &--cooltone {
            > .text {
              @include mq('large') {
                flex: unset;
              }
            }
          }
        }
      }
    }
  }

  &.js-content-shown {
    .aaaem-isi-container.isi-container {
      transform: unset;
      position: relative;
    }
  }
}
