// references common styling START
.references {
  @include mq('large') {
    max-width: 960px; // to match live
    margin-left: auto;
    margin-right: auto;
  }

  @include mq('x-large') {
    max-width: 1196px; // to match live
  }

  p {
    font-size: 13px;
    line-height: 16.9px;
    letter-spacing: 0.26px;
    padding-top: 28px;
    padding-bottom: 28px;
    border-top: var(--emu-common-border-width-thin) solid rgba(0, 94, 184, 0.6);
    margin-left: 45px;
    margin-right: 45px;

    @include mq('large') {
      margin-left: 15px;
      margin-right: 15px;
    }

    @include mq('x-large') {
      padding-top: 42px;
      padding-bottom: 42px;
    }

    b {
      font-family: var(--emu-semantic-font-families-avenir-next-medium);
      color: var(--emu-semantic-colors-primary-400);
      font-weight: var(--emu-common-font-weight-regular);
    }
  }
}
// references common styling END
