#cooltone-results {
  .image-text-grid--cooltone {
    .image-text-grid {
      &__content {
        background-image: radial-gradient(
          65.66% 210.44% at 70.66% 47.41%,
          #005081 0%,
          #00335a 22.92%,
          #001733 100%
        ); // as per live

        > .container:has(.image-text-grid__content-container) {
          @include mq('tablet') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }
        }
      }

      &__content-container {
        padding-top: 100px;
        padding-bottom: 140px;
        padding-left: 40px;
        padding-right: 40px;

        @include mq('medium') {
          padding-left: 25px;
          padding-right: 25px;
        }

        @include mq('tablet') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          margin-right: auto;
        }

        @include mq('x-large') {
          max-width: 435px; // as per live
        }
      }

      &__title {
        max-width: 456px; // as per live

        h3 {
          font-family: var(--emu-semantic-font-families-body);
          font-size: 22px;
          line-height: 1.6;
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('tablet') {
            font-size: 28px;
          }
        }
      }

      &__text {
        margin-bottom: var(--emu-common-spacing-none);

        p {
          line-height: 1.43;
          letter-spacing: normal;

          @include mq('tablet') {
            font-size: 20px;
          }

          .emphasis {
            margin-left: 10px;
          }
        }
      }

      &__teaser {
        .content-container {
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 17px;

          .cmp-teaser__description {
            p {
              font-size: var(--emu-common-font-sizes-narrow-medium);
              line-height: 1.43;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .results {
    &__banner {
      margin-top: -27px;
      position: relative;
      // Space for the header text (Check the ref webpage)
      padding-top: 20px;

      @include mq('tablet') {
        padding-top: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        margin-top: -10px;
      }
    }

    &__hero {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      img {
        // background image
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 57% 100%;

        @include mq('medium') {
          object-position: 50% 0%;
        }
      }
    }

    &__banner-content {
      // Raise content above the image
      position: relative;
      padding-left: 30px;
      padding-right: 30px;

      @include mq('tablet') {
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    &__banner-disclaimer {
      padding-bottom: 15px;
      margin-top: 65px;

      @include mq('medium') {
        padding-bottom: 20px;
        margin-top: 60px;
      }

      p {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        // From ref webpage
        line-height: 135%;
        letter-spacing: normal;
      }
    }

    &__see-real-results {
      padding-top: 44px;
      padding-bottom: 44px;
      margin-bottom: 30px;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        margin-bottom: 40px;
      }

      img {
        width: 100%;
        // From ref webpage
        max-width: 190px;

        @include mq('medium') {
          // From ref webpage
          max-width: 676px;
        }
      }
    }

    &__ct-by-cs {
      img {
        // From ref webpage
        max-width: 121px;

        @include mq('medium') {
          // From ref webpage
          max-width: 221px;
        }
      }
    }
  }
}
