.testimonial {
  &.aaaem-container {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 45px;
    padding-right: 45px;

    @include mq('medium') {
      padding-right: 15px;
      padding-left: 15px;
    }

    @include mq('tablet') {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  &__title {
    @include mq('tablet') {
      margin-bottom: 36px;
    }

    @include mq('x-large') {
      max-width: 950px; //as per live
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__text {
    margin-top: 17px;
    margin-bottom: 48px;

    @include mq('medium') {
      max-width: 610px; //as per live
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('tablet') {
      margin-bottom: 65px;
    }
  }

  &__disclaimer {
    margin-top: 34px;

    @include mq('tablet') {
      margin-top: 10px;
    }

    p {
      opacity: 0.8;
    }
  }

  &__carousel {
    user-select: none;

    @include mq('x-large') {
      max-width: 980px; //as per live
      margin-left: auto;
      margin-right: auto;
    }

    .emu-carousel__item-container {
      @include mq('tablet') {
        display: flex;
      }
    }

    &-tablet-image {
      display: none;

      @include mq('tablet') {
        display: block;
        margin-top: 42px;
        width: 200px; //as per live

        img {
          height: 200px; //as per live
          border-radius: 100px;
        }
      }
    }

    &-wrapper {
      max-width: 290px; //as per live
      margin-left: auto;
      margin-right: auto;

      @include mq('tablet') {
        max-width: unset; //as per live
        padding-left: var(--emu-common-spacing-large);
      }

      @include mq('x-large') {
        padding-left: 62px;
      }
    }

    &-quote {
      position: relative;
      padding-top: 75px;
      margin-top: 17.5px;
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('tablet') {
        margin-top: var(--emu-common-spacing-none);
      }

      blockquote {
        font-family: var(--emu-semantic-font-families-kaneda-gothic);
        color: var(--emu-semantic-colors-primary-400);
        font-size: 125px;
        line-height: 150px;
        position: absolute;
        top: 0;
        margin: var(--emu-common-spacing-none);
        left: 50%;
        transform: translateX(-50%);

        @include mq('tablet') {
          left: 0;
          transform: unset;
        }
      }

      p {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.42px;
        text-align: center;

        @include mq('tablet') {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          text-align: left;
          line-height: 27px;
          letter-spacing: 0.48px;
        }
      }
    }

    &-figure {
      display: flex;
      margin-bottom: 40px;

      .image {
        display: flex;
        align-items: center;
      }

      &-image {
        width: 60px; //as per live

        img {
          border-radius: 30px;
        }

        @include mq('tablet') {
          display: none;
        }
      }

      &-text {
        padding-left: 20px;

        @include mq('tablet') {
          padding-left: var(--emu-common-spacing-none);
        }

        p {
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.42px;

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            letter-spacing: 0.42px;
            line-height: 27px;
          }

          &:first-child {
            color: var(--emu-semantic-colors-primary-400);
            font-weight: var(--emu-semantic-font-weight-500);
            font-family: var(--emu-semantic-font-families-avenir-next-medium);
            margin-top: -2px;
          }

          &:last-child {
            font-family: var(--emu-semantic-font-families-avenir-next-italic);
          }
        }
      }
    }
  }
}
