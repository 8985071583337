// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--default,
.container--default.aaaem-container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @include mq('small-plus') {
    max-width: 540px;
  }
  @include mq('medium') {
    max-width: 720px;
  }
  @include mq('tablet') {
    max-width: 960px;
  }
  @include mq('x-large') {
    max-width: 1196px;
  }
}

.container--large {
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;

  @include mq('small-plus') {
    max-width: 100%;
  }
  @include mq('medium') {
    max-width: 750px;
  }
  @include mq('tablet') {
    max-width: 970px;
  }
  @include mq('x-large') {
    max-width: 1170px;
  }
}

[data-component='title'],
[data-component='text'] {
  &.text--color-blue-400 {
    color: var(--emu-semantic-colors-primary-400);
  }

  &.text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.text--color-gray-50 {
    color: var(--emu-semantic-colors-secondary-50);
  }

  &.text--color-blue-900 {
    color: var(--emu-semantic-colors-primary-900);
  }
}

.bg--light-blue-50 {
  background-color: var(--emu-semantic-colors-primary-50);
}

.bg--blue-gradient {
  background: linear-gradient(180deg, #012655 35%, #015cb9 120%);
}

.bg--light-blue-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.u-body-line-height--large {
  p,
  li,
  a,
  span {
    line-height: 28px;
    letter-spacing: unset;
  }
}

.u-body-font--large {
  p,
  li,
  a,
  span {
    line-height: 1.43;
    letter-spacing: unset;

    @include mq('medium') {
      font-size: var(--emu-semantic-font-sizes-wide-medium);
    }
  }
}

.u-body-font--xs {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  li,
  a,
  span {
    font-family: var(--emu-semantic-font-families-body);
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    line-height: var(--emu-semantic-line-heights-narrow-xs);
    font-weight: var(--emu-common-font-weight-regular);
    letter-spacing: 0.24;
  }
}
