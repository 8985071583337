#common-questions {
  .banner {
    &__img--mobile {
      img {
        object-fit: unset; // to match live
      }
    }

    &__text-img--max-width {
      @include mq('xx-large') {
        margin-left: unset;
        margin-right: unset;
      }
    }
  }

  .common-questions {
    &__accordion {
      &-wrapper {
        padding-left: 45px;
        padding-right: 45px;
        margin-bottom: 50px;

        @include mq('medium') {
          padding-right: 15px;
          padding-left: 15px;
        }

        @include mq('x-large') {
          width: 956px; //as per live
        }

        h4 {
          margin-bottom: 30px;

          @include mq('tablet') {
            margin-bottom: 44px;
            text-align: left;
          }

          @include mq('x-large') {
            font-size: 28px;
            line-height: 33.6px;
          }
        }
      }

      &-text-disclaimer {
        margin-bottom: 21px;

        @include mq('x-large') {
          margin-bottom: 36px;
        }

        p {
          margin-bottom: 13px;
          font-family: 'serif';

          @include mq('x-large') {
            margin-bottom: 29px;
          }

          &:first-child {
            margin-top: 18px;

            @include mq('x-large') {
              margin-top: 38px;
            }
          }
        }
      }
    }

    &__technology {
      @include mq('tablet') {
        margin-bottom: 100px;
      }
    }
  }
}
