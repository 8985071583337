.accordion {
  .aaaem-accordion {
    &__header {
      border-bottom: none;
      border-top: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-300);

      @include mq('tablet') {
        padding-top: 21px;
        padding-bottom: 21px;
      }

      &[aria-expanded='true'] {
        border-top: 3px solid var(--emu-semantic-colors-primary-400);

        .aaaem-accordion__title {
          font-family: var(--emu-semantic-font-families-heading);
        }
      }
    }

    &__title {
      font-weight: var(--emu-semantic-font-weight-500);
      line-height: 19.2px;
      letter-spacing: 0.32px;

      @include mq('tablet') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        padding-left: 20px;
        line-height: 21.6px;
        letter-spacing: 0.36px;
      }
    }

    &__icon {
      padding-right: 22px;

      &::before,
      &::after {
        transition: none;
      }

      @include mq('tablet') {
        height: 17px; //to match live site
        margin-left: -3px;
        padding-right: 23px;
      }
    }

    &__button {
      @include mq('tablet') {
        flex-direction: row-reverse;
      }
    }

    &__panel {
      &.-ba-is-active + .aaaem-accordion__item p {
        border-top: 3px solid var(--emu-semantic-colors-primary-400);
      }

      .js-badger-accordion-panel-inner {
        padding-top: 10px;

        @include mq('tablet') {
          padding-top: 4px;
          padding-right: 25px;
          padding-bottom: 43px;
          padding-left: 40px;
        }

        a {
          text-decoration: none;
          color: var(--emu-semantic-colors-primary-600);

          &:hover {
            color: #23527c; // used once
            text-decoration: underline;
          }
        }

        sup {
          font-size: 10px;
        }
      }
    }

    &:has(dd:last-of-type.-ba-is-active) {
      border-bottom: 3px solid var(--emu-semantic-colors-primary-400);
    }

    dd.-ba-is-active {
      max-height: none !important; //to match live site
    }

    dt:last-of-type {
      p {
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-300);

        &[aria-expanded='true'] {
          border-bottom: none;
        }
      }
    }
  }
}
