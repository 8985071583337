.button {
  .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline {
    position: relative;
    border-bottom: var(--emu-common-border-width-medium) solid
      var(--emu-common-colors-transparent);

    span {
      letter-spacing: 0.34px;

      @include mq('x-large') {
        font-size: 22px;
        line-height: 30.8px;
        letter-spacing: 0.44px;
      }
    }

    svg {
      width: 17px; // as per live
      height: 19.5px; // to match live
      position: absolute;
      top: var(--emu-common-spacing-xxs);
      right: -28px;
      transition: 0.15s transform ease;

      @include mq('x-large') {
        width: 22px; // as per live
        height: 25px; // to match live
      }
    }

    &:hover,
    &:active,
    &:focus {
      border-bottom-color: var(--emu-semantic-colors-primary-400);

      svg {
        transform: translateX(10px);
      }
    }
  }

  .emu-button.emu-button-v2.emu-button__primary-filled {
    font-family: var(--emu-semantic-font-families-body);
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: 19px;
    letter-spacing: 2.5px;
    font-weight: var(--emu-semantic-font-weight-600);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 45px;
    padding-right: 45px;
    justify-content: center;
    transition: 0.3s all ease;

    @include mq('small-plus') {
      width: auto;
      min-width: 215px; // as per live
    }

    @include mq('medium') {
      line-height: 22px;
    }
  }

  .emu-button.emu-button-v2.emu-button__secondary-filled {
    font-family: var(--emu-semantic-font-families-body);
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: 1.43;
    letter-spacing: 2.5px;
    font-weight: var(--emu-semantic-font-weight-500);
    width: 100%;
    padding-top: 9px;
    padding-bottom: var(--emu-common-spacing-small);
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    max-width: 320px; // as per live

    @include mq('tablet') {
      max-width: 215px; // as per live
    }

    &:active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); // as per live
    }
  }

  .emu-button.emu-button-v2.emu-button__secondary-outline {
    font-family: var(--emu-semantic-font-families-body);
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 2px;
    font-weight: var(--emu-semantic-font-weight-600);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 60px;
    padding-right: 60px;
    transition: 0.3s all ease;

    @include mq('medium') {
      font-size: var(--emu-common-font-sizes-wide-medium);
      line-height: 22px;
      padding-left: 77px;
      padding-right: 77px;
    }
  }
}
