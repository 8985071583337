@font-face {
  font-family: GlyphiconsHalflings;
  font-display: swap;
  src: url("resources/fonts/glyphicons-halflings-regular.woff2") format("woff2");
}

@font-face {
  font-family: CoolSculptingIcons;
  font-display: swap;
  src: url("resources/fonts/coolsculpting-icons.ttf") format("truetype");
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 120px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-semantic-colors-primary-300);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-0: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-50: #eaf5fb;
  --emu-semantic-colors-primary-100: #f4f7fb;
  --emu-semantic-colors-primary-200: #00c2ff;
  --emu-semantic-colors-primary-300: #002554;
  --emu-semantic-colors-primary-400: #005eb8;
  --emu-semantic-colors-primary-500: #0079c1;
  --emu-semantic-colors-primary-600: #337ab7;
  --emu-semantic-colors-primary-700: #0c2e77;
  --emu-semantic-colors-primary-800: #011a66;
  --emu-semantic-colors-primary-900: #46a7dc;
  --emu-semantic-colors-primary-950: #005cb9;
  --emu-semantic-colors-primary-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-primary-dark: var(--emu-semantic-colors-primary-200);
  --emu-semantic-colors-secondary-50: #75787b;
  --emu-semantic-colors-secondary-100: #f2f2f2;
  --emu-semantic-colors-secondary-200: #edf0f6;
  --emu-semantic-colors-secondary-300: #177ad8;
  --emu-semantic-colors-secondary-400: var(--emu-common-colors-blush-400);
  --emu-semantic-colors-secondary-500: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-blush-700);
  --emu-semantic-colors-secondary-800: var(--emu-common-colors-blush-800);
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-secondary-dark: var(--emu-semantic-colors-secondary-200);
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-font-weight-500: 500;
  --emu-semantic-font-weight-600: 600;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "AvenirNextRegular", sans-serif;
  --emu-semantic-font-families-heading: "AvenirNextDemi", sans-serif;
  --emu-semantic-font-families-mono: "AvenirNextBold", sans-serif;
  --emu-semantic-font-families-avenir-next-medium: "AvenirNextMedium", sans-serif;
  --emu-semantic-font-families-avenir-next-italic: "AvenirNextItalic", sans-serif;
  --emu-semantic-font-families-kaneda-gothic: "KanedaGothicRegular", sans-serif;
  --emu-semantic-font-families-cool-sculpting-icons: CoolSculptingIcons;
  --emu-semantic-font-families-glyphicons-halflings: GlyphiconsHalflings;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .25);
  --emu-semantic-font-sizes-narrow-medium: 16px;
  --emu-semantic-font-sizes-narrow-large: 18px;
  --emu-semantic-font-sizes-narrow-xl: 30px;
  --emu-semantic-font-sizes-narrow-xxl: 50px;
  --emu-semantic-font-sizes-narrow-xxxl: 48px;
  --emu-semantic-font-sizes-narrow-small: 16px;
  --emu-semantic-font-sizes-narrow-xs: 12px;
  --emu-semantic-font-sizes-wide-medium: 18px;
  --emu-semantic-font-sizes-wide-large: 34px;
  --emu-semantic-font-sizes-wide-xl: 36px;
  --emu-semantic-font-sizes-wide-xxl: 70px;
  --emu-semantic-font-sizes-wide-xxxl: 100px;
  --emu-semantic-font-sizes-wide-small: 16px;
  --emu-semantic-font-sizes-wide-xs: 12px;
  --emu-semantic-line-heights-narrow-large: calc(var(--emu-semantic-font-sizes-narrow-large) * 1.2);
  --emu-semantic-line-heights-narrow-medium: 34px;
  --emu-semantic-line-heights-narrow-xl: 36px;
  --emu-semantic-line-heights-narrow-xxl: calc(var(--emu-semantic-font-sizes-narrow-xxl) * .9);
  --emu-semantic-line-heights-narrow-xxxl: calc(var(--emu-semantic-font-sizes-narrow-xxxl) * .9);
  --emu-semantic-line-heights-narrow-small: 24px;
  --emu-semantic-line-heights-narrow-xs: calc(var(--emu-semantic-font-sizes-narrow-xs) * 1.4);
  --emu-semantic-line-heights-wide-large: calc(var(--emu-semantic-font-sizes-wide-large) * 1.2);
  --emu-semantic-line-heights-wide-medium: 34px;
  --emu-semantic-line-heights-wide-xl: 36px;
  --emu-semantic-line-heights-wide-xxl: calc(var(--emu-semantic-font-sizes-wide-xxl) * .9);
  --emu-semantic-line-heights-wide-xxxl: calc(var(--emu-semantic-font-sizes-wide-xxxl) * .9);
  --emu-semantic-line-heights-wide-small: 24px;
  --emu-semantic-line-heights-wide-xs: calc(var(--emu-semantic-font-sizes-wide-xs) * 1.4);
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-small-plus: 576px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 992px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-kaneda-gothic);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-kaneda-gothic);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-mono);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-mono);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-kaneda-gothic);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-kaneda-gothic);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-line-heights-wide-xl) / var(--emu-semantic-font-sizes-wide-xl) var(--emu-semantic-font-families-mono);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-mono);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-small);
  --emu-component-cards-teaser-padding-top-wide: 24px;
  --emu-component-cards-teaser-padding-right-narrow: 40px;
  --emu-component-cards-teaser-padding-right-wide: 43px;
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-small);
  --emu-component-cards-teaser-padding-bottom-wide: 24px;
  --emu-component-cards-teaser-padding-left-narrow: 40px;
  --emu-component-cards-teaser-padding-left-wide: 43px;
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-content-fragment-card-margin-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-margin-wide: var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-typography-name-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-name-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-title-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-title-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-bio-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-cards-content-fragment-card-text-typography-bio-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-cards-content-fragment-card-text-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-cards-content-fragment-card-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-cards-content-fragment-card-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-cards-content-fragment-card-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-cards-content-fragment-card-color-background-dark: var(--emu-semantic-colors-primary-950);
  --emu-component-cards-content-fragment-card-color-background-light: var(--emu-semantic-colors-primary-0);
  --emu-component-cards-content-fragment-card-border-radius-narrow: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-border-radius-wide: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-border-radius-narrow: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-cards-content-fragment-card-author-image-border-radius-wide: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-component-lists-accordion-item-header-text-color-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: 17px var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-primary-300);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) 15px var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-body-padding-wide: var(--emu-common-spacing-none) var(--emu-common-spacing-none) 15px var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: 15px var(--emu-semantic-spacing-none) 15px var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-header-padding-wide: 15px var(--emu-semantic-spacing-none) 15px var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: 2.5px;
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-primary-400);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-primary-400);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: 20px;
  --emu-component-lists-accordion-item-header-icon-size-height-wide: 20px;
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-primary-300);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-primary-300);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-primary-400);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 17px / 23.8px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 17px / 23.8px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-secondary-100);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-secondary-100);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-secondary-100);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-semantic-colors-secondary-100);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-secondary-100);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-secondary-100);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-secondary-100);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-secondary-100);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-none);
  --emu-component-actions-button-outline-width-primary-filled: 0;
  --emu-component-actions-button-outline-width-primary-outline: 0;
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-primary-300);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-primary-300);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-primary-400);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-narrow: 0px;
  --emu-component-actions-button-padding-wide: 0px;
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-common-border-radius-none) var(--emu-common-border-radius-none) var(--emu-common-border-radius-none) var(--emu-common-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: var(--emu-semantic-font-sizes-narrow-small);
  --emu-component-actions-pagination-icon-wide: var(--emu-semantic-font-sizes-wide-small);
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-actions-pagination-gap-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-pagination-gap-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: 10.5px;
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: 30px;
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: 7.5px;
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: 30px;
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-medium);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: 21px;
  --emu-component-containers-isi-header-padding-top-wide: 21px;
  --emu-component-containers-isi-header-padding-right-narrow: 45px;
  --emu-component-containers-isi-header-padding-right-wide: 45px;
  --emu-component-containers-isi-header-padding-bottom-narrow: 20px;
  --emu-component-containers-isi-header-padding-bottom-wide: 21px;
  --emu-component-containers-isi-header-padding-left-narrow: 45px;
  --emu-component-containers-isi-header-padding-left-wide: 45px;
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-primary-400);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-common-border-width-none);
  --emu-component-containers-isi-body-padding-top-narrow: 24px;
  --emu-component-containers-isi-body-padding-top-wide: 24px;
  --emu-component-containers-isi-body-padding-right-narrow: 45px;
  --emu-component-containers-isi-body-padding-right-wide: 45px;
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-narrow: 45px;
  --emu-component-containers-isi-body-padding-left-wide: 45px;
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 100%;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-narrow: var(--emu-component-containers-container-padding-top-narrow) var(--emu-component-containers-container-padding-right-narrow) var(--emu-component-containers-container-padding-bottom-narrow) var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-container-padding-wide: var(--emu-component-containers-container-padding-top-wide) var(--emu-component-containers-container-padding-right-wide) var(--emu-component-containers-container-padding-bottom-wide) var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-color-border-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-light);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-light);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-component-containers-carousel-indicators-thumbnail-border-width-narrow);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-background-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-common-border-width-none);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-common-border-width-none);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width) px;
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

@media (min-width: 992px) {
  h1 {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

@media (min-width: 1200px) {
  h2 {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);
}

@media (min-width: 768px) {
  h3 {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

@media (min-width: 1200px) {
  h4 {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

@media (min-width: 768px) {
  h5 {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: var(--emu-common-spacing-none);
}

html, body, h1, h2, h3, h4, h5, h6, p, li, a, span, button {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  color: inherit;
}

body {
  color: var(--emu-semantic-colors-primary-300);
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -5px;
}

p {
  letter-spacing: .48px;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--default, .container--default.aaaem-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container--default, .container--default.aaaem-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container--default, .container--default.aaaem-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container--default, .container--default.aaaem-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container--default, .container--default.aaaem-container {
    max-width: 1196px;
  }
}

.container--large {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 576px) {
  .container--large {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container--large {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container--large {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container--large {
    max-width: 1170px;
  }
}

[data-component="title"].text--color-blue-400, [data-component="text"].text--color-blue-400 {
  color: var(--emu-semantic-colors-primary-400);
}

[data-component="title"].text--color-white, [data-component="text"].text--color-white {
  color: var(--emu-common-colors-white);
}

[data-component="title"].text--color-black, [data-component="text"].text--color-black {
  color: var(--emu-common-colors-black);
}

[data-component="title"].text--color-gray-50, [data-component="text"].text--color-gray-50 {
  color: var(--emu-semantic-colors-secondary-50);
}

[data-component="title"].text--color-blue-900, [data-component="text"].text--color-blue-900 {
  color: var(--emu-semantic-colors-primary-900);
}

.bg--light-blue-50 {
  background-color: var(--emu-semantic-colors-primary-50);
}

.bg--blue-gradient {
  background: linear-gradient(#012655 35%, #015cb9 120%);
}

.bg--light-blue-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.u-body-line-height--large p, .u-body-line-height--large li, .u-body-line-height--large a, .u-body-line-height--large span {
  letter-spacing: unset;
  line-height: 28px;
}

.u-body-font--large p, .u-body-font--large li, .u-body-font--large a, .u-body-font--large span {
  letter-spacing: unset;
  line-height: 1.43;
}

@media (min-width: 768px) {
  .u-body-font--large p, .u-body-font--large li, .u-body-font--large a, .u-body-font--large span {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }
}

.u-body-font--xs h1, .u-body-font--xs h2, .u-body-font--xs h3, .u-body-font--xs h4, .u-body-font--xs h5, .u-body-font--xs p, .u-body-font--xs li, .u-body-font--xs a, .u-body-font--xs span {
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: .24px;
}

#home .home__banner {
  background: linear-gradient(90deg, var(--emu-semantic-colors-primary-300) 0%, var(--emu-semantic-colors-primary-400) 100%);
  justify-content: center;
  align-items: flex-end;
  height: 308px;
  margin-top: -27px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #home .home__banner {
    height: auto;
  }
}

@media (min-width: 1024px) {
  #home .home__banner {
    margin-top: -10px;
  }
}

@media (min-width: 1200px) {
  #home .home__banner-image {
    padding-top: 23px;
  }
}

#coolsculpting-backed-by-science .banner__img--mobile img {
  object-fit: fill;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .banner__disclaimer {
    width: auto;
    left: 0;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas {
  padding-top: 50px;
  padding-left: var(--emu-common-spacing-none);
  padding-bottom: 50px;
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas {
    padding-top: 80px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas {
    padding-bottom: 75px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-title, #coolsculpting-backed-by-science .backed-by-science__treatment-areas-text {
  padding-left: 63px;
  padding-right: 63px;
}

@media (min-width: 768px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-title, #coolsculpting-backed-by-science .backed-by-science__treatment-areas-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-title {
  margin-bottom: 17px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-title {
    margin-bottom: 50px;
  }

  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-text {
    width: 793px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-text {
    width: 956px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main {
  padding-top: 1.5px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1024px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main .tabs {
  margin-top: var(--emu-common-spacing-none);
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul {
  margin-bottom: 42px;
}

@media (min-width: 768px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul {
    margin-bottom: 80px;
  }
}

@media (min-width: 1024px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul {
    margin-bottom: 50px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul li:first-child {
  margin-right: 17.5px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul li:first-child {
    margin-right: 25.5px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul a[role="tab"] {
  border-color: var(--emu-semantic-colors-primary-400);
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-400);
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul a[role="tab"] {
    padding-left: 45px;
    padding-right: 45px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul a[role="tab"][aria-selected="true"] {
  background-color: var(--emu-semantic-colors-primary-400);
  color: var(--emu-common-colors-white);
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--main > ul a[role="tab"] span {
  display: inline-block;
  transform: translateY(-1px);
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--nested {
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 1024px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--nested {
    display: none;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--nested img {
  width: 100%;
  min-height: 344px;
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--nested ul {
  margin-bottom: var(--emu-common-spacing-none);
  padding-top: 30px;
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--nested ul li:first-child {
  margin-right: 2.5px;
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--nested ul a[role="tab"] {
  border-width: var(--emu-common-border-width-none);
  background-color: var(--emu-common-colors-transparent);
  border-radius: var(--emu-common-border-radius-none);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  letter-spacing: normal;
  color: #393a3b;
  opacity: .4;
  padding: 6px 12px;
  line-height: 21.6px;
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--nested ul a[role="tab"][aria-selected="true"] {
  color: var(--emu-semantic-colors-primary-400);
  opacity: 1;
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-tab--nested ul span {
  display: inline-block;
  transform: translateY(-1px);
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-desktop-images {
  display: none;
}

@media (min-width: 1024px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-desktop-images {
    justify-content: space-evenly;
    display: flex;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-desktop-images .image {
  flex-grow: 0;
  flex-basis: 480px;
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__treatment-areas-desktop-images .image {
    flex-basis: 598px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-desktop-images .image .cmp-image {
  width: 100%;
}

#coolsculpting-backed-by-science .backed-by-science__treatment-areas-desktop-images .image .cmp-image img {
  object-fit: contain;
  width: auto;
  max-width: 100%;
  max-height: 460px;
  margin-left: auto;
  margin-right: auto;
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works {
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 15px 122px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works {
    max-width: 1196px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel {
  -webkit-user-select: none;
  user-select: none;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel .emu-carousel__content {
    justify-content: space-evenly;
    display: flex;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-item {
  margin-bottom: 23px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-item {
    margin-bottom: var(--emu-semantic-spacing-none);
    width: 310px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-item {
    width: 389px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image {
  margin-bottom: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image {
    margin-bottom: 45px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image img {
  z-index: var(--emu-common-other-z-index-layer);
  width: 115px;
  height: 115px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image img {
    width: 132px;
    height: 132px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image:before {
  content: " ";
  border: 3px solid var(--emu-semantic-colors-primary-400);
  background-color: var(--emu-common-colors-white);
  border-radius: 50%;
  width: 130px;
  height: 130px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image:before {
    width: 153px;
    height: 153px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image:after {
  content: "";
  background-color: var(--emu-semantic-colors-primary-400);
  z-index: var(--emu-common-other-z-index-behind);
  width: 100%;
  height: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image--after-target:after {
  left: 50%;
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-image--after-reduce:after {
  right: 50%;
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-bottom-wrapper {
  padding-left: 45px;
  padding-right: 45px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-bottom-wrapper {
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-title {
  color: var(--emu-semantic-colors-primary-400);
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-title {
    margin-bottom: 26px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-title h4 {
  font-size: 19px;
  font-family: var(--emu-semantic-font-families-heading);
  letter-spacing: .38px;
  line-height: 1.2;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-title h4 {
    letter-spacing: .44px;
    font-size: 22px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-text {
  font-family: var(--emu-semantic-font-families-body);
  margin-bottom: 52px;
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-text {
    min-height: 135px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__how-coolsculpting-works-carousel-bottom-image {
  width: 100%;
  max-width: 324px;
  margin-left: auto;
  margin-right: auto;
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-title {
  padding-left: 45px;
  padding-right: 45px;
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-title h4 {
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-title h4 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    margin-bottom: 45px;
    line-height: 40.8px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-text p {
  margin-bottom: 25px;
  padding-left: 40px;
  padding-right: 40px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-text p {
    margin-bottom: 45px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-text p {
    margin-left: 108px;
    margin-right: 108px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel {
    padding-right: var(--emu-semantic-spacing-none);
    padding-left: var(--emu-semantic-spacing-none);
  }

  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel .tns-inner {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel .tns-inner {
    padding-left: var(--emu-semantic-spacing-none);
    padding-right: var(--emu-semantic-spacing-none);
    max-width: 1196px;
  }
}

@media (min-width: 1440px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel .tns-inner {
    max-width: 1304px;
  }
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel .tns-inner .emu-carousel__content {
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-container {
  -webkit-user-select: none;
  user-select: none;
  max-width: 280px;
  min-height: 350px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  padding: 45px 15px 30px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-container {
    max-width: 303px;
    min-height: 320px;
    margin-bottom: 50px;
    margin-left: 3.5px;
    margin-right: 3.5px;
    padding-top: 27px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-container {
    max-width: 292px;
  }
}

@media (min-width: 1440px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-container {
    max-width: 326px;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 43px;
    padding-right: 43px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-container .image {
  margin-bottom: 40px;
  margin-left: 50px;
  margin-right: 50px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-container .image {
    margin-left: 52px;
    margin-right: 52px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-container .image {
    margin-left: 46px;
    margin-right: 46px;
  }
}

@media (min-width: 1440px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-container .image {
    margin-left: 30.5px;
    margin-right: 30.5px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-image {
  background-color: var(--emu-common-colors-white);
  border-radius: 50%;
  justify-content: center;
  width: 150px;
  height: 150px;
  display: flex;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-image {
    width: 169px;
    height: 169px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-image--width img {
  width: 126px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-image--width img {
    width: 142px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-image img {
  margin-top: auto;
  margin-bottom: auto;
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-title {
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-title {
    margin-top: 20px;
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-title {
    padding-left: var(--emu-semantic-spacing-none);
    padding-right: var(--emu-semantic-spacing-none);
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-title h3 {
  letter-spacing: .32px;
  line-height: var(--emu-semantic-sizing-one-line-height-narrow);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-title h3 {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    font-family: var(--emu-semantic-font-families-avenir-next-medium);
    font-weight: var(--emu-semantic-font-weight-600);
    line-height: 21.6px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-title h3 > sup {
  padding-left: 2.5px;
  font-size: 60%;
  top: -8px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-title h3 > sup {
    padding-left: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-carousel-title h3 > sup {
    padding-left: 2.5px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-disclaimer {
  margin-top: 60px;
  padding-left: 45px;
  padding-right: 45px;
}

@media (min-width: 992px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-disclaimer {
    margin-top: var(--emu-semantic-spacing-none);
  }
}

@media (min-width: 1200px) {
  #coolsculpting-backed-by-science .backed-by-science__scientific-evidence-disclaimer {
    margin-top: 10px;
  }
}

#coolsculpting-backed-by-science .backed-by-science__scientific-evidence-disclaimer p {
  opacity: .8;
}

#common-questions .banner__img--mobile img {
  object-fit: unset;
}

@media (min-width: 1440px) {
  #common-questions .banner__text-img--max-width {
    margin-left: unset;
    margin-right: unset;
  }
}

#common-questions .common-questions__accordion-wrapper {
  margin-bottom: 50px;
  padding-left: 45px;
  padding-right: 45px;
}

@media (min-width: 768px) {
  #common-questions .common-questions__accordion-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  #common-questions .common-questions__accordion-wrapper {
    width: 956px;
  }
}

#common-questions .common-questions__accordion-wrapper h4 {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  #common-questions .common-questions__accordion-wrapper h4 {
    text-align: left;
    margin-bottom: 44px;
  }
}

@media (min-width: 1200px) {
  #common-questions .common-questions__accordion-wrapper h4 {
    font-size: 28px;
    line-height: 33.6px;
  }
}

#common-questions .common-questions__accordion-text-disclaimer {
  margin-bottom: 21px;
}

@media (min-width: 1200px) {
  #common-questions .common-questions__accordion-text-disclaimer {
    margin-bottom: 36px;
  }
}

#common-questions .common-questions__accordion-text-disclaimer p {
  margin-bottom: 13px;
  font-family: "serif";
}

@media (min-width: 1200px) {
  #common-questions .common-questions__accordion-text-disclaimer p {
    margin-bottom: 29px;
  }
}

#common-questions .common-questions__accordion-text-disclaimer p:first-child {
  margin-top: 18px;
}

@media (min-width: 1200px) {
  #common-questions .common-questions__accordion-text-disclaimer p:first-child {
    margin-top: 38px;
  }
}

@media (min-width: 992px) {
  #common-questions .common-questions__technology {
    margin-bottom: 100px;
  }
}

#contact-us .contact-us__banner {
  background: linear-gradient(90deg, var(--emu-semantic-colors-primary-300) 0%, var(--emu-semantic-colors-primary-400) 100%);
  height: 308px;
  margin-top: -27px;
  position: relative;
}

@media (min-width: 768px) {
  #contact-us .contact-us__banner {
    height: 450px;
  }
}

@media (min-width: 992px) {
  #contact-us .contact-us__banner {
    height: 600px;
  }
}

@media (min-width: 1024px) {
  #contact-us .contact-us__banner {
    margin-top: -10px;
  }
}

@media (min-width: 1200px) {
  #contact-us .contact-us__banner {
    height: 610px;
  }
}

#contact-us .contact-us__banner > .container {
  height: 100%;
}

#contact-us .contact-us__banner-img {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

#contact-us .contact-us__banner-img img {
  object-fit: cover;
  width: 292px;
  height: 80%;
  position: relative;
  top: 27px;
  left: -42px;
}

@media (min-width: 576px) {
  #contact-us .contact-us__banner-img img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1200px) {
  #contact-us .contact-us__banner-img img {
    max-width: 1196px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1440px) {
  #contact-us .contact-us__banner-img img {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  }
}

@media (min-width: 576px) {
  #contact-us .contact-us__banner-img--mobile {
    display: none;
  }
}

#contact-us .contact-us__banner-img--desktop {
  display: none;
}

@media (min-width: 576px) {
  #contact-us .contact-us__banner-img--desktop {
    display: inline-block;
  }
}

#contact-us .contact-us__banner-title-container {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  height: 100%;
  position: relative;
}

@media (min-width: 576px) {
  #contact-us .contact-us__banner-title-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1440px) {
  #contact-us .contact-us__banner-title-container {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  }
}

@media (min-width: 576px) {
  #contact-us .contact-us__banner-title-container .title {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

@media (min-width: 768px) {
  #contact-us .contact-us__banner-title-container .title {
    position: unset;
  }
}

#contact-us .contact-us__banner-title h1 {
  color: var(--emu-common-colors-white);
  box-sizing: content-box;
  max-width: 112px;
  margin-left: 66.6667%;
  padding-left: 25px;
  position: absolute;
  bottom: 0;
}

@media (min-width: 576px) {
  #contact-us .contact-us__banner-title h1 {
    margin-left: 58.3333%;
    padding-left: 45px;
  }
}

@media (min-width: 768px) {
  #contact-us .contact-us__banner-title h1 {
    max-width: none;
    padding-left: 30px;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

@media (min-width: 1440px) {
  #contact-us .contact-us__banner-title h1 {
    font-size: 130px;
    line-height: 117px;
  }
}

#contact-us .contact-us__local-centers-container {
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 576px) {
  #contact-us .contact-us__local-centers-container {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (min-width: 768px) {
  #contact-us .contact-us__local-centers-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#contact-us .contact-us__local-centers {
  padding: 35px 15px 30px;
}

@media (min-width: 992px) {
  #contact-us .contact-us__local-centers .title {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#contact-us .contact-us__local-centers .title h4 {
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  #contact-us .contact-us__local-centers .title h4 {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (min-width: 992px) {
  #contact-us .contact-us__local-centers .title h4 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#contact-us .contact-us__local-centers-info {
  flex-direction: column;
  display: flex;
}

@media (min-width: 992px) {
  #contact-us .contact-us__local-centers-info {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

@media (min-width: 768px) {
  #contact-us .contact-us__local-centers-info .text:not(.aaaem-text) {
    max-width: 331px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  #contact-us .contact-us__local-centers-info .text:not(.aaaem-text) {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    flex-grow: 0;
    flex-basis: 280px;
    max-width: none;
  }
}

@media (min-width: 1200px) {
  #contact-us .contact-us__local-centers-info .text:not(.aaaem-text) {
    flex-basis: 360px;
  }

  #contact-us .contact-us__local-center-info {
    padding-left: 14.5px;
    padding-right: 14.5px;
  }
}

#contact-us .contact-us__local-center-info p {
  color: var(--emu-semantic-colors-primary-300);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  letter-spacing: normal;
  margin-bottom: 30px;
  line-height: 25px;
}

@media (min-width: 768px) {
  #contact-us .contact-us__local-center-info p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: 35px;
  }
}

#contact-us .contact-us__local-center-info p b {
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-common-font-weight-regular);
}

#contact-us .contact-us__local-center-info p a {
  text-decoration: none;
}

#contact-us .contact-us__local-center-info p a:hover {
  text-decoration: underline;
}

#contact-us .contact-us__intro {
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 576px) {
  #contact-us .contact-us__intro {
    padding-left: 60px;
    padding-right: 60px;
  }
}

#contact-us .contact-us__intro-wrapper {
  padding-top: 50px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  #contact-us .contact-us__intro-wrapper {
    padding-bottom: 90px;
  }
}

@media (min-width: 1200px) {
  #contact-us .contact-us__intro-wrapper {
    padding-top: 100px;
  }
}

@media (min-width: 768px) {
  #contact-us .contact-us__intro-title {
    margin-bottom: 60px;
  }
}

#contact-us .contact-us__intro-content h4 {
  margin-bottom: 10px;
}

#contact-us .contact-us__intro-content p {
  margin-bottom: 17.6px;
  line-height: 25px;
}

@media (min-width: 768px) {
  #contact-us .contact-us__intro-content p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    letter-spacing: .54px;
    margin-bottom: 19.8px;
  }
}

@media (min-width: 1200px) {
  #contact-us .contact-us__intro-content p {
    margin-bottom: 43.2px;
  }
}

#contact-us .contact-us__intro-content p a {
  text-decoration: none;
}

#contact-us .contact-us__intro-content p a:hover {
  text-decoration: underline;
}

#contact-us .contact-us__intro-content p:last-of-type {
  margin-bottom: var(--emu-common-spacing-none);
}

#coolsculpting-elite .coolsculpting-elite__feature-list-wrapper {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper {
    max-width: 960px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
  }

  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper > .container {
    flex: 0 0 50%;
    align-self: center;
  }

  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper > .image:last-child {
    flex: 0 0 50%;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper {
    max-width: 1196px;
  }
}

#coolsculpting-elite .coolsculpting-elite__feature-list-wrapper .image img {
  width: 100%;
}

@media (min-width: 768px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper .image img {
    object-fit: cover;
    height: 1083px;
  }
}

@media (min-width: 992px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper .image img {
    height: 699px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper .image img {
    height: 877px;
  }
}

@media (min-width: 992px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-wrapper--reverse {
    flex-direction: row-reverse;
  }
}

#coolsculpting-elite .coolsculpting-elite__feature-list-wrapper--reverse h5 {
  color: var(--emu-semantic-colors-primary-400);
}

#coolsculpting-elite .coolsculpting-elite__feature-list-wrapper--reverse p {
  color: var(--emu-semantic-colors-primary-300);
}

#coolsculpting-elite .coolsculpting-elite__feature-list-content-container {
  padding: 50px 45px 36px;
}

@media (min-width: 992px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-content-container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-content-container {
    padding-top: 129px;
    padding-bottom: 129px;
    padding-right: 35px;
  }
}

#coolsculpting-elite .coolsculpting-elite__feature-list-content h5 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-500);
  letter-spacing: .32px;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-content h5 {
    font-size: var(--emu-semantic-font-sizes-wide-small);
  }
}

#coolsculpting-elite .coolsculpting-elite__feature-list-content h5:first-of-type {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-content h5 {
    letter-spacing: .44px;
    margin-top: 42px;
    font-size: 22px;
  }
}

#coolsculpting-elite .coolsculpting-elite__feature-list-content p {
  margin-bottom: 10px;
}

#coolsculpting-elite .coolsculpting-elite__feature-list-content p sup {
  font-size: 10px;
}

#coolsculpting-elite .coolsculpting-elite__feature-list-content p:last-of-type {
  margin-bottom: var(--emu-common-spacing-none);
}

#coolsculpting-elite .coolsculpting-elite__feature-list-disclaimer p {
  opacity: .8;
  margin-top: 15px;
}

#coolsculpting-elite .coolsculpting-elite__feature-list-disclaimer p:not(:last-child) {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1200px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-disclaimer p:first-of-type {
    margin-top: 42px;
  }
}

#coolsculpting-elite .coolsculpting-elite__feature-list-title h2 {
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  #coolsculpting-elite .coolsculpting-elite__feature-list-title h2 {
    margin-bottom: 50px;
  }
}

#coolsculpting-elite .coolsculpting-elite__tab-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  #coolsculpting-elite .coolsculpting-elite__tab-section {
    padding-top: 80px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#coolsculpting-elite .coolsculpting-elite__tab-section-wrapper {
  padding-left: 63px;
  padding-right: 63px;
}

@media (min-width: 768px) {
  #coolsculpting-elite .coolsculpting-elite__tab-section-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  #coolsculpting-elite .coolsculpting-elite__tab-section-wrapper .text p {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    width: 82%;
    margin-left: auto;
    margin-right: auto;
  }
}

#coolsculpting-elite .coolsculpting-elite__tab-section-title h2 {
  margin-bottom: 17px;
}

@media (min-width: 992px) {
  #coolsculpting-elite .coolsculpting-elite__tab-section-title h2 {
    margin-bottom: 50px;
  }
}

#coolsculpting-elite .coolsculpting-elite__tab-section-tab-container {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

#coolsculpting-elite .coolsculpting-elite__tab-section-tab-container ul li a:hover {
  background-color: var(--emu-common-colors-white);
  color: var(--emu-semantic-colors-primary-300);
}

@media (min-width: 992px) {
  #coolsculpting-elite .coolsculpting-elite__tab-section-tab-container ul li a {
    padding-left: 25px;
    padding-right: 25px;
  }
}

#coolsculpting-elite .coolsculpting-elite__tab-section-image {
  justify-content: center;
  display: flex;
}

@media (min-width: 320px) {
  #coolsculpting-elite .coolsculpting-elite__tab-section-image img {
    width: 375px;
    height: 204px;
  }
}

@media (min-width: 576px) {
  #coolsculpting-elite .coolsculpting-elite__tab-section-image img {
    width: auto;
    height: auto;
  }
}

@media (min-width: 992px) {
  #cooltone-about .image-text-grid--cooltone .image-text-grid__content > .container:has(.image-text-grid__content-container) {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#cooltone-about .image-text-grid--cooltone .image-text-grid__content-container {
  padding-top: 40px;
  padding-bottom: 36px;
}

@media (min-width: 992px) {
  #cooltone-about .image-text-grid--cooltone .image-text-grid__content-container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #cooltone-about .image-text-grid--cooltone .image-text-grid__content-container {
    max-width: 435px;
  }
}

@media (min-width: 992px) {
  #cooltone-about .image-text-grid--cooltone .image-text-grid__teaser {
    height: 720px;
  }

  #cooltone-about .image-text-grid--cooltone .image-text-grid__teaser .cmp-teaser__image--desktop, #cooltone-about .image-text-grid--cooltone .image-text-grid__teaser .cmp-teaser__image--desktop .cmp-image {
    height: 100%;
  }

  #cooltone-about .image-text-grid--cooltone .image-text-grid__teaser .cmp-teaser__image--desktop .cmp-image a img {
    object-fit: cover;
    height: 100%;
  }
}

#cooltone-about .cooltone-about__banner {
  margin-top: -27px;
  position: relative;
}

@media (min-width: 1024px) {
  #cooltone-about .cooltone-about__banner {
    margin-top: -10px;
  }
}

@media (min-width: 1400px) {
  #cooltone-about .cooltone-about__banner {
    background-color: #1650e0;
  }
}

#cooltone-about .cooltone-about__banner-img {
  position: absolute;
  inset: 0;
}

#cooltone-about .cooltone-about__banner-img img {
  object-fit: cover;
  object-position: center top;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__banner-img img {
    object-position: right;
  }
}

@media (min-width: 1400px) {
  #cooltone-about .cooltone-about__banner-img img {
    object-position: center -90px;
  }
}

@media (min-width: 576px) {
  #cooltone-about .cooltone-about__banner-img--mobile {
    display: none;
  }
}

#cooltone-about .cooltone-about__banner-img--desktop {
  display: none;
}

@media (min-width: 576px) {
  #cooltone-about .cooltone-about__banner-img--desktop {
    display: block;
  }
}

#cooltone-about .cooltone-about__banner-content {
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: var(--emu-common-spacing-none);
  padding-left: 30px;
  position: relative;
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__banner-content {
    padding-top: 120px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

#cooltone-about .cooltone-about__banner-more-strength-img {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__banner-more-strength-img {
    margin-bottom: 40px;
  }
}

#cooltone-about .cooltone-about__banner-more-strength-img img {
  max-width: 220px;
  padding-top: 44px;
  padding-bottom: 44px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__banner-more-strength-img img {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    max-width: 525px;
  }
}

#cooltone-about .cooltone-about__banner-disclaimer p {
  margin-top: 119px;
  margin-bottom: var(--emu-common-spacing-medium);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  letter-spacing: unset;
  text-align: center;
  max-width: 576px;
  line-height: 16.2px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__banner-disclaimer p {
    margin-top: 29px;
    margin-bottom: 21px;
  }
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__banner-disclaimer p {
    text-align: left;
    margin-left: auto;
    font-size: 10px;
    line-height: 13.5px;
  }
}

#cooltone-about .cooltone-about__banner-see-results {
  background-image: linear-gradient(270deg, #009cd9cc .43%, #0061b9cc 52.13%, #003f82cc 99.25%);
  width: 100%;
  padding: 33px 20px 35px;
  position: relative;
  bottom: 0;
}

@media (min-width: 576px) {
  #cooltone-about .cooltone-about__banner-see-results {
    padding-top: var(--emu-common-spacing-large);
  }
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__banner-see-results {
    padding-top: 37px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__banner-see-results {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

#cooltone-about .cooltone-about__banner-see-results > .button {
  text-align: center;
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__banner-see-results > .button {
    width: 100%;
    max-width: 215px;
  }
}

#cooltone-about .cooltone-about__banner-see-results-title h3 {
  font-family: var(--emu-semantic-font-families-body);
  font-size: 24px;
  font-weight: var(--emu-semantic-font-weight-500);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  margin-bottom: 37px;
}

@media (min-width: 576px) {
  #cooltone-about .cooltone-about__banner-see-results-title h3 {
    margin-bottom: 38px;
  }
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__banner-see-results-title h3 {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 3px;
    margin-right: 35px;
    font-size: 28px;
  }
}

#cooltone-about .cooltone-about__banner-see-results-title h3 sup {
  top: -9px;
}

@media (min-width: 1200px) {
  #cooltone-about .cooltone-about__banner-see-results-title h3 sup {
    top: -11px;
  }
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__banner-see-results-btn {
    margin-top: 3px;
  }
}

#cooltone-about .cooltone-about__fda {
  background-image: radial-gradient(50% 65% at 10% 100%, #005081 0%, #00335a 22.92%, #001733 100%);
  padding-top: 40px;
  overflow: hidden;
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__fda {
    background-image: radial-gradient(65.66% 210.44% at 70.66% 47.41%, #005081 0%, #00335a 22.92%, #001733 100%);
    padding-top: 130px;
    padding-bottom: 110px;
    position: relative;
  }
}

@media (min-width: 1200px) {
  #cooltone-about .cooltone-about__fda {
    padding-top: 170px;
    padding-bottom: 120px;
  }
}

@media (min-width: 1600px) {
  #cooltone-about .cooltone-about__fda {
    padding-top: 220px;
    padding-bottom: 240px;
  }
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__fda-wrapper {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
}

@media (min-width: 1200px) {
  #cooltone-about .cooltone-about__fda-wrapper {
    max-width: 1196px;
  }
}

#cooltone-about .cooltone-about__fda-content {
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  max-width: 456px;
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__fda-content {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#cooltone-about .cooltone-about__fda-title {
  margin-bottom: 20px;
}

#cooltone-about .cooltone-about__fda-title h3 {
  color: var(--emu-semantic-colors-primary-100);
  line-height: 33px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__fda-title h3 {
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
  }
}

#cooltone-about .cooltone-about__fda-text p {
  color: var(--emu-semantic-colors-primary-100);
  margin-bottom: 10px;
}

#cooltone-about .cooltone-about__fda-button {
  background-color: var(--emu-common-colors-white);
  color: var(--emu-semantic-colors-primary-30);
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 576px) {
  #cooltone-about .cooltone-about__fda-button {
    width: auto;
    min-width: 215px;
  }
}

#cooltone-about .cooltone-about__fda-button:hover {
  background-color: var(--emu-semantic-colors-secondary-300);
  color: var(--emu-common-colors-white);
}

#cooltone-about .cooltone-about__fda-button:active {
  background-color: var(--emu-semantic-colors-primary-300);
  color: var(--emu-common-colors-white);
}

#cooltone-about .cooltone-about__fda-img {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__fda-img {
    position: absolute;
    top: 55px;
    right: 25px;
  }
}

@media (min-width: 1200px) {
  #cooltone-about .cooltone-about__fda-img {
    max-width: 550px;
    top: 0;
    right: 120px;
  }
}

@media (min-width: 1600px) {
  #cooltone-about .cooltone-about__fda-img {
    max-width: 700px;
    right: 250px;
  }
}

#cooltone-about .cooltone-about__practice {
  background-color: var(--emu-common-colors-white);
  padding-top: 60px;
  padding-bottom: 55px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__practice {
    background-color: var(--emu-semantic-colors-primary-100);
    padding-top: 90px;
    padding-bottom: 70px;
  }
}

#cooltone-about .cooltone-about__practice-wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  #cooltone-about .cooltone-about__practice-wrapper {
    max-width: 1196px;
    margin-left: auto;
    margin-right: auto;
  }
}

#cooltone-about .cooltone-about__practice-wrapper > .button {
  text-align: center;
}

#cooltone-about .cooltone-about__practice-title {
  margin-bottom: 50px;
}

#cooltone-about .cooltone-about__practice-title h3 {
  line-height: 39.6px;
  font-size: var(--emu-semantic-font-sizes-wide-xl);
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__practice-title h3 {
    text-align: center;
  }
}

#cooltone-about .cooltone-about__practice-note p {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__practice-note p {
    text-align: center;
  }
}

#cooltone-about .cooltone-about__practice-btn {
  background-color: var(--emu-semantic-colors-primary-400);
}

#cooltone-about .cooltone-about__practice-btn:hover {
  background: var(--emu-semantic-colors-secondary-300);
}

#cooltone-about .cooltone-about__practice-btn:active {
  background-color: var(--emu-semantic-colors-primary-300);
}

#cooltone-about .cooltone-about__practice-content-container {
  flex-direction: column;
  margin-bottom: 48px;
  display: flex;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__practice-content-container {
    flex-direction: row;
    margin-bottom: 50px;
  }

  #cooltone-about .cooltone-about__practice-content-container > .container {
    width: 50%;
  }
}

#cooltone-about .cooltone-about__practice-content-container > .container:first-child {
  margin-bottom: 55px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__practice-content-container > .container:first-child {
    margin-bottom: var(--emu-common-spacing-none);
    flex-direction: row;
    margin-right: 33px;
  }
}

#cooltone-about .cooltone-about__practice-content-title {
  margin-bottom: 20px;
}

#cooltone-about .cooltone-about__practice-content-title h4 {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-600);
  font-size: 20px;
  line-height: 28px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__practice-content-title h4 {
    font-size: 24px;
    line-height: 33.6px;
  }
}

#cooltone-about .cooltone-about__practice-content-text {
  margin-bottom: 28px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__practice-content-text p {
    margin-bottom: 60px;
  }
}

#cooltone-about .cooltone-about__allergan-partnership {
  padding-top: 40px;
  padding-bottom: 40px;
}

#cooltone-about .cooltone-about__allergan-partnership-wrapper > .button {
  text-align: center;
  margin-bottom: 30px;
}

#cooltone-about .cooltone-about__allergan-partnership-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__allergan-partnership-title h3 {
    text-align: center;
  }
}

#cooltone-about .cooltone-about__allergan-partnership-text {
  margin-bottom: 30px;
}

#cooltone-about .cooltone-about__allergan-partnership-text p {
  text-align: justify;
}

@media (min-width: 768px) {
  #cooltone-about .cooltone-about__allergan-partnership-text p {
    text-align: center;
  }

  #cooltone-about .cooltone-about__allergan-partnership-text {
    width: 67%;
    margin-left: auto;
    margin-right: auto;
  }
}

#cooltone-about .cooltone-about__allergan-partnership-btn:hover {
  background-color: var(--emu-semantic-colors-secondary-300);
}

#cooltone-about .cooltone-about__allergan-partnership-btn:active {
  background-color: var(--emu-semantic-colors-primary-300);
}

#cooltone-about .cooltone-about__allergan-partnership-programs-container {
  flex-direction: column;
  display: flex;
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-container {
    flex-direction: row;
  }

  #cooltone-about .cooltone-about__allergan-partnership-programs-container > .container {
    width: 50%;
  }
}

#cooltone-about .cooltone-about__allergan-partnership-programs-content {
  min-height: 300px;
  margin-bottom: 15px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  padding: 30px;
}

@media (min-width: 576px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-content {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-content {
    margin-left: 12.5px;
    margin-right: 12.5px;
  }
}

#cooltone-about .cooltone-about__allergan-partnership-programs-content > .text {
  margin-top: 20px;
  margin-bottom: 10px;
}

#cooltone-about .cooltone-about__allergan-partnership-programs-content > .text p {
  letter-spacing: unset;
  font-size: 24px;
  font-weight: var(--emu-semantic-font-weight-500);
  line-height: 26.4px;
}

#cooltone-about .cooltone-about__allergan-partnership-programs-images {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#cooltone-about .cooltone-about__allergan-partnership-programs-images img {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 320px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-images {
    flex-direction: column;
  }
}

@media (min-width: 576px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-images {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-images {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-images {
    flex-direction: row;
    height: 183.6px;
  }
}

#cooltone-about .cooltone-about__allergan-partnership-programs-app-img {
  margin-right: var(--emu-common-spacing-none);
}

@media (min-width: 576px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-app-img {
    margin-right: var(--emu-common-spacing-xxs);
    margin-right: 22.5px;
  }
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-app-img {
    margin-right: 20px;
  }
}

#cooltone-about .cooltone-about__allergan-partnership-programs-alle-img {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  align-items: center;
  display: flex;
}

@media (min-width: 992px) {
  #cooltone-about .cooltone-about__allergan-partnership-programs-alle-img {
    height: 210px;
  }
}

#cooltone-about .references p:first-child {
  padding-top: 28px;
  padding-bottom: var(--emu-common-spacing-none);
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  #cooltone-about .references p:first-child {
    padding-top: 42px;
  }
}

#cooltone-about .references p:last-child {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  border-top: none;
  margin-bottom: 82px;
}

@media (min-width: 1200px) {
  #cooltone-about .references p:last-child {
    margin-bottom: 95px;
  }
}

.error.aaaem-container {
  margin-top: -27px;
  padding: 52px 45px 47px;
}

@media (min-width: 1024px) {
  .error.aaaem-container {
    margin-top: -10px;
  }
}

@media (min-width: 1200px) {
  .error.aaaem-container {
    padding: 100px 105px;
  }
}

.error.aaaem-container .error__title {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .error.aaaem-container .error__title {
    margin-bottom: 35px;
  }
}

@media (min-width: 1440px) {
  .error.aaaem-container .error__title h1 {
    font-size: 130px;
    line-height: 117px;
  }
}

.error.aaaem-container .error__sub-title {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .error.aaaem-container .error__sub-title {
    margin-bottom: 85px;
  }
}

.error.aaaem-container .error__buttons-wrapper {
  text-align: center;
}

@media (min-width: 768px) {
  .error.aaaem-container .error__buttons-wrapper {
    text-align: left;
    column-count: 2;
    margin-left: 41px;
    margin-right: 38px;
  }
}

@media (min-width: 992px) {
  .error.aaaem-container .error__buttons-wrapper {
    max-width: 720px;
    margin-left: 137px;
    margin-right: 87px;
  }
}

@media (min-width: 1200px) {
  .error.aaaem-container .error__buttons-wrapper {
    column-gap: 56px;
    max-width: 725px;
    margin-left: auto;
    margin-right: auto;
  }
}

.error.aaaem-container .error__button {
  margin-top: 30px;
  margin-right: 35px;
}

@media (min-width: 768px) {
  .error.aaaem-container .error__button {
    margin-top: 20px;
  }
}

#partner-with-us .banner__img--mobile img {
  object-fit: unset;
}

#partner-with-us .partner-with-us__intro-video {
  background: url("resources/images/video_background.jpg") center / cover no-repeat;
  padding-top: 10px;
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__intro-video {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

#partner-with-us .partner-with-us__intro-video .embed {
  padding-top: 30px;
  padding-bottom: 5px;
}

@media (min-width: 576px) {
  #partner-with-us .partner-with-us__intro-video .embed {
    justify-content: center;
    display: flex;
  }
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__intro-video .embed {
    padding-top: var(--emu-common-spacing-none);
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  #partner-with-us .partner-with-us__intro-video .embed {
    padding-top: 13px;
    padding-left: var(--emu-common-spacing-none);
  }
}

#partner-with-us .partner-with-us__intro-video-wrapper {
  padding-right: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__intro-video-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
  }
}

@media (min-width: 1200px) {
  #partner-with-us .partner-with-us__intro-video-wrapper {
    padding-bottom: 6px;
    padding-right: 10px;
  }
}

#partner-with-us .partner-with-us__intro-video-text {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 45px;
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__intro-video-text {
    padding-top: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #partner-with-us .partner-with-us__intro-video-text {
    padding-right: 47px;
  }
}

#partner-with-us .partner-with-us__intro-video-text p {
  font-size: 20px;
  font-weight: var(--emu-semantic-font-weight-500);
  letter-spacing: .4px;
  line-height: 30px;
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__intro-video-text p {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: var(--emu-common-line-heights-wide-medium);
    letter-spacing: .32px;
  }
}

@media (min-width: 992px) {
  #partner-with-us .partner-with-us__intro-video-text p {
    letter-spacing: .4px;
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 1200px) {
  #partner-with-us .partner-with-us__intro-video-text p {
    letter-spacing: .56px;
    font-size: 28px;
    line-height: 42px;
  }
}

#partner-with-us .partner-with-us__intro-video-embed-video {
  background-color: var(--emu-common-colors-transparent);
  width: 100%;
}

@media (min-width: 576px) {
  #partner-with-us .partner-with-us__intro-video-embed-video {
    width: 450px;
  }
}

@media (min-width: 992px) {
  #partner-with-us .partner-with-us__intro-video-embed-video {
    width: 610px;
  }
}

@media (min-width: 1200px) {
  #partner-with-us .partner-with-us__intro-video-embed-video {
    width: 778px;
  }
}

#partner-with-us .partner-with-us__accordion-wrapper {
  padding: 50px 45px;
}

#partner-with-us .partner-with-us__accordion-wrapper .aaaem-accordion__title {
  font-size: 14px;
  font-family: var(--emu-semantic-font-families-heading);
  letter-spacing: .28px;
  line-height: 16.8px;
}

#partner-with-us .partner-with-us__accordion-wrapper .js-badger-accordion-panel-inner {
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__accordion-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  #partner-with-us .partner-with-us__accordion-wrapper {
    display: none;
  }
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__accordion-title h4 {
    margin-bottom: 17px;
  }
}

#partner-with-us .partner-with-us__accordion-image {
  margin-top: var(--emu-common-spacing-none);
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-none);
  max-width: 167px;
  margin-left: auto;
  padding-top: 15px;
}

#partner-with-us .partner-with-us__accordion-image img {
  margin-bottom: 25px;
}

#partner-with-us .partner-with-us__accordion-image--cool-assurance img {
  margin-left: var(--emu-common-spacing-xs);
}

#partner-with-us .partner-with-us__logo-list {
  padding-top: 100px;
  padding-bottom: 100px;
  display: none;
}

@media (min-width: 992px) {
  #partner-with-us .partner-with-us__logo-list {
    display: block;
  }
}

#partner-with-us .partner-with-us__logo-list-title {
  margin-bottom: 35px;
}

#partner-with-us .partner-with-us__logo-list-title h2 {
  font-size: 25px;
  font-family: var(--emu-semantic-font-families-heading);
  line-height: 30px;
}

@media (min-width: 1200px) {
  #partner-with-us .partner-with-us__logo-list-title h2 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: 40.8px;
  }
}

#partner-with-us .partner-with-us__logo-list-wrapper {
  margin-top: 17px;
}

#partner-with-us .partner-with-us__logo-list-wrapper > .container {
  margin-bottom: 55px;
}

#partner-with-us .partner-with-us__logo-list-wrapper > .container:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

#partner-with-us .partner-with-us__logo-list-item {
  justify-content: center;
  display: flex;
}

#partner-with-us .partner-with-us__logo-list-item--portfolio-loyalty {
  margin-top: -20px;
}

#partner-with-us .partner-with-us__logo-list-item > .image {
  justify-content: flex-end;
  align-items: center;
  width: 231px;
  display: flex;
}

#partner-with-us .partner-with-us__logo-list-item > .image img {
  position: relative;
  left: 2px;
}

#partner-with-us .partner-with-us__logo-list-item > .container {
  padding-top: 21px;
  padding-left: 28px;
  padding-right: var(--emu-common-spacing-xxs);
  width: 651px;
}

#partner-with-us .partner-with-us__logo-list-item-title h2 {
  font-size: 22px;
  font-family: var(--emu-semantic-font-families-heading);
  letter-spacing: .44px;
  margin-bottom: 18px;
  line-height: 26.4px;
}

#partner-with-us .partner-with-us__logo-list-item-warranty-img {
  margin-top: -20px;
}

#partner-with-us .partner-with-us__alle-loyalty {
  background-color: var(--emu-semantic-colors-primary-800);
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  #partner-with-us .partner-with-us__alle-loyalty {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

#partner-with-us .partner-with-us__alle-loyalty-wrapper {
  flex-direction: column;
  align-items: center;
  padding-left: 45px;
  padding-right: 45px;
  display: flex;
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__alle-loyalty-wrapper {
    flex-direction: row-reverse;
    padding-left: 15px;
    padding-right: 15px;
  }

  #partner-with-us .partner-with-us__alle-loyalty-wrapper > .text {
    flex: 0 0 66.67%;
  }

  #partner-with-us .partner-with-us__alle-loyalty-wrapper > .image {
    flex: 0 0 33.33%;
  }

  #partner-with-us .partner-with-us__alle-loyalty-text-container {
    padding-right: 15px;
  }
}

#partner-with-us .partner-with-us__alle-loyalty-text-container h4 {
  font-weight: var(--emu-common-font-weight-bold);
  letter-spacing: .36px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 22px;
  font-family: "serif";
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__alle-loyalty-text-container h4 {
    letter-spacing: .68px;
    text-align: left;
    font-size: 34px;
    line-height: 40.8px;
  }
}

#partner-with-us .partner-with-us__alle-loyalty-text-container p {
  text-align: center;
  margin-bottom: 10px;
  font-family: "serif";
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__alle-loyalty-text-container p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    letter-spacing: .54px;
    text-align: left;
    line-height: 27px;
  }
}

@media (min-width: 992px) {
  #partner-with-us .partner-with-us__alle-loyalty-text-container p {
    max-width: 566px;
  }
}

#partner-with-us .partner-with-us__alle-loyalty-image {
  margin-bottom: 26px;
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__alle-loyalty-image {
    margin-bottom: var(--emu-common-spacing-none);
    padding-left: 20px;
  }
}

#partner-with-us .partner-with-us__alle-loyalty-image img {
  max-width: 98px;
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__alle-loyalty-image img {
    max-width: 202px;
  }
}

#partner-with-us .partner-with-us__cs-university-img img {
  object-fit: cover;
  width: 100%;
  height: 236px;
}

@media (min-width: 576px) {
  #partner-with-us .partner-with-us__cs-university-img img {
    height: 420px;
  }
}

@media (min-width: 768px) {
  #partner-with-us .partner-with-us__cs-university-img img {
    height: auto;
  }
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__content {
  background-image: radial-gradient(65.66% 210.44% at 70.66% 47.41%, #005081 0%, #00335a 22.92%, #001733 100%);
}

@media (min-width: 992px) {
  #cooltone-results .image-text-grid--cooltone .image-text-grid__content > .container:has(.image-text-grid__content-container) {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__content-container {
  padding: 100px 40px 140px;
}

@media (min-width: 768px) {
  #cooltone-results .image-text-grid--cooltone .image-text-grid__content-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  #cooltone-results .image-text-grid--cooltone .image-text-grid__content-container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #cooltone-results .image-text-grid--cooltone .image-text-grid__content-container {
    max-width: 435px;
  }
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__title {
  max-width: 456px;
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__title h3 {
  font-family: var(--emu-semantic-font-families-body);
  font-size: 22px;
  line-height: 1.6;
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 992px) {
  #cooltone-results .image-text-grid--cooltone .image-text-grid__title h3 {
    font-size: 28px;
  }
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__text {
  margin-bottom: var(--emu-common-spacing-none);
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__text p {
  letter-spacing: normal;
  line-height: 1.43;
}

@media (min-width: 992px) {
  #cooltone-results .image-text-grid--cooltone .image-text-grid__text p {
    font-size: 20px;
  }
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__text p .emphasis {
  margin-left: 10px;
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__teaser .content-container {
  padding-bottom: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

#cooltone-results .image-text-grid--cooltone .image-text-grid__teaser .content-container .cmp-teaser__description p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  width: 100%;
  line-height: 1.43;
}

#cooltone-results .results__banner {
  margin-top: -27px;
  padding-top: 20px;
  position: relative;
}

@media (min-width: 992px) {
  #cooltone-results .results__banner {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #cooltone-results .results__banner {
    margin-top: -10px;
  }
}

#cooltone-results .results__hero {
  position: absolute;
  inset: 0;
}

#cooltone-results .results__hero img {
  object-fit: cover;
  object-position: 57% 100%;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  #cooltone-results .results__hero img {
    object-position: 50% 0%;
  }
}

#cooltone-results .results__banner-content {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

@media (min-width: 992px) {
  #cooltone-results .results__banner-content {
    padding-left: 50px;
    padding-right: 50px;
  }
}

#cooltone-results .results__banner-disclaimer {
  margin-top: 65px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #cooltone-results .results__banner-disclaimer {
    margin-top: 60px;
    padding-bottom: 20px;
  }
}

#cooltone-results .results__banner-disclaimer p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  letter-spacing: normal;
  line-height: 135%;
}

#cooltone-results .results__see-real-results {
  margin-bottom: 30px;
  padding-top: 44px;
  padding-bottom: 44px;
}

@media (min-width: 768px) {
  #cooltone-results .results__see-real-results {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    margin-bottom: 40px;
  }
}

#cooltone-results .results__see-real-results img {
  width: 100%;
  max-width: 190px;
}

@media (min-width: 768px) {
  #cooltone-results .results__see-real-results img {
    max-width: 676px;
  }
}

#cooltone-results .results__ct-by-cs img {
  max-width: 121px;
}

@media (min-width: 768px) {
  #cooltone-results .results__ct-by-cs img {
    max-width: 221px;
  }
}

#cooltone-science .image-text-grid--cooltone {
  padding-top: 10px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  #cooltone-science .image-text-grid--cooltone {
    padding-bottom: 120px;
  }
}

@media (min-width: 992px) {
  #cooltone-science .image-text-grid--cooltone {
    padding-top: 100px;
  }
}

#cooltone-science .cooltone-science__banner {
  background-color: #00243a;
  margin-top: -27px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1024px) {
  #cooltone-science .cooltone-science__banner {
    margin-top: -10px;
  }
}

#cooltone-science .cooltone-science__banner-title {
  width: 100%;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  #cooltone-science .cooltone-science__banner-title {
    top: 264px;
  }
}

#cooltone-science .cooltone-science__banner-title h3 {
  color: var(--emu-semantic-colors-secondary-200);
  font-weight: var(--emu-common-font-weight-bold);
  text-align: center;
  letter-spacing: 2.5px;
  line-height: 40px;
}

@media (min-width: 768px) {
  #cooltone-science .cooltone-science__banner-title h3 {
    text-align: left;
    letter-spacing: 3.15px;
    max-width: 690px;
    font-size: 45px;
    line-height: 53px;
  }
}

@media (min-width: 992px) {
  #cooltone-science .cooltone-science__banner-title h3 {
    max-width: 720px;
  }
}

#cooltone-science .cooltone-science__banner-img {
  opacity: .75;
  max-width: 700px;
  margin-left: auto;
}

@media (min-width: 992px) {
  #cooltone-science .cooltone-science__banner-img {
    transform: translateX(100px);
  }
}

@media (min-width: 1600px) {
  #cooltone-science .cooltone-science__banner-img {
    max-width: 1000px;
  }
}

#cooltone-science .cooltone-science__banner-img img {
  object-fit: cover;
  width: 100%;
  height: 480px;
}

@media (min-width: 576px) {
  #cooltone-science .cooltone-science__banner-img img {
    height: 440px;
  }
}

@media (min-width: 768px) {
  #cooltone-science .cooltone-science__banner-img img {
    height: 665px;
  }
}

#cooltone-science .cooltone-science__muscle-stimulation {
  background-color: #c2e1f5;
  position: relative;
}

#cooltone-science .cooltone-science__muscle-stimulation-wrapper {
  padding-top: 25px;
}

@media (min-width: 1200px) {
  #cooltone-science .cooltone-science__muscle-stimulation-wrapper {
    max-width: unset;
  }
}

@media (min-width: 1440px) {
  #cooltone-science .cooltone-science__muscle-stimulation-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
  }
}

#cooltone-science .cooltone-science__muscle-stimulation-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #cooltone-science .cooltone-science__muscle-stimulation-text p {
    text-align: center;
  }
}

#cooltone-science .cooltone-science__muscle-stimulation-title h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #cooltone-science .cooltone-science__muscle-stimulation-title h3 {
    text-align: center;
  }
}

#cooltone-science .cooltone-science__muscle-stimulation-image img {
  width: 100%;
}

#cooltone-technology .image-text-grid--cooltone .image-text-grid__content-container {
  padding-top: 62px;
  padding-bottom: 62px;
}

@media (min-width: 992px) {
  #cooltone-technology .image-text-grid--cooltone .image-text-grid__content-container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#cooltone-technology .cooltone-technology__body-contouring {
  background-color: var(--emu-semantic-colors-primary-100);
  padding-top: 40px;
}

@media (min-width: 992px) {
  #cooltone-technology .cooltone-technology__body-contouring {
    padding-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  #cooltone-technology .cooltone-technology__body-contouring {
    padding-bottom: 90px;
  }
}

#cooltone-technology .cooltone-technology__body-contouring-title h3 {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__body-contouring-title h3 {
    text-align: center;
  }
}

#cooltone-technology .cooltone-technology__body-contouring-text p {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__body-contouring-text p {
    text-align: center;
  }
}

#cooltone-technology .cooltone-technology__body-contouring-text p span.emphasis {
  color: var(--emu-semantic-colors-primary-dark);
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__body-contouring-image-wrapper {
    align-items: center;
    display: flex;
  }

  #cooltone-technology .cooltone-technology__body-contouring-image-wrapper > .image {
    flex: 0 0 50%;
  }
}

#cooltone-technology .cooltone-technology__body-contouring-fda-image {
  margin-bottom: 50px;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__body-contouring-fda-image {
    overflow-x: unset;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#cooltone-technology .cooltone-technology__body-contouring-fda-image img {
  margin-top: 25%;
  margin-bottom: 25%;
  transform: scale(1.5)translateY(10px);
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__body-contouring-fda-image img {
    transform: unset;
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    padding-right: 13px;
    padding-left: var(--emu-common-spacing-xxs);
    scale: 1.25;
  }
}

#cooltone-technology .cooltone-technology__body-contouring-patient-image {
  justify-content: center;
  display: flex;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__body-contouring-patient-image {
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  #cooltone-technology .cooltone-technology__body-contouring-patient-image {
    padding-left: 18px;
    padding-right: 3px;
  }
}

#cooltone-technology .cooltone-technology-awards__main-container {
  padding-top: 60px;
  padding-bottom: 108px;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology-awards__main-container {
    padding-top: 118px;
    padding-bottom: 72px;
  }
}

@media (min-width: 992px) {
  #cooltone-technology .cooltone-technology-awards__main-container {
    padding-bottom: 153px;
  }
}

@media (min-width: 1440px) {
  #cooltone-technology .cooltone-technology-awards__main-container {
    max-width: 1196px;
  }
}

#cooltone-technology .cooltone-technology-awards__title h3 {
  margin-bottom: 20px;
  line-height: 1.1;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology-awards__title h3 {
    text-align: center;
  }
}

#cooltone-technology .cooltone-technology-awards__title-info {
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology-awards__title-info {
    margin-bottom: 65px;
  }
}

#cooltone-technology .cooltone-technology-awards__title-info p {
  font-family: var(--emu-semantic-font-families-body);
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology-awards__title-info p {
    text-align: center;
  }
}

#cooltone-technology .cooltone-technology-awards__awards-container {
  flex-direction: column;
  justify-content: center;
  row-gap: 81px;
  display: flex;
}

@media (min-width: 992px) {
  #cooltone-technology .cooltone-technology-awards__awards-container {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 40px;
  }
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology-awards__awards-container > .container {
    flex-basis: 280px;
  }
}

@media (min-width: 1200px) {
  #cooltone-technology .cooltone-technology-awards__awards-container > .container {
    flex-basis: 360px;
  }
}

#cooltone-technology .cooltone-technology-awards__award-image {
  justify-content: center;
  align-items: center;
  min-height: 123px;
  margin-bottom: 30px;
  display: flex;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology-awards__award-title {
    padding-top: 15px;
  }
}

@media (min-width: 992px) {
  #cooltone-technology .cooltone-technology-awards__award-title {
    padding-top: var(--emu-common-spacing-none);
  }
}

#cooltone-technology .cooltone-technology-awards__award-title h4 {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: 20px;
  font-weight: var(--emu-common-font-weight-bold);
  margin-bottom: 24px;
  line-height: 1.1;
}

#cooltone-technology .cooltone-technology-awards__award-title-info {
  max-width: 331px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology-awards__award-title-info {
    max-width: none;
  }
}

#cooltone-technology .cooltone-technology-awards__award-title-info p {
  font-family: var(--emu-semantic-font-families-body);
}

#cooltone-technology .cooltone-technology__banner {
  background-color: var(--emu-common-colors-black);
  margin-top: -27px;
  position: relative;
}

@media (min-width: 1024px) {
  #cooltone-technology .cooltone-technology__banner {
    margin-top: -10px;
  }
}

#cooltone-technology .cooltone-technology__banner-img {
  width: 100%;
  height: 55%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
  #cooltone-technology .cooltone-technology__banner-img {
    height: 70%;
  }
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__banner-img {
    height: 100%;
  }
}

#cooltone-technology .cooltone-technology__banner-img--shadow:before {
  content: "";
  background: linear-gradient(180deg, var(--emu-common-colors-black) 0%, #050c15 80%, #060e1817 90%, #07101c00 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: -82%;
  left: 0;
}

@media (min-width: 576px) {
  #cooltone-technology .cooltone-technology__banner-img--shadow:before {
    background: linear-gradient(180deg, var(--emu-common-colors-black) 0%, #050c15 75%, #060e1817 95%, #07101c00 100%);
    height: 66%;
    top: -40%;
  }
}

#cooltone-technology .cooltone-technology__banner-img img {
  object-fit: cover;
  object-position: center top;
  width: 100%;
  height: 100%;
}

@media (min-width: 576px) {
  #cooltone-technology .cooltone-technology__banner-img img {
    object-position: center;
  }
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__banner-img img {
    object-position: 10%;
  }
}

@media (min-width: 1200px) {
  #cooltone-technology .cooltone-technology__banner-img img {
    object-position: left;
  }
}

#cooltone-technology .cooltone-technology__banner-content-container {
  min-height: 512px;
  display: flex;
  position: relative;
}

@media (min-width: 576px) {
  #cooltone-technology .cooltone-technology__banner-content-container {
    justify-content: center;
    min-height: 577px;
  }
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__banner-content-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    justify-content: flex-end;
    min-height: 665px;
  }
}

#cooltone-technology .cooltone-technology__banner-content {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__banner-content {
    align-items: center;
    width: 50%;
    margin-left: auto;
    margin-right: 80px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  #cooltone-technology .cooltone-technology__banner-content {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  #cooltone-technology .cooltone-technology__banner-content {
    margin-right: 40px;
  }
}

#cooltone-technology .cooltone-technology__banner-content .button {
  text-align: center;
  margin-top: auto;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__banner-content .button {
    min-width: var(--emu-semantic-sizing-breakpoints-small);
    margin-top: 74.5px;
  }
}

#cooltone-technology .cooltone-technology__banner-ct-by-cs {
  margin-top: 50px;
  margin-bottom: 16.5px;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__banner-ct-by-cs {
    margin-top: 140px;
    margin-bottom: 30px;
  }
}

#cooltone-technology .cooltone-technology__banner-ct-by-cs img {
  width: 221px;
  margin-left: auto;
  margin-right: auto;
}

#cooltone-technology .cooltone-technology__banner-title h3 {
  letter-spacing: 2.5px;
  color: var(--emu-semantic-colors-secondary-200);
  line-height: 40px;
}

@media (min-width: 768px) {
  #cooltone-technology .cooltone-technology__banner-title h3 {
    letter-spacing: 3.15px;
    font-size: 45px;
    line-height: 53px;
  }
}

@media (min-width: 1024px) {
  .references {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .references {
    max-width: 1196px;
  }
}

.references p {
  letter-spacing: .26px;
  border-top: var(--emu-common-border-width-thin) solid #005eb899;
  margin-left: 45px;
  margin-right: 45px;
  padding-top: 28px;
  padding-bottom: 28px;
  font-size: 13px;
  line-height: 16.9px;
}

@media (min-width: 1024px) {
  .references p {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (min-width: 1200px) {
  .references p {
    padding-top: 42px;
    padding-bottom: 42px;
  }
}

.references p b {
  font-family: var(--emu-semantic-font-families-avenir-next-medium);
  color: var(--emu-semantic-colors-primary-400);
  font-weight: var(--emu-common-font-weight-regular);
}

.accordion .aaaem-accordion__header {
  border-bottom: none;
  border-top: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-300);
}

@media (min-width: 992px) {
  .accordion .aaaem-accordion__header {
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

.accordion .aaaem-accordion__header[aria-expanded="true"] {
  border-top: 3px solid var(--emu-semantic-colors-primary-400);
}

.accordion .aaaem-accordion__header[aria-expanded="true"] .aaaem-accordion__title {
  font-family: var(--emu-semantic-font-families-heading);
}

.accordion .aaaem-accordion__title {
  font-weight: var(--emu-semantic-font-weight-500);
  letter-spacing: .32px;
  line-height: 19.2px;
}

@media (min-width: 992px) {
  .accordion .aaaem-accordion__title {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    letter-spacing: .36px;
    padding-left: 20px;
    line-height: 21.6px;
  }
}

.accordion .aaaem-accordion__icon {
  padding-right: 22px;
}

.accordion .aaaem-accordion__icon:before, .accordion .aaaem-accordion__icon:after {
  transition: none;
}

@media (min-width: 992px) {
  .accordion .aaaem-accordion__icon {
    height: 17px;
    margin-left: -3px;
    padding-right: 23px;
  }

  .accordion .aaaem-accordion__button {
    flex-direction: row-reverse;
  }
}

.accordion .aaaem-accordion__panel.-ba-is-active + .aaaem-accordion__item p {
  border-top: 3px solid var(--emu-semantic-colors-primary-400);
}

.accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner {
  padding-top: 10px;
}

@media (min-width: 992px) {
  .accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner {
    padding: 4px 25px 43px 40px;
  }
}

.accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner a {
  color: var(--emu-semantic-colors-primary-600);
  text-decoration: none;
}

.accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner a:hover {
  color: #23527c;
  text-decoration: underline;
}

.accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner sup {
  font-size: 10px;
}

.accordion .aaaem-accordion:has(dd:last-of-type.-ba-is-active) {
  border-bottom: 3px solid var(--emu-semantic-colors-primary-400);
}

.accordion .aaaem-accordion dd.-ba-is-active {
  max-height: none !important;
}

.accordion .aaaem-accordion dt:last-of-type p {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-300);
}

.accordion .aaaem-accordion dt:last-of-type p[aria-expanded="true"] {
  border-bottom: none;
}

.banner {
  background: linear-gradient(90deg, var(--emu-semantic-colors-primary-300) 0%, var(--emu-semantic-colors-primary-400) 100%);
  margin-top: -27px;
  position: relative;
}

@media (min-width: 1024px) {
  .banner {
    margin-top: -10px;
  }
}

.banner__img--mobile {
  display: block;
}

@media (min-width: 576px) {
  .banner__img--mobile {
    display: none;
  }
}

.banner__img--desktop {
  display: none;
}

@media (min-width: 576px) {
  .banner__img--desktop {
    display: block;
  }
}

.banner__img img {
  object-fit: cover;
  width: 100%;
  height: 308px;
}

@media (min-width: 768px) {
  .banner__img img {
    height: 450px;
  }
}

@media (min-width: 992px) {
  .banner__img img {
    height: 599px;
  }
}

@media (min-width: 1200px) {
  .banner__img img {
    height: 609px;
  }
}

.banner__inner-container {
  width: 100%;
  position: absolute;
  inset: 0;
}

.banner__text-img {
  align-items: center;
  width: 100%;
  max-width: 50%;
  margin-left: 50%;
  padding-left: 30px;
  display: flex;
  position: absolute;
  top: 100px;
  right: 30px;
}

@media (min-width: 768px) {
  .banner__text-img {
    top: 0;
    bottom: 0;
    right: 15px;
  }
}

@media (min-width: 1200px) {
  .banner__text-img {
    right: 6px;
  }
}

@media (min-width: 1440px) {
  .banner__text-img {
    right: -55px;
  }
}

.banner__text-img img {
  width: 100%;
}

@media (min-width: 576px) {
  .banner__text-img img {
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .banner__text-img img {
    padding-left: var(--emu-common-spacing-none);
    width: 330px;
  }
}

@media (min-width: 992px) {
  .banner__text-img img {
    width: 450px;
  }
}

@media (min-width: 1200px) {
  .banner__text-img img {
    width: 550px;
  }
}

.banner__text-img--mobile {
  display: flex;
}

@media (min-width: 576px) {
  .banner__text-img--mobile {
    display: none;
  }
}

.banner__text-img--desktop {
  display: none;
}

@media (min-width: 576px) {
  .banner__text-img--desktop {
    display: flex;
  }
}

@media (min-width: 1440px) {
  .banner__text-img--max-width {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
  }
}

.banner__disclaimer {
  opacity: .8;
  text-shadow: 1px 1px 1px #00000080;
  margin-left: 15px;
  margin-right: 15px;
  position: absolute;
  bottom: 10px;
  left: 0;
}

@media (min-width: 576px) {
  .banner__disclaimer {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    left: unset;
  }
}

@media (min-width: 768px) {
  .banner__disclaimer {
    width: 345px;
    bottom: 70px;
    right: 0;
  }
}

@media (min-width: 992px) {
  .banner__disclaimer {
    width: 373px;
    right: 90px;
  }
}

@media (min-width: 1200px) {
  .banner__disclaimer {
    right: 205px;
  }
}

.banner__disclaimer p {
  letter-spacing: .2px;
  font-size: 10px;
  line-height: 14px;
}

.button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline {
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-common-colors-transparent);
  position: relative;
}

.button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline span {
  letter-spacing: .34px;
}

@media (min-width: 1200px) {
  .button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline span {
    letter-spacing: .44px;
    font-size: 22px;
    line-height: 30.8px;
  }
}

.button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline svg {
  top: var(--emu-common-spacing-xxs);
  width: 17px;
  height: 19.5px;
  transition: transform .15s;
  position: absolute;
  right: -28px;
}

@media (min-width: 1200px) {
  .button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline svg {
    width: 22px;
    height: 25px;
  }
}

.button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline:hover, .button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline:active, .button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline:focus {
  border-bottom-color: var(--emu-semantic-colors-primary-400);
}

.button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline:hover svg, .button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline:active svg, .button .emu-button.emu-button-v2.add-icon-right.emu-button__primary-outline:focus svg {
  transform: translateX(10px);
}

.button .emu-button.emu-button-v2.emu-button__primary-filled {
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-common-font-sizes-wide-medium);
  letter-spacing: 2.5px;
  line-height: 19px;
  font-weight: var(--emu-semantic-font-weight-600);
  justify-content: center;
  width: 100%;
  padding: 10px 45px;
  transition: all .3s;
}

@media (min-width: 576px) {
  .button .emu-button.emu-button-v2.emu-button__primary-filled {
    width: auto;
    min-width: 215px;
  }
}

@media (min-width: 768px) {
  .button .emu-button.emu-button-v2.emu-button__primary-filled {
    line-height: 22px;
  }
}

.button .emu-button.emu-button-v2.emu-button__secondary-filled {
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  letter-spacing: 2.5px;
  line-height: 1.43;
  font-weight: var(--emu-semantic-font-weight-500);
  padding-top: 9px;
  padding-bottom: var(--emu-common-spacing-small);
  justify-content: center;
  width: 100%;
  max-width: 320px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .button .emu-button.emu-button-v2.emu-button__secondary-filled {
    max-width: 215px;
  }
}

.button .emu-button.emu-button-v2.emu-button__secondary-filled:active {
  box-shadow: inset 0 3px 5px #00000020;
}

.button .emu-button.emu-button-v2.emu-button__secondary-outline {
  font-family: var(--emu-semantic-font-families-body);
  letter-spacing: 2px;
  font-size: 14px;
  line-height: 19px;
  font-weight: var(--emu-semantic-font-weight-600);
  padding: 10px 60px;
  transition: all .3s;
}

@media (min-width: 768px) {
  .button .emu-button.emu-button-v2.emu-button__secondary-outline {
    font-size: var(--emu-common-font-sizes-wide-medium);
    padding-left: 77px;
    padding-right: 77px;
    line-height: 22px;
  }
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action span svg {
  display: none;
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action span:before {
  display: block;
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action-prev span:before {
  content: url("resources/images/prev-arrow.png");
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action-next span:before {
  content: url("resources/images/next-arrow.png");
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .tns-controls.js-show {
  width: 106px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .tns-outer {
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  [data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .tns-outer {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .can-show {
  justify-content: center;
  width: 58px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  bottom: -24px;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  [data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .can-show {
    display: none;
  }
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .can-show span:first-child {
  font-family: var(--emu-semantic-font-families-avenir-next-medium);
  font-weight: var(--emu-semantic-font-weight-600);
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .can-show span:last-child {
  padding-left: 7px;
  padding-right: 7px;
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .emu-carousel__action {
  transform: unset;
  height: 24px;
  top: 0;
}

@media (min-width: 992px) {
  [data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .emu-carousel__action {
    display: none;
  }
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .emu-carousel__action span svg {
  display: none;
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .emu-carousel__action span:before {
  font-size: 24px;
  font-family: var(--emu-semantic-font-families-glyphicons-halflings);
  color: var(--emu-semantic-colors-primary-400);
  opacity: .3;
  line-height: 1;
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .emu-carousel__action:focus span:before, [data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .emu-carousel__action:hover span:before {
  opacity: 1;
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .emu-carousel__action-prev span:before {
  content: "";
}

[data-component="carousel-v2"].emu-carousel.emu-carousel--with-count .emu-carousel__action-next span:before {
  content: "";
}

[data-component="container-isi-v2"] .aaaem-isi-banner {
  position: relative;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner {
    max-width: 1196px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper button {
  text-align: left;
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper button span {
  font-family: var(--emu-semantic-font-families-mono);
  letter-spacing: .26px;
  color: var(--emu-common-colors-white);
  font-size: 13px;
  line-height: 1.4;
  display: flex;
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper button span b {
  font-weight: var(--emu-common-font-weight-regular);
  margin-left: 12px;
  margin-right: 9px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
    max-width: 1196px;
  }
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content h5 {
  font-family: var(--emu-semantic-font-families-avenir-next-medium);
  letter-spacing: .26px;
  font-size: 13px;
  line-height: 1.1;
  font-weight: var(--emu-semantic-font-weight-600);
  color: var(--emu-semantic-colors-primary-400);
  margin-bottom: 5px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content p {
  letter-spacing: .26px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1.3;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content p b {
  font-family: var(--emu-semantic-font-families-avenir-next-medium);
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p:last-child {
    margin-bottom: var(--emu-common-spacing-none);
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content .isi__inner-container {
    display: flex;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .isi__inner-container > .text {
  margin-bottom: 33px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content .isi__inner-container > .text {
    flex: 0 0 50%;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content .isi__inner-container > .text:first-child {
    padding-right: 43px;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content .isi__inner-container--coolsculpting > .text, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content .isi__inner-container--cooltone > .text {
    flex: unset;
  }
}

[data-component="container-isi-v2"].js-content-shown .aaaem-isi-container.isi-container {
  transform: unset;
  position: relative;
}

footer.footer {
  background-color: var(--emu-common-colors-white);
  z-index: calc(var(--emu-common-other-z-index-isi)  + 1);
  position: relative;
}

footer.footer .footer-wrapper.aaaem-container {
  padding-left: 45px;
  padding-right: 45px;
}

@media (min-width: 1024px) {
  footer.footer .footer-wrapper.aaaem-container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  footer.footer .footer-wrapper.aaaem-container {
    max-width: 1196px;
  }
}

footer.footer .footer-top-container {
  border-top: 3px solid var(--emu-semantic-colors-primary-400);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-50);
  padding-top: 45px;
  padding-bottom: 50px;
}

@media (min-width: 1024px) {
  footer.footer .footer-top-container {
    display: flex;
  }

  footer.footer .footer-top-container > .image {
    order: 1;
    margin-right: auto;
  }

  footer.footer .footer-top-container > .container {
    order: 2;
  }

  footer.footer .footer-top-container > .text {
    order: 3;
    margin-bottom: 44px;
  }
}

footer.footer .footer-logo {
  margin-bottom: 25px;
}

footer.footer .footer-logo img {
  max-height: 19px;
  transform: translateX(-20px);
}

@media (min-width: 768px) {
  footer.footer .footer-logo img {
    transform: unset;
  }
}

@media (min-width: 1024px) {
  footer.footer .footer-logo img {
    max-height: 30px;
  }
}

@media (min-width: 1200px) {
  footer.footer .footer-logo img {
    max-height: 40px;
  }
}

footer.footer .footer-links ul {
  margin-top: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  margin-bottom: 10px;
  list-style: none;
}

footer.footer .footer-links ul li {
  font-family: var(--emu-semantic-font-families-avenir-next-medium);
  margin-bottom: var(--emu-common-spacing-medium);
  font-size: 14px;
  line-height: 15.78px;
}

footer.footer .footer-links ul li a {
  text-decoration: none;
}

footer.footer .footer-links ul li a img {
  vertical-align: middle;
}

footer.footer .footer-links ul li a:hover, footer.footer .footer-links ul li a:focus {
  text-decoration: underline;
}

@media (min-width: 1024px) {
  footer.footer .footer-links--first {
    margin-right: 40px;
  }
}

@media (min-width: 1200px) {
  footer.footer .footer-links--first {
    margin-right: 60px;
  }
}

footer.footer .footer-social-media {
  margin-top: 50px;
}

@media (min-width: 1024px) {
  footer.footer .footer-social-media {
    margin-top: var(--emu-common-spacing-none);
    margin-right: 40px;
  }
}

@media (min-width: 1200px) {
  footer.footer .footer-social-media {
    margin-right: 60px;
  }
}

footer.footer .footer-social-media__text p {
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-large);
  letter-spacing: .24px;
  font-weight: var(--emu-semantic-font-weight-600);
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  footer.footer .footer-social-media__text p {
    letter-spacing: .28px;
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 21px;
  }
}

footer.footer .footer-social-media__icons-container {
  display: flex;
}

footer.footer .footer-social-media__icons-container a {
  margin-right: 45px;
  display: flex;
}

footer.footer .footer-social-media__icons-container a svg {
  fill: var(--emu-semantic-colors-primary-300);
  height: 20.5px;
}

@media (min-width: 1200px) {
  footer.footer .footer-social-media__icons-container a svg {
    height: 23px;
  }
}

footer.footer .footer-social-media__icons-container a:hover, footer.footer .footer-social-media__icons-container a:active {
  opacity: .7;
}

footer.footer .footer-social-media__icons-container a:focus {
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-300);
}

footer.footer .footer-social-media__icons-container > .image:last-child .cmp-image a {
  margin-right: var(--emu-common-spacing-none);
}

footer.footer .footer-bottom-container {
  padding-top: 52px;
  padding-bottom: 47px;
}

@media (min-width: 1024px) {
  footer.footer .footer-bottom-container {
    padding-top: 41px;
    padding-bottom: 36px;
    display: flex;
  }

  footer.footer .footer-bottom-container > .image {
    flex: 0 0 16.67%;
  }
}

@media (min-width: 1200px) {
  footer.footer .footer-bottom-container {
    padding-bottom: var(--emu-common-spacing-large);
  }
}

footer.footer .footer-allergan-logo {
  margin-bottom: 23px;
}

@media (min-width: 1024px) {
  footer.footer .footer-allergan-logo {
    padding-right: 25px;
  }
}

footer.footer .footer-allergan-logo img {
  max-height: 50px;
}

@media (min-width: 1024px) {
  footer.footer .footer-allergan-logo img {
    max-height: 70px;
  }

  footer.footer .footer-bottom-content-wrapper {
    padding-left: 5px;
    display: flex;
  }

  footer.footer .footer-bottom-content-wrapper > .text {
    flex: 0 0 50%;
  }
}

footer.footer .footer-bottom-content-wrapper p {
  letter-spacing: .3px;
  margin-bottom: 19px;
  font-size: 10px;
  line-height: 1.3;
}

@media (min-width: 1024px) {
  footer.footer .footer-bottom-content-wrapper p {
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    letter-spacing: .36px;
  }
}

footer.footer .footer-bottom-content-wrapper p sup {
  font-size: 10px;
}

@media (min-width: 1024px) {
  footer.footer .footer-bottom-content-text {
    margin-left: 5px;
  }
}

footer.footer .footer-bottom-content-text p:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1200px) {
  footer.footer .footer-bottom-content-top-text {
    margin-right: var(--emu-common-spacing-xxs);
  }
}

@media (min-width: 1024px) {
  .header--sticky .header__menu-mobile-dropdown-content-wrapper .emu-navigation__content-wrapper nav ul li ul {
    background-color: #005eb8f2;
  }
}

header.experiencefragment {
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
}

* > :has(.cmp-experiencefragment--header) {
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
}

.skip-menu-container a.skip-menu {
  position: absolute;
}

.header__menu {
  background-image: linear-gradient(90deg, var(--emu-semantic-colors-primary-300) 0%, var(--emu-semantic-colors-primary-400) 100%);
  box-shadow: 0 0 20px #0025544d;
}

.header__menu .emu-image__link {
  display: flex;
}

.header__menu-mobile.aaaem-container {
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
}

@media (min-width: 1024px) {
  .header__menu-mobile.aaaem-container {
    position: unset;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32.5px;
    padding-right: 32.5px;
  }
}

@media (min-width: 1200px) {
  .header__menu-mobile.aaaem-container {
    max-width: 1196px;
    padding-left: 47.5px;
    padding-right: 47.5px;
  }
}

.header__menu-mobile.aaaem-container > .button {
  position: absolute;
  top: 25px;
  right: 25px;
}

@media (min-width: 1024px) {
  .header__menu-mobile.aaaem-container > .button {
    display: none;
  }
}

.header__menu-mobile.aaaem-container > .button .emu-button.emu-button-v2 {
  background: var(--emu-common-colors-transparent);
  padding-top: 7px;
  padding-bottom: 7px;
  position: relative;
  top: -7.5px;
}

.header__menu-mobile.aaaem-container > .button .emu-button.emu-button-v2:hover, .header__menu-mobile.aaaem-container > .button .emu-button.emu-button-v2:focus {
  opacity: .75;
}

.header__menu-mobile.aaaem-container > .button .emu-button.emu-button-v2 span {
  background: var(--emu-common-colors-white);
  border-radius: 10px;
  width: 20px;
  height: 2px;
}

.header__menu-mobile.aaaem-container > .button .emu-button.emu-button-v2 span:before, .header__menu-mobile.aaaem-container > .button .emu-button.emu-button-v2 span:after {
  content: "";
  background: inherit;
  height: inherit;
  width: inherit;
  border-radius: inherit;
  position: absolute;
  right: 0;
}

.header__menu-mobile.aaaem-container > .button .emu-button.emu-button-v2 span:before {
  top: 0;
}

.header__menu-mobile.aaaem-container > .button .emu-button.emu-button-v2 span:after {
  bottom: 0;
}

.header__menu-mobile.aaaem-container > div.container {
  position: absolute;
}

@media (min-width: 1024px) {
  .header__menu-mobile.aaaem-container > div.container {
    position: unset;
  }
}

.header__menu-mobile.aaaem-container > div.container .header__menu-mobile-dropdown {
  transition: all var(--emu-common-other-time-duration-instant) ease;
  background-color: var(--emu-common-colors-white);
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

@media (min-width: 1024px) {
  .header__menu-mobile.aaaem-container > div.container .header__menu-mobile-dropdown {
    background-color: var(--emu-common-colors-transparent);
    position: unset;
    overflow-y: unset;
    height: auto;
    display: block;
  }
}

.header__menu-mobile.aaaem-container > div.container .header__menu-mobile-dropdown.js-toggle-on {
  display: block;
}

.header__menu-disclaimer {
  padding-bottom: 10px;
}

@media (min-width: 1024px) {
  .header__menu-disclaimer {
    display: none;
  }
}

.header__menu-disclaimer p {
  letter-spacing: normal;
}

.header__menu-mobile-top {
  padding-left: 25px;
  padding-right: 25px;
}

.header__menu-mobile-top-content {
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
}

.header__menu-mobile-dropdown {
  background-color: var(--emu-common-colors-white);
}

.header__menu-mobile-dropdown-content-wrapper.aaaem-container {
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-content-wrapper.aaaem-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .header__menu-mobile-dropdown-content-wrapper.aaaem-container {
    max-width: 1196px;
  }
}

.header__menu-mobile-dropdown-top-content {
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-top-content {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }

  .header__menu-mobile-dropdown-top-content > .button {
    display: none;
  }
}

.header__menu-mobile-dropdown-top-content > .button .emu-button.emu-button-v2 {
  background: var(--emu-common-colors-transparent);
  position: absolute;
  top: 7.5px;
  right: 0;
}

.header__menu-mobile-dropdown-top-content > .button .emu-button.emu-button-v2:hover, .header__menu-mobile-dropdown-top-content > .button .emu-button.emu-button-v2:focus {
  opacity: .75;
  background: var(--emu-common-colors-transparent);
}

.header__menu-mobile-dropdown-top-content > .button .emu-button.emu-button-v2 span {
  background: var(--emu-semantic-colors-primary-400);
  border-radius: 10px;
  width: 20px;
  height: 2px;
  transform: rotate(45deg);
}

.header__menu-mobile-dropdown-top-content > .button .emu-button.emu-button-v2 span:before, .header__menu-mobile-dropdown-top-content > .button .emu-button.emu-button-v2 span:after {
  content: "";
  background: inherit;
  height: inherit;
  width: inherit;
  border-radius: inherit;
  position: absolute;
  right: 0;
}

.header__menu-mobile-dropdown-top-content > .button .emu-button.emu-button-v2 span:before {
  top: 0;
  transform: rotate(90deg);
}

.header__menu-mobile-dropdown-top-content > .button .emu-button.emu-button-v2 span:after {
  display: none;
  bottom: 0;
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-bottom-content.aaaem-container {
    padding-right: calc(50% - 447.5px);
    position: absolute;
    bottom: 41px;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .header__menu-mobile-dropdown-bottom-content.aaaem-container {
    padding-right: calc(50% - 550.5px);
  }
}

.header__menu-mobile-dropdown-bottom-content.aaaem-container > .button {
  text-align: center;
  top: 16.5px;
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-bottom-content.aaaem-container > .button {
    text-align: right;
    top: 7.5px;
  }
}

.header__menu-mobile-dropdown-bottom-content-text {
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-bottom-content-text {
    margin-right: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-none);
  }
}

.header__menu-mobile-dropdown-bottom-content-text ul {
  border-top: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-50);
  margin-bottom: 55px;
  margin-top: var(--emu-common-spacing-none);
  padding-top: 25px;
  padding-left: var(--emu-common-spacing-none);
  list-style: none;
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-bottom-content-text ul {
    margin-bottom: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    justify-content: right;
    gap: var(--emu-common-spacing-xxs);
    border-top: none;
    display: flex;
  }
}

.header__menu-mobile-dropdown-bottom-content-text ul li {
  letter-spacing: .32px;
  line-height: 22px;
  font-weight: var(--emu-semantic-font-weight-600);
  color: var(--emu-semantic-colors-primary-300);
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-bottom-content-text ul li {
    color: var(--emu-common-colors-white);
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    letter-spacing: .24px;
    margin-bottom: 17.5px;
    padding-left: 10px;
    line-height: 17px;
  }

  .header__menu-mobile-dropdown-bottom-content-text ul li:not(:last-child) {
    border-right: .5px solid var(--emu-common-colors-white);
    padding-right: 10px;
  }
}

.header__menu-mobile-dropdown-bottom-content-text ul li a {
  text-decoration: none;
}

.header__menu-mobile-dropdown-bottom-content-text ul li a:hover {
  font-family: var(--emu-semantic-font-families-avenir-next-medium);
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-bottom-content-text ul li a:hover {
    font-family: inherit;
    text-decoration: underline;
  }
}

.header__menu-mobile-dropdown-bottom-content-disclaimer p {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  letter-spacing: normal;
  margin-bottom: 15px;
  line-height: 1.43;
}

.header__menu-mobile-dropdown-cta.emu-button.emu-button-v2 {
  border: 2.5px solid var(--emu-semantic-colors-primary-400);
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-600);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  letter-spacing: .32px;
  color: var(--emu-semantic-colors-primary-400);
  padding: 18px 30px;
  line-height: 22px;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-cta.emu-button.emu-button-v2 {
    border-color: var(--emu-common-colors-white);
    color: var(--emu-common-colors-white);
    letter-spacing: .28px;
    padding: 10.5px 24px;
    font-size: 14px;
    line-height: 20px;
  }
}

.header__menu-mobile-dropdown-cta.emu-button.emu-button-v2:hover, .header__menu-mobile-dropdown-cta.emu-button.emu-button-v2:active {
  background-color: var(--emu-semantic-colors-primary-400);
  color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  .header__menu-mobile-dropdown-cta.emu-button.emu-button-v2:hover, .header__menu-mobile-dropdown-cta.emu-button.emu-button-v2:active {
    background-color: var(--emu-common-colors-white);
    color: var(--emu-semantic-colors-primary-300);
  }
}

.header__menu-logo-white {
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (min-width: 1024px) {
  .header__menu-logo-white {
    padding-top: 40px;
    padding-bottom: 17px;
  }
}

.header__menu-logo-white img {
  max-width: 109px;
}

@media (min-width: 1024px) {
  .header__menu-logo-white img {
    max-width: 223px;
  }

  .header__menu-logo-blue {
    display: none;
  }
}

.header__menu-logo-blue img {
  max-width: 109px;
}

@media (max-width: 1023px) {
  .u-hide-overflow-in-mobile {
    height: 100vh;
    overflow: hidden;
  }
}

.navigationlist .emu-navigation__content-wrapper {
  margin-top: 72px;
  position: relative;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper {
    margin-top: var(--emu-common-spacing-none);
    position: unset;
  }
}

.navigationlist .emu-navigation__content-wrapper nav {
  background-color: var(--emu-common-colors-white);
  padding-left: 20px;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav {
    background-color: var(--emu-common-colors-transparent);
    padding-left: var(--emu-common-spacing-none);
    position: unset;
    margin-left: 42.5px;
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul {
  flex-direction: column;
  display: block;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul {
    position: unset;
    flex-direction: row;
    display: flex;
  }

  .navigationlist .emu-navigation__content-wrapper nav ul li {
    margin-right: 40px;
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul li a {
  display: inline-block;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li a {
    display: block;
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul li > a {
  font-family: var(--emu-semantic-font-families-avenir-next-medium);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  font-weight: var(--emu-semantic-font-weight-500);
  letter-spacing: .36px;
  margin-bottom: 25px;
  line-height: 25px;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li > a {
    color: var(--emu-common-colors-white);
    letter-spacing: .28px;
    margin-bottom: var(--emu-common-spacing-none);
    padding-bottom: 30px;
    font-size: 14px;
    line-height: 20px;
    position: relative;
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul li > a:hover {
  opacity: .75;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li > a:hover {
    background-color: var(--emu-common-colors-transparent);
    color: var(--emu-common-colors-white);
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul li.emu-navigation__item-parent > a {
  position: relative;
}

.navigationlist .emu-navigation__content-wrapper nav ul li.emu-navigation__item-parent > a:after {
  content: "";
  color: var(--emu-semantic-colors-primary-400);
  font-family: var(--emu-semantic-font-families-glyphicons-halflings);
  font-size: var(--emu-common-font-sizes-wide-medium);
  letter-spacing: unset;
  line-height: 1;
  display: inline-block;
  position: absolute;
  top: 4px;
  right: -24px;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li.emu-navigation__item-parent > a:after {
    display: none;
  }

  .navigationlist .emu-navigation__content-wrapper nav ul li.emu-navigation__item-parent > a:hover:before {
    content: "";
    background-color: var(--emu-common-colors-white);
    width: 100%;
    height: 3px;
    display: block;
    position: absolute;
    bottom: 0;
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul li ul {
  display: none;
}

.navigationlist .emu-navigation__content-wrapper nav ul li.js-toggle-dropdown ul {
  display: block;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li.js-toggle-dropdown ul {
    display: flex;
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul li.js-toggle-dropdown > a:after {
  transform: rotateZ(180deg);
}

.navigationlist .emu-navigation__content-wrapper nav ul li ul {
  margin-top: var(--emu-common-spacing-none);
  padding-left: 30px;
}

@media (min-width: 768px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li ul {
    position: relative;
  }
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li ul {
    padding-left: var(--emu-common-spacing-none);
    background-color: #005eb8bf;
    width: 100%;
    padding-left: calc(50vw - 404.5px);
    position: absolute;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li ul {
    padding-left: calc(50vw - 510.5px);
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul li ul li a {
  font-family: var(--emu-semantic-font-families-body);
  line-height: 22.4px;
  font-weight: var(--emu-common-font-weight-regular);
  color: var(--emu-semantic-colors-primary-400);
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li ul li a {
    color: var(--emu-common-colors-white);
    line-height: 20px;
    font-weight: var(--emu-semantic-font-weight-500);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .navigationlist .emu-navigation__content-wrapper nav ul li ul li a:hover {
    background-color: var(--emu-common-colors-transparent);
    color: var(--emu-common-colors-white);
    opacity: 1;
  }

  .navigationlist .emu-navigation__content-wrapper nav ul li ul li a:hover:before {
    content: "";
    background-color: var(--emu-common-colors-white);
    width: 100%;
    height: 3px;
    display: block;
    position: absolute;
    bottom: 0;
  }
}

.navigationlist .emu-navigation__item--active > a {
  color: var(--emu-semantic-colors-primary-400);
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__item--active > a {
    color: var(--emu-common-colors-white);
  }

  .navigationlist .emu-navigation__item--active > a:before {
    content: "";
    background-color: var(--emu-common-colors-white);
    width: 100%;
    height: 3px;
    display: block;
    position: absolute;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .image-text-grid {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .image-text-grid {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .image-text-grid {
    max-width: 1196px;
  }
}

@media (min-width: 1440px) {
  .image-text-grid {
    max-width: 1440px;
  }
}

@media (min-width: 992px) {
  .image-text-grid__content {
    display: flex;
  }

  .image-text-grid__content > div {
    flex: 0 0 50%;
  }
}

.image-text-grid__content > .container:has(.image-text-grid__content-container) {
  align-self: center;
}

.image-text-grid__content--reverse {
  flex-direction: row-reverse;
}

.image-text-grid__content-container {
  padding: 43px 43px 46px;
}

@media (min-width: 992px) {
  .image-text-grid__content-container {
    padding-left: 28px;
    padding-right: 28px;
  }
}

@media (min-width: 1200px) {
  .image-text-grid__content-container {
    padding-top: 99px;
    padding-bottom: 99px;
    padding-left: var(--emu-common-spacing-xl);
    padding-right: 94px;
  }

  .image-text-grid__content-container--reverse {
    padding-left: 107px;
    padding-right: 60px;
  }
}

.image-text-grid__logo-img {
  margin-bottom: 23px;
}

@media (min-width: 1200px) {
  .image-text-grid__logo-img {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

.image-text-grid__logo-img img {
  height: 30px;
}

@media (min-width: 1200px) {
  .image-text-grid__logo-img img {
    height: 53px;
  }
}

.image-text-grid__logo-img--big img {
  height: 76px;
}

@media (min-width: 1200px) {
  .image-text-grid__logo-img--big img {
    height: 126px;
  }
}

.image-text-grid__title {
  margin-bottom: 24px;
}

@media (min-width: 1200px) {
  .image-text-grid__title {
    margin-bottom: 46px;
  }

  .image-text-grid__title h2 {
    width: 90%;
  }
}

.image-text-grid__text {
  margin-bottom: 50px;
}

@media (min-width: 1200px) {
  .image-text-grid__text {
    margin-bottom: 43px;
  }
}

.image-text-grid__text p sup {
  font-size: 10px;
}

.image-text-grid__disclaimer {
  margin-top: 45px;
}

.image-text-grid__disclaimer p {
  opacity: .8;
}

@media (min-width: 1600px) {
  .image-text-grid--cooltone .image-text-grid__wrapper {
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .image-text-grid--cooltone .image-text-grid__content > .container:has(.image-text-grid__content-container) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.image-text-grid--cooltone .image-text-grid__content-container {
  padding-top: 60px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  .image-text-grid--cooltone .image-text-grid__content-container {
    width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .image-text-grid--cooltone .image-text-grid__content-container {
    padding-top: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-right: unset;
    width: 100%;
    max-width: 435px;
  }
}

@media (min-width: 1200px) {
  .image-text-grid--cooltone .image-text-grid__content-container {
    max-width: 445px;
    margin-right: auto;
  }
}

.image-text-grid--cooltone .image-text-grid__teaser .teaser-container .content-container {
  padding-bottom: 18px;
  padding-left: 25px;
  padding-right: 25px;
}

.image-text-grid--cooltone .image-text-grid__teaser .teaser-container .content-container .cmp-teaser__description p {
  letter-spacing: normal;
  text-shadow: -1px 2px 3px #0000001a;
  opacity: unset;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  line-height: 135%;
}

@media (min-width: 768px) {
  .image-text-grid--cooltone .image-text-grid__teaser .teaser-container .content-container .cmp-teaser__description p {
    width: 100%;
  }
}

.image-text-grid--cooltone .image-text-grid__title {
  margin-bottom: 20px;
}

.image-text-grid--cooltone .image-text-grid__text {
  margin-bottom: 30px;
}

.image-text-grid--cooltone .image-text-grid__text p sup {
  font-size: 50%;
  top: -8px;
}

.intro {
  background-color: var(--emu-common-colors-white);
}

@media (min-width: 768px) {
  .intro {
    margin-top: -43px;
    position: relative;
  }
}

.intro > .container:not(:first-child) .intro__wrapper {
  padding-top: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  .intro > .container:not(:first-child) .intro__wrapper {
    margin-top: -43px;
    position: relative;
  }
}

.intro__wrapper {
  padding: 52px 30px 47px;
}

@media (min-width: 1200px) {
  .intro__wrapper {
    padding: 100px 105px;
  }
}

.intro__wrapper > .button {
  text-align: center;
}

.intro__title {
  width: 80%;
  margin-bottom: 18px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1200px) {
  .intro__title {
    margin-bottom: 34px;
  }
}

.intro__text p:not(:last-child) {
  margin-bottom: 17.6px;
}

@media (min-width: 1200px) {
  .intro__text p:not(:last-child) {
    margin-bottom: 38.4px;
  }
}

.intro__text p sup {
  font-size: 10px;
}

.intro__text p span.emphasis {
  color: var(--emu-semantic-colors-primary-400);
  font-size: 20px;
}

.intro__cta {
  margin-top: 35px;
}

@media (min-width: 1200px) {
  .intro__cta {
    margin-top: 50px;
  }
}

.intro__disclaimer {
  opacity: .8;
  margin-top: 25px;
}

@media (min-width: 1200px) {
  .intro__disclaimer {
    width: 72%;
    margin-top: 47px;
    margin-left: auto;
    margin-right: auto;
  }
}

.intro__img {
  justify-content: center;
  margin-bottom: 25px;
  display: flex;
}

@media (min-width: 1200px) {
  .intro__img {
    margin-bottom: 50px;
  }
}

.intro__img img {
  max-height: 45px;
}

@media (min-width: 1200px) {
  .intro__img img {
    max-height: 54px;
  }
}

[data-component="modal"] .modal-wrapper {
  background-color: #00000080;
}

[data-component="modal"] .modal-content {
  width: unset;
  max-width: unset;
  margin: auto 18px;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 576px) {
  [data-component="modal"] .modal-content {
    margin-left: 80px;
    margin-right: 80px;
  }
}

[data-component="modal"] .modal-content:hover .close {
  opacity: .8;
}

[data-component="modal"] .modal-content .close {
  opacity: 0;
  background-image: url("resources/images/modal-close-icon.gif");
  background-size: cover;
  width: 34px;
  height: 18px;
  top: -1px;
  right: -17px;
}

@media (min-width: 576px) {
  [data-component="modal"] .modal-content .close {
    right: -29px;
  }
}

[data-component="modal"] .modal-content .close span {
  display: none;
}

.tabs {
  margin-top: 35px;
}

.tabs ul {
  justify-content: center;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .tabs ul {
    margin-bottom: 50px;
  }
}

.tabs ul li:not(:last-child) {
  margin-right: 17px;
}

@media (min-width: 992px) {
  .tabs ul li:not(:last-child) {
    margin-right: 25px;
  }
}

.tabs ul a {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-heading);
  border-radius: var(--emu-common-border-radius-large);
  letter-spacing: .24px;
  line-height: 12px;
}

@media (min-width: 992px) {
  .tabs ul a {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    letter-spacing: .36px;
    border-width: 3px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .tabs ul a[aria-selected="true"] {
    border-width: 3px;
  }
}

.teaser .content-container {
  bottom: var(--emu-common-spacing-none);
  width: 100%;
  position: absolute;
}

@media (min-width: 1200px) {
  .teaser .content-container {
    padding-left: 72px;
    padding-right: 148px;
  }
}

.teaser .content-container .cmp-teaser__description p {
  letter-spacing: .2px;
  text-shadow: 1px 1px 1px #000000d9;
  opacity: .8;
  font-size: 10px;
  line-height: 1.4;
  display: block !important;
}

.teaser .aaaem-teaser, .teaser .aaaem-teaser .teaser-container, .teaser .aaaem-teaser .teaser-container .teaser-image-container, .teaser .aaaem-teaser .teaser-container .teaser-image-container .cmp-teaser__image--desktop, .teaser .aaaem-teaser .teaser-container .teaser-image-container .cmp-teaser__image--desktop .cmp-image {
  height: 100%;
}

.teaser .aaaem-teaser .teaser-container .teaser-image-container .cmp-teaser__image--desktop .cmp-image a img {
  object-fit: cover;
  height: 100%;
}

.testimonial.aaaem-container {
  padding: 50px 45px;
}

@media (min-width: 768px) {
  .testimonial.aaaem-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .testimonial.aaaem-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .testimonial__title {
    margin-bottom: 36px;
  }
}

@media (min-width: 1200px) {
  .testimonial__title {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }
}

.testimonial__text {
  margin-top: 17px;
  margin-bottom: 48px;
}

@media (min-width: 768px) {
  .testimonial__text {
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .testimonial__text {
    margin-bottom: 65px;
  }
}

.testimonial__disclaimer {
  margin-top: 34px;
}

@media (min-width: 992px) {
  .testimonial__disclaimer {
    margin-top: 10px;
  }
}

.testimonial__disclaimer p {
  opacity: .8;
}

.testimonial__carousel {
  -webkit-user-select: none;
  user-select: none;
}

@media (min-width: 1200px) {
  .testimonial__carousel {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .testimonial__carousel .emu-carousel__item-container {
    display: flex;
  }
}

.testimonial__carousel-tablet-image {
  display: none;
}

@media (min-width: 992px) {
  .testimonial__carousel-tablet-image {
    width: 200px;
    margin-top: 42px;
    display: block;
  }

  .testimonial__carousel-tablet-image img {
    border-radius: 100px;
    height: 200px;
  }
}

.testimonial__carousel-wrapper {
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .testimonial__carousel-wrapper {
    max-width: unset;
    padding-left: var(--emu-common-spacing-large);
  }
}

@media (min-width: 1200px) {
  .testimonial__carousel-wrapper {
    padding-left: 62px;
  }
}

.testimonial__carousel-quote {
  margin-top: 17.5px;
  margin-bottom: var(--emu-common-spacing-large);
  padding-top: 75px;
  position: relative;
}

@media (min-width: 992px) {
  .testimonial__carousel-quote {
    margin-top: var(--emu-common-spacing-none);
  }
}

.testimonial__carousel-quote blockquote {
  font-family: var(--emu-semantic-font-families-kaneda-gothic);
  color: var(--emu-semantic-colors-primary-400);
  margin: var(--emu-common-spacing-none);
  font-size: 125px;
  line-height: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 992px) {
  .testimonial__carousel-quote blockquote {
    transform: unset;
    left: 0;
  }
}

.testimonial__carousel-quote p {
  letter-spacing: .42px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
}

@media (min-width: 992px) {
  .testimonial__carousel-quote p {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    text-align: left;
    letter-spacing: .48px;
    line-height: 27px;
  }
}

.testimonial__carousel-figure {
  margin-bottom: 40px;
  display: flex;
}

.testimonial__carousel-figure .image {
  align-items: center;
  display: flex;
}

.testimonial__carousel-figure-image {
  width: 60px;
}

.testimonial__carousel-figure-image img {
  border-radius: 30px;
}

@media (min-width: 992px) {
  .testimonial__carousel-figure-image {
    display: none;
  }
}

.testimonial__carousel-figure-text {
  padding-left: 20px;
}

@media (min-width: 992px) {
  .testimonial__carousel-figure-text {
    padding-left: var(--emu-common-spacing-none);
  }
}

.testimonial__carousel-figure-text p {
  letter-spacing: .42px;
  font-size: 14px;
  line-height: 21px;
}

@media (min-width: 992px) {
  .testimonial__carousel-figure-text p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    letter-spacing: .42px;
    line-height: 27px;
  }
}

.testimonial__carousel-figure-text p:first-child {
  color: var(--emu-semantic-colors-primary-400);
  font-weight: var(--emu-semantic-font-weight-500);
  font-family: var(--emu-semantic-font-families-avenir-next-medium);
  margin-top: -2px;
}

.testimonial__carousel-figure-text p:last-child {
  font-family: var(--emu-semantic-font-families-avenir-next-italic);
}

.video-testimonial {
  background-image: url("resources/images/video-testimonial-bg.jpg");
  background-color: var(--emu-semantic-colors-primary-800);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .video-testimonial {
    padding: 90px 50px 60px;
  }
}

@media (min-width: 1024px) {
  .video-testimonial {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  .video-testimonial__wrapper {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .video-testimonial__wrapper {
    max-width: 1196px;
  }
}

.video-testimonial__title {
  margin-bottom: 5px;
}

.video-testimonial__title h3 {
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: 35px;
  font-weight: var(--emu-common-font-weight-black);
}

@media (min-width: 576px) {
  .video-testimonial__title h3 {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  .video-testimonial__title h3 {
    font-size: 64px;
    line-height: 69.5px;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__title h3 {
    font-size: 54px;
    line-height: 58px;
  }

  .video-testimonial__title h3 br {
    display: none;
  }
}

.video-testimonial__text {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .video-testimonial__text {
    margin-bottom: 65px;
    padding-left: 20px;
  }
}

@media (min-width: 768px) {
  .video-testimonial__text {
    margin-bottom: 50px;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__text {
    margin-bottom: 65px;
  }
}

.video-testimonial__text p {
  letter-spacing: unset;
  font-size: 14px;
  line-height: 26px;
}

@media (min-width: 576px) {
  .video-testimonial__text p {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .video-testimonial__text p {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__text p {
    font-size: 24px;
    line-height: 31px;
  }
}

.video-testimonial__text p sup {
  top: -10px;
}

@media (min-width: 768px) {
  .video-testimonial__text p br {
    display: none;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__content {
    justify-content: space-evenly;
    width: 930px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .video-testimonial__content {
    width: 1166px;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__content > .embed {
    width: 55%;
  }

  .video-testimonial__content > .text {
    width: 35%;
  }
}

.video-testimonial__carousel.emu-carousel .emu-carousel__action {
  top: 23%;
}

@media (min-width: 576px) {
  .video-testimonial__carousel.emu-carousel .emu-carousel__action {
    top: 58%;
  }
}

@media (min-width: 768px) {
  .video-testimonial__carousel.emu-carousel .emu-carousel__action {
    top: 26%;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__carousel.emu-carousel .emu-carousel__action {
    top: 54%;
  }
}

.video-testimonial__carousel.emu-carousel .emu-carousel__action-prev {
  left: var(--emu-common-spacing-small);
}

@media (min-width: 576px) {
  .video-testimonial__carousel.emu-carousel .emu-carousel__action-prev {
    left: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 768px) {
  .video-testimonial__carousel.emu-carousel .emu-carousel__action-prev {
    left: -11px;
  }
}

.video-testimonial__carousel.emu-carousel .emu-carousel__action-next {
  right: var(--emu-common-spacing-small);
}

@media (min-width: 576px) {
  .video-testimonial__carousel.emu-carousel .emu-carousel__action-next {
    right: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 768px) {
  .video-testimonial__carousel.emu-carousel .emu-carousel__action-next {
    right: -11px;
  }
}

.video-testimonial__embed {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .video-testimonial__embed {
    width: 85%;
  }
}

@media (min-width: 768px) {
  .video-testimonial__embed {
    width: calc(90vw - 90px);
  }
}

@media (min-width: 1024px) {
  .video-testimonial__embed {
    width: 100%;
  }
}

.video-testimonial__description {
  -webkit-user-select: none;
  user-select: none;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .video-testimonial__description {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (min-width: 768px) {
  .video-testimonial__description {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    width: 90%;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__description {
    width: 100%;
    padding-left: 20px;
  }
}

.video-testimonial__description h5 {
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 16.5px;
  font-weight: var(--emu-common-font-weight-black);
  margin-top: 54px;
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .video-testimonial__description h5 {
    margin-top: 24px;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 30.5px;
  }
}

@media (min-width: 768px) {
  .video-testimonial__description h5 {
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    line-height: 41px;
    font-weight: var(--emu-common-font-weight-bold);
    margin-top: 60px;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__description h5 {
    font-size: 22px;
    line-height: 30px;
    font-weight: var(--emu-common-font-weight-black);
    margin-top: 24px;
  }
}

.video-testimonial__description h4 {
  font-size: 28px;
  line-height: 44px;
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-common-font-weight-regular);
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .video-testimonial__description h4 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    margin-bottom: 20px;
    line-height: 34px;
  }
}

@media (min-width: 768px) {
  .video-testimonial__description h4 {
    font-size: 64px;
    line-height: 69.5px;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__description h4 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: 42px;
  }
}

.video-testimonial__description p {
  letter-spacing: normal;
  line-height: 32px;
}

@media (min-width: 576px) {
  .video-testimonial__description p {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .video-testimonial__description p {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1024px) {
  .video-testimonial__description p {
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: 30px;
    font-weight: var(--emu-semantic-font-weight-500);
  }
}

/*# sourceMappingURL=main.css.map */
