[data-component='modal'] {
  .modal-wrapper {
    background-color: rgba(0, 0, 0, 0.5); // as per live
  }

  .modal-content {
    margin-left: 18px;
    margin-right: 18px;
    margin-top: auto;
    margin-bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    width: unset;
    max-width: unset;

    @include mq('small-plus') {
      margin-left: 80px;
      margin-right: 80px;
    }

    &:hover {
      .close {
        opacity: 0.8;
      }
    }

    .close {
      width: 34px; // as per live
      height: 18px;
      background-image: url('../../assets/images/modal-close-icon.gif'); // as per live
      background-size: cover;
      right: -17px;
      top: -1px;
      opacity: 0;

      @include mq('small-plus') {
        right: -29px;
      }

      span {
        display: none;
      }
    }
  }
}
