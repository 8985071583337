.header--sticky {
  .header__menu {
    &-mobile-dropdown-content-wrapper {
      .emu-navigation__content-wrapper {
        nav {
          ul {
            li {
              ul {
                @include mq('large') {
                  background-color: rgba(0, 94, 184, 0.95);
                }
              }
            }
          }
        }
      }
    }
  }
}
