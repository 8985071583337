#cooltone-science {
  .image-text-grid--cooltone {
    padding-top: 10px;
    padding-bottom: 60px;

    @include mq('medium') {
      padding-bottom: 120px;
    }

    @include mq('tablet') {
      padding-top: 100px;
    }
  }

  .cooltone-science {
    &__banner {
      background-color: #00243a; // used once
      position: relative;
      overflow: hidden;
      margin-top: -27px;

      @include mq('large') {
        margin-top: -10px;
      }

      &-title {
        position: absolute;
        top: 120px;
        width: 100%;
        left: 0;
        right: 0;

        @include mq('medium') {
          top: 264px;
        }

        h3 {
          color: var(--emu-semantic-colors-secondary-200);
          font-weight: var(--emu-common-font-weight-bold);
          text-align: center;
          letter-spacing: 2.5px;
          line-height: 40px;

          @include mq('medium') {
            max-width: 690px; // as per live
            text-align: left;
            font-size: 45px;
            letter-spacing: 3.15px;
            line-height: 53px;
          }

          @include mq('tablet') {
            max-width: 720px; // as per live
          }
        }
      }

      &-img {
        opacity: 0.75;
        max-width: 700px; // as per live
        margin-left: auto;

        @include mq('tablet') {
          transform: translateX(100px);
        }

        @include mq('1600px') {
          max-width: 1000px; // as per live
        }

        img {
          width: 100%;
          height: 480px; // as per live
          object-fit: cover;

          @include mq('small-plus') {
            height: 440px; // as per live
          }

          @include mq('medium') {
            height: 665px; // as per live
          }
        }
      }
    }

    &__muscle-stimulation {
      background-color: #c2e1f5; // used once
      position: relative;

      &-wrapper {
        padding-top: 25px;

        @include mq('x-large') {
          max-width: unset; // as per live
        }

        @include mq('xx-large') {
          width: 100%;
          position: absolute;
          top: 0;
        }
      }

      &-text {
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          margin-top: 30px;
          margin-bottom: 30px;

          @include mq('medium') {
            text-align: center;
          }
        }
      }

      &-title {
        h3 {
          margin-top: 20px;
          margin-bottom: 20px;

          @include mq('medium') {
            text-align: center;
          }
        }
      }

      &-image {
        img {
          width: 100%;
        }
      }
    }
  }
}
