#cooltone-technology {
  .image-text-grid--cooltone {
    .image-text-grid {
      &__content-container {
        padding-top: 62px;
        padding-bottom: 62px;

        @include mq('tablet') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }

  .cooltone-technology {
    &__body-contouring {
      padding-top: 40px;
      background-color: var(--emu-semantic-colors-primary-100);

      @include mq('tablet') {
        padding-bottom: 40px;
      }

      @include mq('x-large') {
        padding-bottom: 90px;
      }

      &-title {
        h3 {
          margin-top: 20px;
          margin-bottom: 30px;
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);

          @include mq('medium') {
            text-align: center;
          }
        }
      }

      &-text {
        p {
          margin-bottom: 30px;

          @include mq('medium') {
            text-align: center;
          }

          span.emphasis {
            color: var(--emu-semantic-colors-primary-dark);
          }
        }
      }

      &-image-wrapper {
        @include mq('medium') {
          display: flex;
          align-items: center;
        }

        > .image {
          @include mq('medium') {
            flex: 0 0 50%;
          }
        }
      }

      &-fda-image {
        overflow-x: hidden; // to hide extra space due to scaling of image
        margin-bottom: 50px;

        @include mq('medium') {
          overflow-x: unset;
          margin-bottom: var(--emu-common-spacing-none);
        }

        img {
          transform: scale(1.5) translateY(10px); // to match live
          margin-top: 25%; // as per live
          margin-bottom: 25%; // as per live

          @include mq('medium') {
            transform: unset;
            scale: (1.25); // to match live
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            padding-right: 13px;
            padding-left: var(--emu-common-spacing-xxs);
          }
        }
      }

      &-patient-image {
        display: flex;
        justify-content: center;

        @include mq('medium') {
          padding-left: 15px;
        }

        @include mq('x-large') {
          padding-left: 18px;
          padding-right: 3px;
        }
      }
    }

    &-awards {
      &__main-container {
        padding-top: 60px;
        padding-bottom: 108px;

        @include mq('medium') {
          padding-top: 118px;
          padding-bottom: 72px;
        }

        @include mq('tablet') {
          padding-bottom: 153px; // calc(72px + 81px);
        }

        @include mq('xx-large') {
          max-width: 1196px;
        }
      }

      &__title {
        h3 {
          margin-bottom: 20px;
          line-height: 1.1; // Override default as it doesn't match the design

          @include mq('medium') {
            text-align: center;
          }
        }
      }

      &__title-info {
        margin-bottom: 45px;

        @include mq('medium') {
          margin-bottom: 65px;
        }

        p {
          font-family: var(--emu-semantic-font-families-body);

          @include mq('medium') {
            text-align: center;
          }
        }
      }

      &__awards-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 81px;

        @include mq('tablet') {
          flex-direction: row;
          justify-content: space-between;
          column-gap: 40px;
        }

        > .container {
          @include mq('medium') {
            flex-basis: 280px; // as per live
          }

          @include mq('x-large') {
            flex-basis: 360px; // as per live
          }
        }
      }

      &__award-image {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 123px; // to match live
      }

      &__award-title {
        @include mq('medium') {
          padding-top: 15px;
        }

        @include mq('tablet') {
          padding-top: var(--emu-common-spacing-none);
        }

        h4 {
          font-family: var(--emu-semantic-font-families-mono);
          font-size: 20px;
          font-weight: var(--emu-common-font-weight-bold);
          line-height: 1.1;
          margin-bottom: 24px;
        }
      }

      &__award-title-info {
        max-width: 331px; // as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: none;
        }

        p {
          font-family: var(--emu-semantic-font-families-body);
        }
      }
    }

    &__banner {
      margin-top: -27px;
      background-color: var(--emu-common-colors-black);
      position: relative;

      @include mq('large') {
        margin-top: -10px;
      }

      &-img {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        // From ref webpage
        height: 55%;

        @include mq('small-plus') {
          // From ref webpage
          height: 70%;
        }

        @include mq('medium') {
          height: 100%;
        }

        &--shadow {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: -82%;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              180deg,
              var(--emu-common-colors-black) 0%,
              #050c15 80%,
              #060e1817 90%,
              #07101c00 100%
            );

            @include mq('small-plus') {
              // From ref webpage
              height: 66%;
              top: -40%;
              background: linear-gradient(
                180deg,
                var(--emu-common-colors-black) 0%,
                #050c15 75%,
                #060e1817 95%,
                #07101c00 100%
              );
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;

          @include mq('small-plus') {
            object-position: center;
          }

          @include mq('medium') {
            object-position: 10%;
          }

          @include mq('x-large') {
            object-position: left;
          }
        }
      }

      &-content-container {
        position: relative;
        display: flex;
        // From ref webpage
        min-height: 512px;

        @include mq('small-plus') {
          // From ref webpage
          min-height: 577px;
          justify-content: center;
        }

        @include mq('medium') {
          // From ref webpage
          min-height: 665px;
          justify-content: flex-end;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        @include mq('medium') {
          align-items: center;
          margin-left: auto;
          margin-right: 80px;
          width: 50%;
          padding-left: 30px;
          padding-right: 30px;
        }

        @include mq('tablet') {
          padding-left: 50px;
          padding-right: 50px;
        }

        @include mq('x-large') {
          margin-right: 40px;
        }

        .button {
          text-align: center;
          margin-top: auto;
          margin-bottom: 35px;

          @include mq('medium') {
            margin-top: 74.5px;
            min-width: var(--emu-semantic-sizing-breakpoints-small);
          }
        }
      }

      &-ct-by-cs {
        margin-top: 50px;
        margin-bottom: 16.5px;

        @include mq('medium') {
          margin-top: 140px;
          margin-bottom: 30px;
        }

        img {
          // From ref webpage
          width: 221px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-title {
        h3 {
          line-height: 40px;
          letter-spacing: 2.5px;
          color: var(--emu-semantic-colors-secondary-200);

          @include mq('medium') {
            font-size: 45px;
            line-height: 53px;
            letter-spacing: 3.15px;
          }
        }
      }
    }
  }
}
