.banner {
  margin-top: -27px;
  background: linear-gradient(
    90deg,
    var(--emu-semantic-colors-primary-300) 0%,
    var(--emu-semantic-colors-primary-400) 100%
  );
  position: relative;

  @include mq('large') {
    margin-top: -10px;
  }

  &__img {
    &--mobile {
      display: block;

      @include mq('small-plus') {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include mq('small-plus') {
        display: block;
      }
    }

    img {
      height: 308px; // as per live
      width: 100%;
      object-fit: cover;

      @include mq('medium') {
        height: 450px; // as per live
      }

      @include mq('tablet') {
        height: 599px; // as per live
      }

      @include mq('x-large') {
        height: 609px; // as per live
      }
    }
  }

  &__inner-container {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__text-img {
    position: absolute;
    top: 100px;
    right: 30px;
    width: 100%;
    max-width: 50%;
    margin-left: 50%;
    padding-left: 30px;
    display: flex;
    align-items: center;

    @include mq('medium') {
      top: 0;
      bottom: 0;
      right: 15px;
    }

    @include mq('x-large') {
      right: 6px;
    }

    @include mq('xx-large') {
      right: -55px;
    }

    img {
      width: 100%;

      @include mq('small-plus') {
        padding-left: 15px;
      }

      @include mq('medium') {
        padding-left: var(--emu-common-spacing-none);
        width: 330px; // as per live
      }

      @include mq('tablet') {
        width: 450px; // as per live
      }

      @include mq('x-large') {
        width: 550px; // as per live
      }
    }

    &--mobile {
      display: flex;

      @include mq('small-plus') {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include mq('small-plus') {
        display: flex;
      }
    }

    &--max-width {
      @include mq('xx-large') {
        max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__disclaimer {
    position: absolute;
    bottom: 10px;
    opacity: 0.8;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    left: 0;
    margin-left: 15px;
    margin-right: 15px;

    @include mq('small-plus') {
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
      left: unset;
    }

    @include mq('medium') {
      width: 345px; // as per live
      right: 0;
      bottom: 70px;
    }

    @include mq('tablet') {
      width: 373px; // as per live
      right: 90px;
    }

    @include mq('x-large') {
      right: 205px; // as per live
    }

    p {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.2;
    }
  }
}
