#partner-with-us {
  .banner {
    &__img--mobile {
      img {
        object-fit: unset;
      }
    }
  }

  .partner-with-us {
    &__intro-video {
      background: url('./assets/images/video_background.jpg') no-repeat center
        center;
      background-size: cover;
      padding-top: 10px;

      @include mq('medium') {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      .embed {
        padding-top: 30px;
        padding-bottom: 5px;

        @include mq('small-plus') {
          display: flex;
          justify-content: center;
        }

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-left: 15px;
        }

        @include mq('x-large') {
          padding-top: 13px;
          padding-left: var(--emu-common-spacing-none);
        }
      }

      &-wrapper {
        padding-right: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);

        @include mq('medium') {
          display: flex;
          padding-right: 15px;
          padding-left: 15px;
        }

        @include mq('x-large') {
          padding-bottom: 6px;
          padding-right: 10px;
        }
      }

      &-text {
        padding-top: 30px;
        padding-right: 45px;
        padding-left: 15px;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          padding-right: 47px;
        }

        p {
          font-size: 20px;
          font-weight: var(--emu-semantic-font-weight-500);
          line-height: 30px;
          letter-spacing: 0.4px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            line-height: var(--emu-common-line-heights-wide-medium);
            letter-spacing: 0.32px;
          }

          @include mq('tablet') {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.4px;
          }

          @include mq('x-large') {
            font-size: 28px;
            line-height: 42px;
            letter-spacing: 0.56px;
          }
        }
      }

      &-embed-video {
        width: 100%; //as per live
        background-color: var(--emu-common-colors-transparent);

        @include mq('small-plus') {
          width: 450px; //as per live
        }

        @include mq('tablet') {
          width: 610px; //as per live
        }

        @include mq('x-large') {
          width: 778px; //as per live
        }
      }
    }

    &__accordion {
      &-wrapper {
        padding-top: 50px;
        padding-right: 45px;
        padding-bottom: 50px;
        padding-left: 45px;

        .aaaem-accordion__title {
          font-size: 14px;
          font-family: var(--emu-semantic-font-families-heading);
          letter-spacing: 0.28px;
          line-height: 16.8px;
        }

        .js-badger-accordion-panel-inner {
          padding-bottom: 40px;
          padding-left: 15px;
          padding-right: 15px;
        }

        @include mq('medium') {
          padding-left: 15px;
          padding-right: 15px;
        }

        @include mq('tablet') {
          display: none;
        }
      }

      &-title {
        h4 {
          @include mq('medium') {
            margin-bottom: 17px;
          }
        }
      }

      &-image {
        max-width: 167px; //as per live
        margin-top: var(--emu-common-spacing-none);
        margin-right: auto;
        margin-bottom: var(--emu-common-spacing-none);
        margin-left: auto;
        padding-top: 15px;

        img {
          margin-bottom: 25px;
        }

        &--cool-assurance {
          img {
            margin-left: var(--emu-common-spacing-xs);
          }
        }
      }
    }

    &__logo-list {
      padding-top: 100px;
      padding-bottom: 100px;
      display: none;

      @include mq('tablet') {
        display: block;
      }

      &-title {
        margin-bottom: 35px;

        h2 {
          font-size: 25px;
          font-family: var(--emu-semantic-font-families-heading);
          line-height: 30px;

          @include mq('x-large') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: 40.8px;
          }
        }
      }

      &-wrapper {
        margin-top: 17px;

        > .container {
          margin-bottom: 55px;

          &:last-child {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &-item {
        display: flex;
        justify-content: center;

        &--portfolio-loyalty {
          margin-top: -20px;
        }

        > .image {
          width: 231px; //as per live
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            position: relative;
            left: 2px;
          }
        }

        > .container {
          width: 651px; //as per live
          padding-top: 21px;
          padding-left: 28px;
          padding-right: var(--emu-common-spacing-xxs);
        }

        &-title {
          h2 {
            font-size: 22px;
            font-family: var(--emu-semantic-font-families-heading);
            letter-spacing: 0.44px;
            line-height: 26.4px;
            margin-bottom: 18px;
          }
        }

        &-warranty-img {
          margin-top: -20px;
        }
      }
    }

    &__alle-loyalty {
      background-color: var(--emu-semantic-colors-primary-800);
      padding-top: 50px;
      padding-bottom: 50px;

      @include mq('tablet') {
        padding-top: 100px;
        padding-bottom: 100px;
      }

      &-wrapper {
        padding-right: 45px;
        padding-left: 45px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @include mq('medium') {
          padding-right: 15px;
          padding-left: 15px;
          flex-direction: row-reverse;
        }

        > .text {
          @include mq('medium') {
            flex: 0 0 66.67%;
          }
        }

        > .image {
          @include mq('medium') {
            flex: 0 0 33.33%;
          }
        }
      }

      &-text-container {
        @include mq('medium') {
          padding-right: 15px;
        }

        h4 {
          font-weight: var(--emu-common-font-weight-bold);
          margin-top: 20px;
          margin-bottom: 22px;
          font-family: 'serif';
          letter-spacing: 0.36px;
          text-align: center;

          @include mq('medium') {
            font-size: 34px;
            line-height: 40.8px;
            letter-spacing: 0.68px;
            text-align: left;
          }
        }

        p {
          margin-bottom: 10px;
          font-family: 'serif';
          text-align: center;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            line-height: 27px;
            letter-spacing: 0.54px;
            text-align: left;
          }

          @include mq('tablet') {
            max-width: 566px; //as per live
          }
        }
      }

      &-image {
        margin-bottom: 26px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
          padding-left: 20px;
        }

        img {
          max-width: 98px; //as per live

          @include mq('medium') {
            max-width: 202px; //as per live
          }
        }
      }
    }

    &__cs-university-img {
      img {
        width: 100%; //as per live
        height: 236px; //as per live
        object-fit: cover;

        @include mq('small-plus') {
          height: 420px; //as per live
        }

        @include mq('medium') {
          height: auto; //as per live
        }
      }
    }
  }
}
